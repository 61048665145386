import React, { useState } from 'react';

import { HiOutlinePhotograph } from 'react-icons/hi'
import { BsCardText, BsCreditCard } from 'react-icons/bs'
import { RiLockPasswordLine } from 'react-icons/ri'

import ChangeLogoForm from './change-logo';
import EditProfile from './edit-profile';
import EditPassword from './edit-password';
import PaymentPage from './payment';
import Header from '../../components/header/Header';
import './styles.scss';

export default function Settings() {
  const [formStep, setFormStep] = useState(1);

  return (
    <div className="page-container container">
      <Header
        title="Suas Configurações"
        subTitle="Exibindo suas configurações"
      />
      <div className="settings__header-container mt-3">
        <ul>
          <button
            className={`${formStep === 1
              ? 'settings__arrow-step-active' : 'settings__arrow-step'}`}
            type="button"
            onClick={() => setFormStep(1)}
          >
            <span>
              <HiOutlinePhotograph style={{ fontSize:'18px' }} /> <span className="settings__arrow-step__spanText">Logo da empresa</span>
            </span>
          </button>
          <button
            className={`${formStep === 2
              ? 'settings__arrow-step-active' : 'settings__arrow-step'}`}
            type="button"
            onClick={() => setFormStep(2)}
          >
            <strong className="settings__arrow-step__strong" />
            <span>
            <BsCardText style={{ fontSize:'18px' }} /> <span className="settings__arrow-step__spanText">Minha conta</span>
            </span>
          </button>
          <button
            className={`${formStep === 3
              ? 'settings__arrow-step-active' : 'settings__arrow-step'}`}
            type="button"
            onClick={() => setFormStep(3)}
          >
            <strong className="settings__arrow-step__strong" />
            <span>
            <RiLockPasswordLine style={{ fontSize:'18px' }} /> <span className="settings__arrow-step__spanText">Redefinir senha</span>
            </span>
          </button>
          <button
            className={`${formStep === 4
              ? 'settings__arrow-step-active' : 'settings__arrow-step'}`}
            type="button"
            onClick={() => setFormStep(4)}
          >
            <strong className="settings__arrow-step__strong" />
            <span>
            <BsCreditCard style={{ fontSize:'18px' }} /> <span className="settings__arrow-step__spanText">Pagamento</span>
            </span>
          </button>
        </ul>
      </div>
      <div className="row px-4 mt-3">
        {formStep === 1 && (
          <ChangeLogoForm />
        )}
        {formStep === 2 && (
          <EditProfile />
        )}
        {formStep === 3 && (
          <EditPassword />
        )}
        {formStep === 4 && (
          <PaymentPage />
        )}
      </div>
    </div>
  );
}
