export default {
  application: {
    name: 'EloPag | Conexão Monetizada',
  },
  routes: {
    login: {
      url: '/login',
      pageTitle: 'Login',
    },
    register: {
      url: '/cadastro',
      pageTitle: 'Cadastro',
    },
    forgotPassword: {
      url: '/recuperacao-senha',
      pageTitle: 'Esqueceu a Senha?',
    },
    redefinePassword: {
      url: '/redefinir-senha',
      pageTitle: 'Redefinir Senha',
    },
    registerContinuation: {
      url: '/continuacao-cadastro',
      pageTitle: 'Cadastro',
    },
    creditCard: {
      url: '/cartao',
      pageTitle: 'cartão',
    },
    emailValidation: {
      url: '/validacao-email',
      pageTitle: 'Validação do Email',
    },
    tutorial: {
      url: '/tutorial',
      pageTitle: 'Tutorial',
    },
    dashboard: {
      url: '/dashboard',
      pageTitle: 'Painel',
    },
    chat: {
      url: '/pix-messenger',
      pageTitle: 'Pix Messenger',
    },
    call: {
      url: '/ligacoes',
      pageTitle: 'Ligações',
    },
    post: {
      url: '/publicacoes',
      pageTitle: 'Publicações',
    },
    postNew: {
      url: '/publicacoes/new',
      pageTitle: 'Publicações',
    },
    article: {
      url: '/artigos',
      pageTitle: 'Artigos',
    },
    credits: {
      url: '/creditos',
      pageTitle: 'Adicionar Créditos',
    },
    settings: {
      url: '/configuracoes',
      pageTitle: 'Configurações',
    },
    notifications: {
      url: '/notificacoes',
      pageTitle: 'Notificações',
    },
    addCrediCard: {
      url: '/configuracoes/cartao-de-credito',
      pageTitle: 'Adicionar Cartão',
    },
  },
};
