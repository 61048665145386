import React from 'react'
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    background: 'rgb(0,0,0,0.85)',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1040,
  },
};

// Modal.setAppElement('#app');

const ImageChatModal = ({ isOpen, onClose, url }) => {
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      shouldCloseOnOverlayClick
      className="modal-chat__container"
    >
      <button
        onClick={onClose}
        className="modal-chat__container__button"
      >X</button>
      <img
        className="modal-chat__container__image"
        src={url}
        alt="That is an image sent on chat."
      />
    </Modal>
  )
}

export default ImageChatModal
