import React from 'react';
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';

import './styles.scss';

const customStyles = {
  overlay: {
    background: 'rgb(0,0,0,0.85)',
  },
};

Modal.setAppElement('#root');

const CheckoutErrorModal = ({
  isOpen,
  onOverlayClick,
  errorMessage,
  isPaymentSucess,
}) => (
  <Modal
    isOpen={isOpen}
    style={customStyles}
    shouldCloseOnOverlayClick
    onRequestClose={onOverlayClick}
    overlayClassName="modal"
    className="modal-checkout__container"
  >
    <div className="row w-100 ml-0">
      <div className="col-12">
        <button
          className="modal-checkout__close"
          type="button"
          onClick={onOverlayClick}
        >
          <MdClose className="modal-checkout__close__icon" />
        </button>
        <div className="w-100 h-100 d-flex flex-column px-4 py-4">
          <p className="modal-checkout__title">
            {`${isPaymentSucess ? 'Parabéns você conclui sua compra' : 'Ops...parece que algo deu errado!'}`}
          </p>
          <div className="text-center my-1">
            {isPaymentSucess ? (
              <img
                src="/assets/images/warningMessage/emotionHappy.svg"
                alt="Emoticon feliz"
              />
            ) : (
              <img
                src="/assets/images/warningMessage/emotion.svg"
                alt="Emoticon chorando"
              />
            )}
          </div>
          <div className="text-center">
            <p className="unlogged unlogged__description">
              {`${isPaymentSucess ? 'Sua compra está sendo processada, aguarde o e-mail de confirmação' : errorMessage}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

export default CheckoutErrorModal;
