export default {
  apiKey: 'AIzaSyCOAGmlEBrehsP2sKK72dRVmdGzFY49ed0',
  authDomain: 'elopag-business.firebaseapp.com',
  databaseURL: 'https://elopag-business.firebaseio.com',
  projectId: 'elopag-business',
  storageBucket: 'elopag-business.appspot.com',
  messagingSenderId: '354883475502',
  appId: '1:354883475502:web:c94d0fa84c6c95126fe7bf',
  measurementId: 'G-P2DYS3THBJ',
};
