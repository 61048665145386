/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate, useParams, useLocation } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import Cropper from 'react-easy-crop'
import { handleImageCompress, convertToBase64 } from '../../helpers/compressImage';
import Select from 'react-select';
import useArticle from '../article/useArticle';
import getCroppedImg from '../../helpers/cropImage'
import * as SalesActions from '../../app/redux/actions/sale';
import { getSelectedSale } from '../../app/redux/reducers/sale';
import toast from '../../helpers/toast';
import validate from '../../helpers/form-validator';
import Header from '../../components/header/Header';
import Show from '../../components/show';
import './styles.scss';

export default function PostForm() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const { articles, getArticles } = useArticle();

  const salectedSale = useSelector(state => getSelectedSale(state));
  const [formData, setFormData] = useState({
    description: '',
    base64Logo: '',
    article: null,
  });
  const [selectedArticle, setSelectedArticle] = useState()
  const [optionsArticles, setOptionsArticles] = useState([])
  const [fileName, setFileName] = useState('');
  const [oldSale, setOldSale] = useState({});
  const [isEditPage, setIsEditPage] = useState(null);
  const [filePreview, setFilePreview] = useState('');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [showCropper, setShowCropper] = useState(false);

  const onFormDataChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const blob = await getCroppedImg(
        formData.base64Logo,
        croppedAreaPixels
      );
      setFilePreview(URL.createObjectURL(blob));
      const convertedToBase64 = await convertToBase64(blob);
      onFormDataChange(convertedToBase64, 'base64Logo');
      setShowCropper(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels])


  const getFileAndConvert = async (file) => {
    if (file) {
      if (file.type.includes('image')) {

        const compressedImage = await handleImageCompress(file);
        onFormDataChange(compressedImage, 'base64Logo');
        setShowCropper(true);
        setFileName(file.name);
      } else {
        toast.error('Esse tipo de arquivo não é suportado!');
        setFileName('');
      }
    }
  };

  const addSale = async () => {
    try {
      const isFormValid = validate.validateAddSaleForm(formData);
      if (isFormValid.error) {
        toast.error(isFormValid.errorMessage);
      } else {
        await dispatch(SalesActions.addSale(formData));
        toast.success('Promoção foi criada com sucesso');
        navigate(I18n.t('routes.post.url'));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateSale = async () => {
    try {
      const isFormValid = validate.validateUpdateSaleForm(formData, oldSale);
      if (isFormValid.error) {
        toast.error(isFormValid.errorMessage);
      } else {
        await dispatch(SalesActions.updateSale(formData, id));
        toast.success('Promoção foi alterada com sucesso');
        navigate(I18n.t('routes.post.url'));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setIsEditPage(id !== 'new');
    if (id !== 'new') dispatch(SalesActions.getSale(id));
  }, []);

  useEffect(() => {
    if (isEditPage) {
      if (!(Object.keys(salectedSale).length === 0) && salectedSale.sale) {
        setFormData({
          description: salectedSale.sale.description,
        });
        setOldSale(salectedSale.sale);
        setFilePreview(salectedSale.sale.images[0].url)
        setIsEditPage(true);

        if (salectedSale.sale.article) {
          setFormData({
            description: salectedSale.sale.description,
            article: salectedSale.sale.article._id,
          });
          setSelectedArticle({
            value: salectedSale.sale.article._id,
            label: (
              <div>
                {salectedSale.sale.article.title}
              </div>
            ),
          })
        }
      } else {
        setIsEditPage(null);
      }
    } else {

    }
  }, [salectedSale]);

  useEffect(() => {
    getArticles()

    if (!isEditPage && location.state.article) {
      setFormData({
        article: location.state.article._id,
      });
      setSelectedArticle({
        value: location.state.article._id,
        label: (
          <div>
            {location.state.article.title}
          </div>
        ),
      })
    }
  }, [])

  useEffect(() => {
    if (!articles) return

    const opt = []

    articles.map(item => {
      opt.push({
        value: item._id,
        label: (
          <div>
            {item.title}
          </div>
        ),
      })
    })

    setOptionsArticles(opt)
  }, [articles])

  return (
    <div className="page-container container post-form">
      <Header
        title={isEditPage ? 'Editar Publicação' : 'Adicionar uma nova publicação'}
        subTitle={isEditPage ? 'Altere os dados abaixo' : 'Preencha os dados abaixo'}
      />
      <div className="post-form__container">
        <div className="post-form__container__image-container">
          <div className="post-form__container__image-container__image-wrapper">
            <Show if={!showCropper && filePreview}>
              <img
                src={filePreview}
                className="post-form__container__image-container__image-wrapper__image"
              />
            </Show>

            <Show if={!showCropper && !filePreview}>
              <img
                src='/assets/images/post/no-image.jpg'
                className="post-form__container__image-container__image-wrapper__no-image"
              />
            </Show>

            <Show if={showCropper}>
              <div className="post-form__container__image-container__image-wrapper__cropper-container">
                <div className="post-form__container__image-container__image-wrapper__cropper">
                  <Cropper
                    image={formData.base64Logo}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                  <button
                    type="button"
                    onClick={showCroppedImage}
                    className="post-form__container__image-container__image-wrapper__cropper__btn-confirm"
                  >
                    Salvar alterações
                  </button>
                </div>
              </div>
            </Show>
          </div>

          <div
            htmlFor="file-upload"
            className="post-form__container__image-container__file__input"
          >
            <label
              htmlFor="file-upload"
              className={`${isEditPage || fileName !== '' ? 'post-form__container__image-container__file__title2'
                : 'post-form__container__image-container__file__title1'}`}
            >
              {isEditPage || fileName !== '' ? 'Trocar Imagem' : 'Adicionar Imagem'}
            </label>
            <input
              type="file"
              id="file-upload"
              className="post-form__container__image-container__file"
              accept=".jpeg, .tif, .jpg, .png"
              onInput={(e) => {
                if (e.target.files[0]) {
                  getFileAndConvert(e.target.files[0]);
                }
              }}
            />
          </div>
        </div>

        <div className="post-form__container__form">
          <div className="post-form__container__form__field">
            <span className="post-form__container__form__field__label">Descrição da publicação</span>
            <textarea
              className="post-form__container__form__field__input"
              type="text"
              maxLength="300"
              rows={16}
              placeholder="Descrição da promoção com informações sobre o funcionamento,
              produtos e serviços inclusos, entre outros."
              required
              value={formData.description}
              onChange={e => onFormDataChange(e.target.value, 'description')}
            />
          </div>
          <div className="post-form__container__form__field">
            <span className="post-form__container__form__field__label">Impulsionar Artigo?</span>
            <Select
              value={selectedArticle}
              onChange={(item) => {
                setSelectedArticle(item);
                onFormDataChange(item.value, 'article');
              }}
              placeholder="Escolha um artigo para monetizar"
              options={optionsArticles}
              className="credit-card-form__select"
              styles={{
                control: styles => ({
                  ...styles,
                  backgroundColor: 'transparent',
                  height: '100%',
                }),
              }}
            />
          </div>
          <button
            type="button"
            className="btn-primary post-form__container__form__button"
            onClick={() => {
              if (isEditPage) updateSale();
              else addSale();
            }}
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
}
