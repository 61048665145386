import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ChatServer from '../../app/api/chat';
import { getUser } from '../../app/redux/reducers/user';
import { getChatList, getSelectedChat } from '../../app/redux/reducers/chat';
import { setChatList, setSelectedChat } from '../../app/redux/actions/chat';
import Preferences from '../../data/preferences'

const useChat = () => {
  const dispatch = useDispatch();
  const { user: { companies } } = useSelector(state => getUser(state));
  const chats = useSelector(state => getChatList(state));
  const selectedChat = useSelector(state => getSelectedChat(state));

  const [take] = useState(Preferences.chatTake);
  const [skip, setSkip] = useState(0);
  const [oldLength, setOldLength] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [companyId] = useState(companies[0]._id);
  const [observable, setObservable] = useState();
  const [isUpdateChats, setIsUpdateChats] = useState(false);

  const getChats = async () => {
    return await ChatServer.getChats(companyId, skip, take);
  };

  const selectChat = (chat) => {
    const newChats = [...chats];
    newChats.find(c => c._id == chat._id).unreadMessages = 0;

    if (chat._id != selectedChat._id) {
      dispatch(setChatList(newChats));
      dispatch(setSelectedChat(chat));
      readMessages(chat._id);
    }
  }

  const createChat = async (customerId) => {
    const chat = await ChatServer.createChat(companyId, customerId);
    dispatch(setSelectedChat(chat));
  }

  const readMessages = (chatId) => {
    ChatServer.readMessages(chatId);
  };

  const fetchMore = async () => {
    setOldLength(chats.length);
    const newList = await getChats();
    dispatch(setChatList([...chats, ...newList]));
    setSkip(skip + take);
  }

  const updateChats = async () => {
    let newChats = null;
    const list = await ChatServer.getChats(companyId, 0, 1);
    const chat = list[0];
    const exists = chats.filter(e => e._id == chat._id);
  
    if (exists) {
      newChats = chats.filter(e => e._id != chat._id);
    } else {
      newChats = chats.pop();
    } 

    newChats.unshift(chat);
    dispatch(setChatList(newChats));
  }
  
  const chatUpdatedObservable = async() => {
    const chatUpdatedObservable = await ChatServer.chatUpdatedObservable();
    return chatUpdatedObservable.subscribe({
      next: async ({ data }) => {
        if (data?.chatUpdated) {
          setIsUpdateChats(true);
        }
      }
    });
  };

  useEffect(() => {
    if (isUpdateChats) {
      setIsUpdateChats(false);
      updateChats();
    }
  }, [isUpdateChats]);

  useEffect(() => {
    async function init() {
      const list = await getChats();
      dispatch(setChatList(list));
      setSkip(take);
    }
    init()
  }, []);

  useEffect(() => {
    const diffLength = chats.length - oldLength;
    setHasMore((diffLength) % 10 === 0 && diffLength > 0)
  }, [chats]);


  useEffect(() => {
    function initObservable() {
      const chatObservable = chatUpdatedObservable();
      setObservable(chatObservable);
    };

    initObservable();

    return () => {
      if (observable) {
        observable.unsubscribe();
      }
    };
  }, []);

  return { chats, hasMore, selectedChat, fetchMore, selectChat, createChat }
}

export default useChat;
