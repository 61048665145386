import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const CATEGORIES = gql`
  query Categories($filter: [Filter]){
    categories(filter: $filter){
      _id,
      name,
      picture {
        url
      }
      adminPicture{
        url
      }
    }
  }
`;

const SUBCATEGORIES = gql`
  query Categories($filter: [Filter]){
    categories(filter: $filter){
      _id,
      name,
    }
  }
`;

async function getCategories() {
  try {
    const filter = [{ field: 'type', value: 'C', operator: 'neq' }];
    const { data: { categories } } = await graphqlApi().query({
      query: CATEGORIES,
      variables: {
        filter,
      },
    });
    return categories;
  } catch (e) {
    const code = e.graphQLErrors.length ? e.graphQLErrors[0].code : null;
    return code || 'Ocorreu um erro, tente novamente em instantes';
  }
}

async function getSubCategories(categoryId) {
  try {
    const filter = [
      {
        field: 'type',
        value: 'C',
        operator: 'eq',
      },
      {
        field: 'parent',
        value: categoryId,
        operator: 'eq',
      },
    ];
    const { data: { categories } } = await graphqlApi().query({
      query: SUBCATEGORIES,
      variables: {
        filter,
      },
    });
    return categories;
  } catch (e) {
    const code = e.graphQLErrors.length ? e.graphQLErrors[0].code : null;
    return code || 'Ocorreu um erro, tente novamente em instantes';
  }
}


export default {
  getCategories,
  getSubCategories,
};
