import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const CALLS = gql`
query adminCalls($sort: [Sort], $filter: [Filter], $take: Int, $skip: Int) {
  adminCalls(sort: $sort, filter: $filter, take: $take, skip: $skip) {
    _id
    company {
      _id
    }
    customer {
      _id
      name
      cpf
    }
    status
    feedback
    feedbackMsg
    createdAt
    closedAt
  }
}
`;
const CALL = gql`
query adminCall($id: ID) {
  adminCall(id: $id) {
    _id
    company {
      _id
    }
    customer {
      _id
      name
      cpf
    }
    status
    feedback
    feedbackMsg
    createdAt
    closedAt
  }
}
`;

const CREATE_CALL = gql`
mutation createCall($company: ID!, $customer: ID!){
  createCall(data: {
    company: $company
    customer: $customer
  }){
    _id
    company {
      _id
    }
    customer {
      _id
      name
      cpf
    }
    status
    feedback
    feedbackMsg
    createdAt
    closedAt
  }
}
`;

const UPDATE_CALL = gql`
mutation updateCall($_id: ID!, $company: ID!, $customer: ID!, $feedback: String, $feedbackMsg: String){
  updateCall(data: {
    _id: $_id, company: $company, customer: $customer, feedback: $feedback, feedbackMsg: $feedbackMsg
  }){
    _id
    company {
      _id
    }
    customer {
      _id
      name
      cpf
    }
    status
    feedback
    feedbackMsg
    createdAt
    closedAt
  }
}
`;

const CALL_ANSWERED = gql`
  subscription {
    callAnswered
  }
`;

async function getCalls(companyId, take, skip) {
  try {
    const filter = [{ field: 'company', value: companyId }];
    const sort = [{ field: 'createdAt', dir: 'desc' }];
    const { data: { adminCalls } } = await graphqlApi().query({
      query: CALLS,
      variables: {
        sort,
        filter,
        take,
        skip,
      },
    });
    return adminCalls;
  } catch (error) {
    /** */
  }
}

async function getCall(id) {
  const { data: { adminCall } } = await graphqlApi().query({
    query: CALL,
    variables: {
      id,
    },
  });
  return adminCall;
}

async function callAnsweredObservable() {
  return await graphqlApi().subscribe({
    query: CALL_ANSWERED,
  });
}

async function createCall(company, customer) {
  try {
    return graphqlApi().mutate({
      mutation: CREATE_CALL,
      variables: {
        company,
        customer
      },
    });
  } catch (e) {
    const code = e.graphQLErrors.length ? e.graphQLErrors[0].code : null;
    return code || 'Ocorreu um erro, tente novamente em instantes';
  }
}

async function updateCall({ _id, company, customer, feedback, feedbackMsg }) {
  return graphqlApi().mutate({
    mutation: UPDATE_CALL,
    variables: {
      _id, company, customer, feedback, feedbackMsg
    },
  });
}

export default {
  getCall,
  getCalls,
  callAnsweredObservable,
  createCall,
  updateCall,
};
