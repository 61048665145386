import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input';
import CreditCardContainer from '../credit-card/credit-card-container';
import toast from '../../helpers/toast';
import './styles.scss';

const AddCreditsForm = ({
  handleNextStep,
  handleCancel,
  creditCards,
}) => {
  const [money, setMoney] = useState('100.00');
  const [numberOfMessage, setNumberOfMessage] = useState('0');
  const [valueOfMessage, setValueOfMessage] = useState('0.02');


  useEffect(() => {
    let messages;
      setValueOfMessage(0.02);
      messages = money / valueOfMessage;
      setNumberOfMessage(Math.trunc(messages));
  }, [money]);

  const handleFormSubmit = async () => {
    if (!money || money < 1.00) {
      toast.error('O valor deve ser de no mínimo R$ 1,00');
    } else if (creditCards && creditCards.length > 0) {
      handleNextStep(money, numberOfMessage, 'Checkout');
    } else {
      handleNextStep(money, numberOfMessage, 'AddNewCard');
    }
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="add-credits__containers">
          <h1 className="add-credits__containers__title">
            QUAL VALOR DO CRÉDITO?
          </h1>
          <div className="add-credits__containers__input-container">
            <p className="add-credits__containers__input-container__text">
              R$
            </p>
            <CurrencyInput
              value={money}
              className="add-credits__containers__input-container__input"
              thousandSeparator=""
              inputType="tel"
              maxLength={8}
              onChange={e => setMoney(e)}
            />
          </div>
          <div className="add-credits__containers__underlined" />
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 mt-4">
            <p className="add-credits__description">
              <strong className="add-credits__description-bold">
                {'Modelo Pay-Per-Use. '}
              </strong>
              Sua empresa adquire o crédito que desejar.
              <br />
              No campo acima informe o valor do credito que desejar comprar!
              <br />
              <strong className="add-credits__description-bold">
                Atenção:
              </strong> Seus créditos só serão descontados a partir do uso da plataforma.
              <br />
              <strong className="add-credits__description-bold">
                A EloPag Ads fica com 10% sobre os valores creditados na plataforma.
              </strong>
            </p>
          </div>
        </div>
      </div>
      
      <div className="container">
        <div className="row mb-3">
          <div className="col-md-6 col-sm-12 mt-3">
            {handleCancel ? (
              <button
                type="button"
                className="add-credits__decline-button"
                onClick={handleCancel}
              >
                Continuar sem adicionar creditos
              </button>
            ) : (
              <div>
                {creditCards && creditCards.length > 0 && creditCards.map(item => (
                  <CreditCardContainer
                    number={item.number}
                    brand={item.brand}
                    key={item.number}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="col-md-6 col-sm-12 mt-3">
            <button
              type="button"
              className="btn-primary btn-full-width"
              onClick={handleFormSubmit}
            >
              {creditCards && creditCards.length > 0 ? 'Continuar' : 'Adicionar cartão'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCreditsForm;
