import {
  addLoading,
  removeLoading,
} from './loading';
import CompanyRequests from '../../api/company';
import * as AuthActions from './auth';
import AdminRequest from '../../api/admin';
import WalletRequest from '../../api/wallet';

export const ACTION_SAVE_USER = 'SAVE_USER';
export const ACTION_CLEAN_USER = 'CLEAN_USER';
export const ACTION_SAVE_OCCUPATION_AREA = 'SAVE_OCCUPATION_AREA';
export const ACTION_SAVE_SUB_OCCUPATIONS = 'ACTION_SAVE_SUB_OCCUPATIONS';
export const ACTION_SAVE_CREDIT_CARD = 'SAVE_CREDIT_CARD';
export const ACTION_SAVE_NOTIFICATIONS = 'SAVE_NOTIFICATIONS';
export const ACTION_SAVE_UNREAD_NOTIFICATIONS = 'SAVE_UNREAD_NOTIFICATIONS';

export const saveUser = user => ({
  type: ACTION_SAVE_USER,
  user,
});

export const cleanUser = () => ({
  type: ACTION_CLEAN_USER,
  user: null,
});

export const saveOccupationArea = item => ({
  type: ACTION_SAVE_OCCUPATION_AREA,
  payload: item,
});

export const saveSubOccupationArea = ids => ({
  type: ACTION_SAVE_SUB_OCCUPATIONS,
  payload: ids,
});

export const saveCreditCard = card => ({
  type: ACTION_SAVE_CREDIT_CARD,
  payload: card,
});

export const saveNotifications = notifications => ({
  type: ACTION_SAVE_NOTIFICATIONS,
  payload: notifications,
});


export const saveUnreadNotifications = notifications => ({
  type: ACTION_SAVE_UNREAD_NOTIFICATIONS,
  payload: notifications,
});

export const getUser = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const user = await AdminRequest.getAdmin();
    await dispatch(saveUser(user));
  } catch (err) {
    dispatch(AuthActions.cleanAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const updateUser = data => async (dispatch) => {
  try {
    dispatch(addLoading());
    const user = await AdminRequest.updateAdmin(data);
    await dispatch(saveUser(user));
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const finishRegister = (data) => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const { subOccupationAreas, occupationArea } = getState().user;

    data.selectedOccupation = occupationArea;
    data.subOccupationAreas = subOccupationAreas;

    await CompanyRequests.registerCompany(data);
    await dispatch(getUser());
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCompany = data => async (dispatch) => {
  try {
    dispatch(addLoading());
    await CompanyRequests.updateCompanyData(data);
    await dispatch(getUser());
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const changePassword = data => async (dispatch) => {
  try {
    dispatch(addLoading());
    await AdminRequest.changePassword(data);
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const addCreditCard = data => async (dispatch) => {
  try {
    dispatch(addLoading());
    data = {
      ...data,
      number: data.number.split(' ').join(''),
    };
    const creditCard = await WalletRequest.addCreditCard(data);
    await dispatch(saveCreditCard([creditCard]));
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateBillingAddress = (data, cardID) => async (dispatch) => {
  try {
    dispatch(addLoading());
    data = {
      ...data,
      card: cardID,
    };
    const newCreditCard = await WalletRequest.updateBillingAddress(data);
    await dispatch(saveCreditCard([newCreditCard]));
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const getCreditCard = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const creditCard = await WalletRequest.getCreditCard();
    await dispatch(saveCreditCard(creditCard));
  } finally {
    dispatch(removeLoading());
  }
};

export const deletecreditCard = creditCardId => async (dispatch) => {
  dispatch(addLoading());
  try {
    await WalletRequest.deleteCreditCard(creditCardId);
    await dispatch(getCreditCard());
  } finally {
    dispatch(removeLoading());
  }
};

export const finalizePayment = data => async (dispatch) => {
  dispatch(addLoading());
  try {
    await WalletRequest.finalizePayment(data);
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};
export const getNotifications = (skip, take) => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const companyId = getState().user.user.companies[0]._id;
    const filter = [{ field: 'company', value: companyId }];
    const { notifications, count } = await AdminRequest.getNotifications(filter, skip, take);

    dispatch(saveNotifications({
      list: notifications,
      count,
    }));
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};
export const getUnreadNotifications = () => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const companyId = getState().user.user.companies[0]._id;
    const filter = [
      { field: 'company', value: companyId },
      { field: 'viewedAt', operator: 'isnotnull', value: 'false' },
    ];
    const notifications = await AdminRequest.getNotifications(filter);
    dispatch(saveUnreadNotifications(notifications.count));
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};
export const readNotifications = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    await AdminRequest.readNotifications();
    dispatch(saveUnreadNotifications(0));
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};
