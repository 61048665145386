import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import InputMask from 'react-input-mask';
import { MdLocalPhone, MdClose, MdPhoneInTalk } from 'react-icons/md';
import CallServer from '../../app/api/call';
import Show from '../../components/show';
import moment from 'moment-timezone';
import toast from '../../helpers/toast';

import './styles.scss';

const customStyles = {
  overlay: {
    background: 'rgb(0,0,0,0.85)',
  },
};

Modal.setAppElement('#root');

const CallModal = ({
  isOpen,
  onCallAnswer,
  onCreateCall,
  onClose,
  call,
  company,
}) => {
  const [calling, setCalling] = useState(false);
  const [time, setTime] = useState();

  useEffect(() => {
    if (call && call.status == 'WAITING') {
      subscribe(call._id);
    }

  }, [call]);
  
  const onCall = async() => {
    setCalling(true);
    try {
      const { data } = await CallServer.createCall(company, call.customer._id);
      onCreateCall(data.createCall);
    } catch (err) {
      toast.error('Você não possui créditos suficientes para fazer ligação!');
      setCalling(false);
    }
  }

  const subscribe = async(id) => {
    const count = startCountTime();
    setCalling(true);
    const callAnsweredObservable = await CallServer.callAnsweredObservable();
    const observable = callAnsweredObservable.subscribe({
      next: async ({ data: { callAnswered } }) => {
        if (callAnswered) {
          const call = await CallServer.getCall(id);

          setCalling(false);
          clearTimeout(count);
          setTime(null);
          observable.unsubscribe();
          onCallAnswer(call);
        }
      },
    });
  }

  const startCountTime = () => {
    return setInterval(() => {
      const newTime = moment().diff(moment(parseInt(call.createdAt)));
      setTime(moment(newTime).format('m:ss').toString());
    }, 1000);
  }

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      overlayClassName="modal"
      className="modal-call__container"
    >
      <div className="row w-100 ml-0">
        <div className="col-12">
          <div className="modal-call__text-container px-4 py-4">
            <div
              className="modal-call__avatar"
              style={{
                backgroundImage: `url(./assets/images/avatar.png)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            />
            <p className="modal-call__title">
              {call && call.customer && call.customer.name}
            </p>
            <p className="modal-call__description">
              <InputMask
                mask="999.999.999-99"
                placeholder="999.999.999-99"
                className="modal-call__description --input"
                disabled
                value={call && call.customer && call.customer.cpf}
              />
            </p>

            <Show if={calling}>
              <p className="modal-call__timer">{time}</p>
            </Show>
            
            <div className="modal-call__container-button">
              <Show if={calling}>
                <button
                  type="button"
                  className="modal-call__container-button__button button-green pulse"
                >
                  <MdPhoneInTalk size={30} />
                </button>
              </Show>

              <Show if={!calling}>
                <button
                  type="button"
                  onClick={onClose}
                  className="modal-call__container-button__button button-grey"
                >
                  <MdClose size={20} />
                </button>
                <button
                  type="button"
                  onClick={onCall}
                  className="modal-call__container-button__button button-green"
                >
                  <MdLocalPhone size={30} />
                </button>
              </Show>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CallModal;
