import React, { useState } from 'react'
import ImageChatModal from './ImageChatModal'

export const ImageChat = ({ url }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div>
      <button
        className='chat__body__message-row__content-image__image-button'
        onClick={() => setShowModal(true)}
      >
        <img
          className='chat__body__message-row__content-image__image'
          src={url}
          alt="That is an image sent on chat."
        />
      </button>

      <ImageChatModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        url={url}
      />
    </div>
  )
}
