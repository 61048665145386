import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import LoadingButton from '../loading-button';
import * as UserActions from '../../app/redux/actions/user';
import { LoadingSelectors } from '../../app/redux/reducers';
import { getAddressByCep } from '../../app/redux/actions/utils';
import { getCreditCard } from '../../app/redux/reducers/user';
import formatCurrency from '../../helpers/currency';
import CheckoutErrorModal from '../modal/Checkout';
import Validator from '../../helpers/form-validator';
import toast from '../../helpers/toast';
import optionsUF from '../../data/uf';
import CreditCardContainer from '../credit-card/credit-card-container';
import './styles.scss';

const Checkout = ({
  creditsValue,
  creditCards,
  messageNumber,
  onConfirmClick,
  onBackClick,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => LoadingSelectors.getLoading(state) > 0);
  const userCreditCard = useSelector(state => getCreditCard(state));
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState(false);
  const [isPaymentSucess, setisPaymentSucess] = useState(false);
  const [selectUF, setSelectedUF] = useState(null);
  const [billingAddressType, setBillingAddressType] = useState(true);
  const [cardId, setCardId] = useState('');
  const [incomingFormData, setIncomingFormData] = useState({});
  const [formData, setFormData] = useState({
    cep: '',
    address: '',
    addressNumber: '',
    neighborhood: '',
    city: '',
    uf: '',
    complement: '',
  });

  useEffect(() => {
    if (userCreditCard.length > 0) {
      userCreditCard.forEach((item) => {
        setIncomingFormData({
          cep: item.billingAddress.zipcode,
          address: item.billingAddress.street,
          addressNumber: item.billingAddress.number,
          neighborhood: item.billingAddress.neighborhood,
          city: item.billingAddress.city,
          uf: item.billingAddress.state,
          complement: item.billingAddress.complement,
        });
      });
    }
  }, [userCreditCard]);

  useEffect(() => {
    if (creditCards.length > 0) {
      creditCards.forEach((item) => {
        const { billingAddress } = item;
        const addres = {
          cep: billingAddress.zipcode,
          address: billingAddress.street,
          addressNumber: billingAddress.number,
          neighborhood: billingAddress.neighborhood,
          city: billingAddress.city,
          uf: billingAddress.state,
          complement: billingAddress.complement,
        };
        setFormData(addres);
        setIncomingFormData(addres);
        setCardId(item._id);
        setSelectedUF(optionsUF.find(ufItem => ufItem.value === billingAddress.state));
      });
    }
  }, []);

  const onFormDataChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleGetAddress = async () => {
    const {
      logradouro, complemento, bairro, localidade, uf,
    } = await dispatch(getAddressByCep(formData.cep));
    await setFormData({
      ...formData,
      address: logradouro,
      complement: complemento,
      neighborhood: bairro,
      city: localidade,
      uf,
    });
    setSelectedUF(optionsUF.find(item => item.value === uf));
  };

  const updateAddress = async () => {
    try {
      const isFormValid = Validator.validateChangeBillingAddress(formData, incomingFormData);
      if (isFormValid.error) {
        toast.error(isFormValid.errorMessage);
      } else if (isFormValid.sucessMessage === 'Enable') {
        setBillingAddressType(true);
      } else {
        await dispatch(UserActions.updateBillingAddress(formData, cardId));
        toast.success('O endereço de cobrança foi atualizado com sucesso!');
        setBillingAddressType(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const makePayment = async () => {
    try {
      const { value } = document.getElementById('antifraudInput');

      await dispatch(UserActions.finalizePayment({
        money: parseFloat(creditsValue),
        creditCardId: creditCards[0]._id,
        antiFraud: value,
      }));
      setisPaymentSucess(true);
      setIsErrorModalOpen(true);
    } catch (error) {
      setModalErrorMessage(error.message);
      setIsErrorModalOpen(true);
    }
  };

  const creditsValueTax = (parseFloat(creditsValue) * 10) / 100;
  const creditsValueWithTax = parseFloat(creditsValue) + parseFloat(creditsValueTax);

  return (
    <Fragment>
      <CheckoutErrorModal
        isOpen={isErrorModalOpen}
        onOverlayClick={() => {
          if (isPaymentSucess) {
            setIsErrorModalOpen(false);
            onConfirmClick();
          } else {
            setIsErrorModalOpen(false);
          }
        }}
        errorMessage={modalErrorMessage}
        isPaymentSucess={isPaymentSucess}
      />
      <div className="row">
        <div className="col-md-6 col-sm-12 checkout-form__divisor">
          <div className="checkout-form__address-container-title">
            <p className="checkout-form__title mb-0">
              Endereço de cobrança:
            </p>
            <button
              type="button"
              className="checkout-form__edit-button"
              onClick={() => {
                if (billingAddressType) {
                  setBillingAddressType(false);
                } else {
                  updateAddress();
                }
              }}
            >
              {`${billingAddressType ? 'Editar' : 'Atualizar'}`}
            </button>
          </div>
          <div className="checkout-form__form-input">
            <span className={`checkout-form__legend checkout-form__legend${billingAddressType ? '--disabled' : ''}`}>
              CEP
            </span>
            <InputMask
              mask="99999-999"
              className={`checkout-form__input checkout-form__input${billingAddressType ? '--disabled' : ''}`}
              placeholder="Digite CEP do endereço de cobrança"
              required
              disabled={billingAddressType}
              value={formData.cep}
              onChange={e => onFormDataChange(e.target.value, 'cep')}
              onBlur={() => handleGetAddress()}
            />
          </div>
          <div className="checkout-form__form-input">
            <input
              placeholder="Digite o endereço de cobrança"
              className={`checkout-form__input checkout-form__input${billingAddressType ? '--disabled' : ''}`}
              required
              disabled={billingAddressType}
              value={formData.address}
              onChange={e => onFormDataChange(e.target.value, 'address')}
            />
            <span className={`checkout-form__legend checkout-form__legend${billingAddressType ? '--disabled' : ''}`}>
              Endereço
            </span>
          </div>
          <div className="checkout-form__form-input">
            <input
              placeholder="Digite o bairro do endereço de cobrança"
              className={`checkout-form__input checkout-form__input${billingAddressType ? '--disabled' : ''}`}
              required
              disabled={billingAddressType}
              value={formData.neighborhood}
              onChange={e => onFormDataChange(e.target.value, 'neighborhood')}
            />
            <span className={`checkout-form__legend
              checkout-form__legend${billingAddressType ? '--disabled' : ''}`}
            >
              Bairro
            </span>
          </div>
          <div className="checkout-form__form-input">
            <div className="checkout-form__divided-container-input ">
              <input
                placeholder="Digite o número do endereço de cobrança"
                className={`checkout-form__input checkout-form__input${billingAddressType ? '--disabled' : ''}`}
                required
                disabled={billingAddressType}
                value={formData.addressNumber}
                onChange={e => onFormDataChange(e.target.value, 'addressNumber')}
              />
              <span className={`checkout-form__legend
                checkout-form__legend${billingAddressType ? '--disabled' : ''}`}
              >
                Número
              </span>
            </div>
            <div className="checkout-form__divided-container-input ">
              <input
                placeholder="Digite o complemento do endereço de cobrança"
                className={`checkout-form__input checkout-form__input${billingAddressType ? '--disabled' : ''}`}
                required
                disabled={billingAddressType}
                value={formData.complement}
                onChange={e => onFormDataChange(e.target.value, 'complement')}
              />
              <span className={`checkout-form__legend
                checkout-form__legend${billingAddressType ? '--disabled' : ''}`}
              >
                Complemento
              </span>
            </div>
          </div>
          <div className="checkout-form__form-input">
            <div className="checkout-form__divided-container-input ">
              <input
                placeholder="Digite a cidade do endereço de cobrança"
                className={`checkout-form__input checkout-form__input${billingAddressType ? '--disabled' : ''}`}
                required
                disabled={billingAddressType}
                value={formData.city}
                onChange={e => onFormDataChange(e.target.value, 'city')}
              />
              <span className={`checkout-form__legend
                checkout-form__legend${billingAddressType ? '--disabled' : ''}`}
              >
                Cidade
              </span>
            </div>
            <div className="checkout-form__divided-container-input ">
              <Select
                value={selectUF}
                onChange={(item) => {
                  setSelectedUF(item);
                  onFormDataChange(item.value, 'uf');
                }}
                placeholder="Escolha o estado..."
                options={optionsUF}
                isDisabled={billingAddressType}
                className={`checkout-form__select checkout-form__select${billingAddressType ? '--disabled' : ''}`}
                styles={{
                  control: styles => ({
                    ...styles,
                    backgroundColor: 'transparent',
                    height: '100%',
                  }),
                  menuList: styles => ({
                    ...styles,
                    maxHeight: '120px',
                  }),
                }}
              />
              <span className={`checkout-form__legend
                checkout-form__legend${billingAddressType ? '--disabled' : ''}`}
              >
                Estado
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <p className="checkout-form__title">
            Cartão selecionado:
          </p>
          {creditCards.length > 0 && creditCards.map(item => (
            <CreditCardContainer
              number={item.number}
              brand={item.brand}
              key={item.number}
            />
          ))}
          <p className="checkout-form__title mt-3">
            Dados da compra:
          </p>
          <div className="checkout-form__details-container">
            <p className="checkout-form__details-container__text">
              Valor da compra:
            </p>
            <p className="checkout-form__details-container__text checkout-form__details-container__text--bold">
              {formatCurrency(creditsValue)}
            </p>
          </div>
          <div className="checkout-form__details-container">
            <p className="checkout-form__details-container__text checkout-form__details-container__text--small">
              Taxa de serviço:
            </p>
            <p className="checkout-form__details-container__text checkout-form__details-container__text--small">
              {formatCurrency(creditsValueTax)}
            </p>
          </div>
          <div className="checkout-form__details-container">
            <p className="checkout-form__details-container__text checkout-form__details-container__text--small">
              Total:
            </p>
            <p className="checkout-form__details-container__text checkout-form__details-container__text--small">
              {formatCurrency(creditsValueWithTax)}
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-6 col-sm-12 mb-3">
          <button
            type="button"
            className="btn-primary btn-full-width"
            onClick={onBackClick}
          >
            Voltar
          </button>
        </div>
        <div className="col-md-6 col-sm-12 mb-3">
          <LoadingButton
              loading={loading}
              title='Finalizar compra'
              onClick={makePayment}
              disabled={!billingAddressType}
            />
        </div>
      </div>
    </Fragment>
  );
};

export default Checkout;
