import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { navigate, useParams } from '@reach/router';

import { I18n } from 'react-redux-i18n';
import toast from '../../helpers/toast';

import FormWrapper from '../../components/shared/form/FormWrapper';
import RedefinePasswordForm from '../../components/redefine-password/RedefinePasswordForm';

import { validatePasswordToken } from '../../app/redux/actions/auth';

export default function RedefinePassword() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [valid, setValid] = useState(false);

  useEffect(() => {
    async function initialize() {
      try {
        await dispatch(validatePasswordToken(token));
        setValid(true);
        toast.success('Redefina sua senha!');
      } catch (err) {
        toast.error(err.message);
        setTimeout(() => {
          navigate(I18n.t('routes.login.url'));
        }, 3000);
      }
    }

    initialize();
  }, [dispatch, token]);

  return (
    <FormWrapper>
      {valid && <RedefinePasswordForm />}
    </FormWrapper>
  );
}
