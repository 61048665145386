import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useCall from './useCall'
import List from './List'
import Show from '../../components/show';
import { ListContentLoader } from './ListContentLoader'
import CallModal from '../../components/modal/CallModal';
import CallAnsweredModal from '../../components/modal/CallAnsweredModal';
import CustomerSearchModal from '../../components/modal/customer-search/CustomerSearchModal';
import Header from '../../components/header/Header';
import './styles.scss';

const CallPage = ({ company }) => {
  const {
    calls,
    setCalls,
    call,
    setCall,
    hasMore,
    checkOpenChat,
    handleSearch,
    checkWaitingChat,
    handleClickAdd,
    onCloseCallAnswered,
    showCustomerSearchModal,
    handleCustomerSearchModel,
    handleSetShowCallmodal,
    showCallModal,
    showCallAnsweredModal,
    onCallAnswer,
    fetchMoreCalls
  } = useCall();


  return (
    <div id="call" className="call page-container container-fluid">
      <Header
        title="Suas Ligações"
        subTitle="Exibindo suas ligações"
        onClickAdd={() => handleClickAdd()}
      />
      <Show if={calls && calls.length > 0}>
        <List
          data={calls}
          fetchMore={fetchMoreCalls}
          onHasMore={hasMore}
        />
      </Show>
      <Show if={calls.length <= 0}>
       <ListContentLoader />
      </Show>

      <CustomerSearchModal
        isOpen={showCustomerSearchModal}
        onChange={handleSearch}
        onClose={() => handleCustomerSearchModel(false)}
      />
      <CallModal
        isOpen={showCallModal}
        onCallAnswer={onCallAnswer}
        onCreateCall={(newCall) => { setCall(newCall) }}
        onClose={() => handleSetShowCallmodal(false)}
        call={call}
        company={company}
      />
      <CallAnsweredModal
        isOpen={showCallAnsweredModal}
        onClose={onCloseCallAnswered}
        call={call}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  company: state.user && state.user.user.companies[0]._id,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CallPage);
