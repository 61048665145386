import React, { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { getCreditCard } from '../../app/redux/reducers/user';
import toast from '../../helpers/toast';
import CheckoutForm from '../../components/checkout';
import AddCreditsForm from '../../components/add-credits';
import Header from '../../components/header/Header';
import Show from '../../components/show';
import './styles.scss';

export default function MyPlan() {
  const responseCreditCard = useSelector(state => getCreditCard(state));
  const [creditCards, setCreditCard] = useState([]);
  const [money, setMoney] = useState('0');
  const [messageNumber, setMessageNumber] = useState(0);
  const [formPage, setFormPage] = useState(1);

  useEffect(() => {
    setCreditCard(responseCreditCard);
  }, [responseCreditCard]);

  const handleNextStep = (value, numberOfMessages, nextStep) => {
    if (nextStep === 'Checkout') {
      if (value >= 1.00) {
        setMoney(value);
        setMessageNumber(numberOfMessages);
        setFormPage(2);
      } else {
        toast.error('O valor deve ser de no mínimo R$ 1,00');
      }
    } else if (nextStep === 'AddNewCard') {
      navigate(I18n.t('routes.addCrediCard.url'));
    }
  };

  return (
    <div className="page-container container">
      <Show if={formPage === 1}>
        <Header
          title="Recarga de Mensagens"
          subTitle="Compre créditos"
        />
        <AddCreditsForm
          creditCards={creditCards}
          handleNextStep={(value, numberOfMessages, nextStep) => handleNextStep(value, numberOfMessages, nextStep)}
        />
      </Show>

      <Show if={formPage === 2}>
        <Header
          title="Resumo da Compra"
          subTitle="Finalize sua compra"
        />
        <CheckoutForm
          creditsValue={money}
          creditCards={creditCards}
          messageNumber={messageNumber}
          onConfirmClick={() => navigate(I18n.t('routes.dashboard.url'))}
          onBackClick={() => setFormPage(1)}
        />
        <input
          type="hidden"
          id="antifraudInput"
          value=""
        />
      </Show>
    </div>
  );
}
