import {
  addLoading,
  removeLoading,
} from './loading';
import CategoriesRequests from '../../api/categories';

export const ACTION_SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST';
export const ACTION_SET_SUBCATEGORIES_LIST = 'SET_SUBCATEGORIES_LIST';

const getCategories = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const categories = await CategoriesRequests.getCategories();
    dispatch({ type: ACTION_SET_CATEGORIES_LIST, payload: categories });
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

const getSubCategories = categoryId => async (dispatch) => {
  dispatch(addLoading());
  try {
    const subcategories = await CategoriesRequests.getSubCategories(categoryId);
    await dispatch({ type: ACTION_SET_SUBCATEGORIES_LIST, payload: subcategories });
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};


export default {
  getCategories,
  getSubCategories,
};
