import React from 'react';
import { connect } from 'react-redux';
import parseDateTime from '../../helpers/parse-date-time'
import ListItem from './ListItem';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingInfinityScroll from '../../components/loading-infinity-scroll/LoadingInfinityScroll';

import './styles.scss';

const List = ({
  data = [],
  fetchMore,
  onHasMore
}) => {

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMore}
      hasMore={onHasMore}
      loader={<LoadingInfinityScroll />}
    >
      <div className="table-border">
        <table key="tablecall" className="table table-striped">
          <thead key="thead" className="thead-dark">
            <tr>
              <th key="foto">Foto</th>
              <th key="nome">Nome</th>
              <th key="datime">Data/Hora</th>
              <th key="status">Status</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 && data.map((call, key) => {
              return (
                <ListItem
                  key={key}
                  cpf={call.customer && call.customer.cpf}
                  customerAvatar={call.customer && call.customer.avatar}
                  customerName={call.customer && call.customer.name}
                  status={call.status}
                  dateTime={parseDateTime(call.createdAt)}
                  handleClick={() => null}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </InfiniteScroll>
  );
};

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
