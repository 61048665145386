import React from 'react';
import brands from './credit-card-brand';

const options = brands.map(item => ({
  value: item.brand,
  label: (
    <div>
      <img
        alt="Flag"
        src={`/assets/images/credit-card/${item.brand}.png`}
        style={item.brand === brands[2].brand ? { marginLeft: '10px', marginRight: '10px', width: '30px' }
          : { width: '50px' }}
      />
      {''}
      {item.name}
    </div>
  ),
}));

export default options;
