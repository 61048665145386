import React from 'react';
import Show from '../show'
import './style.scss';

const LoadingButton = ({ loading, title, onClick, disabled = false }) => (
  <>
    <Show if={!loading}>
      <button
        type="button"
        onClick={onClick}
        className={`btn-full-width ${disabled ? 'btn-disabled' : 'btn-primary'}`}
        disabled={disabled}
      >
        {title}
      </button>
    </Show>

    <Show if={loading}>
      <div className="loading-button">
        <div className="loading-button__inner">
          <img
            className="loading-button__inner__spinner"
            src="/assets/images/ic_loading_spinner.svg"
            alt="Loading"
          />
        </div>
      </div>
    </Show>

  </>
);

export default LoadingButton;
