import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import toast from '../../helpers/toast';
import validator from '../../helpers/form-validator';
import formatters from '../../helpers/formatters'
import FormHeader from '../shared/form/FormHeader';
import FormFooter from '../shared/form/FormFooter';
import { forgotPassword } from '../../app/redux/actions/auth';
import Show from '../show';
import './styles.scss';

export default function ForgotPasswordForm() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [sended, setSended] = useState(false);
  const [resendButtonDisabled, setResendButtonDisabled] = useState(false);

  const onFormSubmit = async (e) => {
    e.preventDefault();

    const validate = validator.validateForgotPasswordForm({ email });
    if (validate.error) {
      toast.error(validate.errorMessage);
      return;
    }

    if (resendButtonDisabled) {
      toast.error('Espere alguns segundos para enviar novamente!');
      return;
    }

    try {
      await dispatch(forgotPassword(email));
      setSended(true);
      setResendButtonDisabled(true);
      setTimeout(() => setResendButtonDisabled(false), 30000);
      toast.success('Verifique seu e-mail, e siga as instruções.');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <section className="form-wrapper__section">
      <FormHeader />
      <div className="container">
        <div className="row d-flex justify-content-center">
        <div className="col-md-8 col-lg-6 form-wrapper__container">
          <form
            onSubmit={e => onFormSubmit(e)}
          >
            <div className="form-wrapper__container__form justify-content-center pb-5">
              <span className="form-wrapper__container__form__legend">E-mail</span>
              <input
                type="text"
                placeholder="Digite seu e-mail"
                className="form-wrapper__container__form__input"
                required
                value={email}
                maxLength={254}
                onChange={e => setEmail(formatters.email(e.target.value))}
              />
       
              <Show if={sended}>
                <h2 className="forgot-password forgot-password__bottom-text forgot-password__bottom-text__text">
                  Ainda não recebeu o e-mail?
                </h2>
                <button
                  tonClick={(e) => onFormSubmit(e)}
                  type="button"
                  className={`forgot-password forgot-password__bottom-text 
                  forgot-password__bottom-text__button 
                  ${resendButtonDisabled ? 'forgot-password__bottom-text__button--disabled' : ''}`}
                >
                Clique Aqui!
                </button>
              </Show>
            </div>
            <FormFooter
              onFormSubmit={e => onFormSubmit(e)}
              buttonTitle="CONCLUIR"
            />
          </form>
          </div>
        </div>
      </div>
    </section>
  );
}
