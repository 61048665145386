export default [
  {
    brand: 'visa',
    name: 'Visa',
  },
  {
    brand: 'mastercard',
    name: 'MasterCard',
  },
  {
    brand: 'maestro',
    name: 'Maestro',
  },
  {
    brand: 'elo',
    name: 'Elo',
  },
  {
    brand: 'hipercard',
    name: 'Hipercard',
  },
  {
    brand: 'diners-club',
    name: 'Dinners Club',
  },
  {
    brand: 'american-express',
    name: 'American Express',
  },
];
