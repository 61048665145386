import React, { useState } from 'react';
import ChatList from './ChatList';
import ChatTalk from './ChatTalk';
import useChat from './useChat';
import './styles.scss';

const ChatPage = () => {
  const { chats, hasMore, selectedChat, fetchMore, selectChat, createChat } = useChat();
  const [status, setStatus] = useState(selectedChat ? false : true)

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus)
  }

  return (
    <div className="chat-container page-container">
      <div className="chat">
        <div className={`chat__left ${!status ? 'chat__left__hide' : ''}`}>
          <ChatList
            data={chats}
            selectedChat={selectedChat}
            hasMore={hasMore}
            onFetchMore={fetchMore}
            onSelectChat={selectChat}
            onCreateChat={createChat}
            onStatus={handleStatusChange}
          />
        </div>
        <div className={`
          chat__right 
          ${status ? 'chat__right__hide' : ''}
          ${!selectedChat ? 'chat__right__not-selected' : ''}
          `}>
          <ChatTalk onStatus={handleStatusChange} />
        </div>
      </div>
    </div>
  );
};


export default ChatPage;
