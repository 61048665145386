import React from 'react';

import FormWrapper from '../../components/shared/form/FormWrapper';
import RegisterForm from '../../components/register/RegisterForm';

const Register = () => (
  <>
    <FormWrapper>
      <RegisterForm />
    </FormWrapper>
  </>
);

export default Register;
