/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cropper from 'react-easy-crop';
import { BiCut } from 'react-icons/bi';

import { getUser } from '../../app/redux/reducers/user';
import * as UserActions from '../../app/redux/actions/user';
import toast from '../../helpers/toast';
import getCroppedImg from '../../helpers/cropImage';
import { handleImageCompress, convertToBase64 } from '../../helpers/compressImage';

export default function MyPlan() {
  const dispatch = useDispatch();
  const { user } = useSelector(state => getUser(state));

  const [base64Logo, setBase64Logo] = useState('');
  const [fileName, setFileName] = useState('');
  const [filePreview, setFilePreview] = useState('');

  const [image, setImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [showCropper, setShowCropper] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const blob = await getCroppedImg(
        image,
        croppedAreaPixels,
        0
      );
      setFilePreview(URL.createObjectURL(blob));
      const convertedToBase64 = await convertToBase64(blob)
      setBase64Logo(convertedToBase64);
      console.log(convertedToBase64);
      setShowCropper(false);

    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels])

  const getFileAndConvert = async (file) => {
    if (file) {
      if (file.type.includes('image')) {
        const compressedImage = await handleImageCompress(file);
        setImage(compressedImage);
        setShowCropper(true);
        setFileName(file.name);
        // reader.onerror = (error) => {
          //   toast(error);
          // };
      } else {
        toast.error('Esse tipo de arquivo não é suportado!');
        setFileName('');
        setFilePreview('');
      }
    }
  };

  const updateCompany = async () => {
    try {
      if (base64Logo === '') {
        toast.error('Insira uma imagem diferente!');
      } else {
        await dispatch(UserActions.updateCompany({ logo: base64Logo, id: user.companies[0]._id }));
        toast.success('Logo foi alterada com sucesso');
        setFileName('');
        setFilePreview('');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const cancelUpdate = () => {
    setFileName('');
    setFilePreview('');
  };

  return (
    <div className="container">

      <div className="row px-0">
        <p className="change-logo__title">
          Escolha sua foto de perfil
        </p>
      </div>
      <div className="row px-0 position-relative">
        <div className="change-logo__file__container">
          <div
            htmlFor="file-upload"
            className="change-logo__file__input"
          >
            <label
              htmlFor="file-upload"
              className="change-logo__file__title1"
            >
              Trocar imagem
            </label>
            <input
              type="file"
              id="file-upload"
              className="change-logo__file"
              accept=".jpeg, .tif, .jpg, .png"
              onInput={(e) => {
                if (e.target.files[0]) {
                  getFileAndConvert(e.target.files[0]);
                }
              }}
            />
          </div>
          <p className="change-logo__file__name">
            {fileName}
          </p>
        </div>
      </div>
      {showCropper ?
        <div className="change-logo__container">
          <div className="change-logo__cropper-container">
            <div className="change-logo__cropper">
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <button
              type="button"
              onClick={showCroppedImage}
              className="change-logo__confirmButton"
            >
              <p className="change-logo__confirmButton__text">
                Aplicar
                </p>
            </button>
          </div>
        </div> :
        <>{user.companies && user.companies[0] && (
          <div className="row px-0">
            <div className="change-logo__logo__container">
              <div
                className="change-logo__logo__image"
                style={{
                  backgroundImage: `url(${filePreview || user.companies[0].logo.url})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </div>
          </div>
        )}</>
      }

      <div className="row px-0">
        <div className="col-12 d-flex justify-content-end mt-3 px-0 postion-relative">
          {filePreview && (
            <Fragment>
              <button
                type="button"
                onClick={() => cancelUpdate()}
                className="change-logo__cancelButton"
              >
                <p className="change-logo__cancelButton__text">
                  Cancelar
                </p>
              </button>
              <button
                type="button"
                onClick={() => updateCompany()}
                className="change-logo__confirmButton"
              >
                <p className="change-logo__confirmButton__text">
                  Confirmar
                </p>
              </button>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
