import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const CHATS = gql`
query AdminChats($filter: [Filter], $sort: [Sort], $skip: Int, $take: Int) {
  adminChats(filter: $filter, sort: $sort, skip: $skip, take: $take) {
    _id
    customer{
      _id
      name
      cpf
    }
    count
    unreadMessages
    startedAt
    updatedAt
    lastMessage
    isActive
  }
}
`;

export const CHAT_MESSAGES = gql`
  query AdminMessages($_id: ID!, $take: Int, $skip: Int) {
    adminChatMessages(_id: $_id, take: $take, skip: $skip) {
        _id
      chat{
        _id
      }
      text
      image {
        _id
        url
      }
      createdAt
      customer{
        _id
      }
      call{
        _id
      }
      system
    }
  }
`;

export const CREATE_CHAT_MESSAGE = gql`
  mutation AdminCreateChatMessage($chat: ID!, $text: String, $image: String, $document: String){
    adminCreateChatMessage(data: {
      chat: $chat
      text: $text
      image: $image
      document: $document
    }) {
      _id
    }
  }
`;

export const UPDATE_CHAT = gql`
  mutation updateChat($chat: ID!, $isActive: Boolean){
    updateChat(data: {
      chat: $chat
      isActive: $isActive
    }){
    _id,
    isActive
    }
  }
`;

export const READ_MESSAGES = gql`
  mutation UpdateMessages($chat:ID!){
    updateMessages(chat:$chat)
  }
`;

export const GET_UNREAD_MESSAGE = gql`
  query adminUnreadChatMessages($companyId: ID!){
    adminUnreadChatMessages(companyId: $companyId) 
  }
`;

export const CHAT_UPDATED = gql`
  subscription {
    chatUpdated
  }
`;

export const CHAT_MESSAGE_UPDATED = gql`
  subscription {
    chatMessageUpdated
  }
`;

export const CREATE_CHAT = gql`
  mutation adminCreateChat($company: ID!, $customer: ID!){
    adminCreateChat(data: {
      company: $company
      customer: $customer
    }) {
      _id
      customer{
        _id
        name
        cpf
      }
      count
      unreadMessages
      startedAt
      updatedAt
      lastMessage
      isActive
    }
  }
`;

async function getChats(companyId, skip, take) {
  try {
    const filter = [{ field: 'company', value: companyId }];
    const sort = [{ field: 'updatedAt', dir: 'desc' }];
    const { data: { adminChats } } = await graphqlApi().query({
      query: CHATS,
      variables: {
        filter,
        sort,
        skip,
        take
      },
    });
    return adminChats;
  } catch (error) {
    /** */
  }
}

async function chatUpdatedObservable() {
  return await graphqlApi().subscribe({
    query: CHAT_UPDATED,
  });
}

async function chatMessageUpdatedObservable() {
  return await graphqlApi().subscribe({
    query: CHAT_MESSAGE_UPDATED,
  });
}

async function getChatMessages(chatId, skip, take) {
  try {
    const { data: { adminChatMessages } } = await graphqlApi().query({
      query: CHAT_MESSAGES,
      variables: {
        _id: chatId,
        take,
        skip,
      },
    });
    return adminChatMessages;
  } catch (error) {
    /** */
  }
}

async function createChatMessage({ chat, text, image, document }) {
  try {
    await graphqlApi().mutate({
      mutation: CREATE_CHAT_MESSAGE,
      variables: {
        chat,
        text,
        image,
        document
      },
    });
  } catch (e) {
    const code = e.graphQLErrors && e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      INACTIVE_CHAT: 'Este chat está inativo.',
      INSUFFICIENT_FUNDS: 'Você não possui créditos suficientes para mandar mensagens!',
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function updateChat(chatId, isActive) {
  await graphqlApi().mutate({
    mutation: UPDATE_CHAT,
    variables: {
      chat: chatId,
      isActive,
    },
  });
}

async function readMessages(chat) {
  await graphqlApi().mutate({
    mutation: READ_MESSAGES,
    variables: {
      chat,
    },
  });
}

async function getUnreadMessages(companyId) {
  try {
    const { data: { adminUnreadChatMessages } } = await graphqlApi().query({
      query: GET_UNREAD_MESSAGE,
      variables: {
        companyId,
      },
    });
    return adminUnreadChatMessages;
  } catch (error) {
    /** */
  }
}

async function createChat(companyId, customerId) {
  const { data: { adminCreateChat } } = await graphqlApi().mutate({
    mutation: CREATE_CHAT,
    variables: {
      company: companyId,
      customer: customerId,
    },
  });
  return adminCreateChat;
}

export default {
  getChats,
  getChatMessages,
  chatUpdatedObservable,
  chatMessageUpdatedObservable,
  createChatMessage,
  updateChat,
  readMessages,
  getUnreadMessages,
  createChat
};
