import React from 'react';

import Brands from '../../data/credit-card-brand';

const CardContainer = ({
  number,
  brand,
  handleDeleteClick,
}) => {
  const brandName = Brands.find(item => item.brand === brand);
  return (
    <div className="credit-card__container">
      <img
        alt={brand}
        src={`/assets/images/credit-card/${brand}.png`}
        className={`credit-card__image ${brand === Brands[2].brand ? 'credit-card__image--alternative' : ''}`}
      />
      <div className="credit-card__description-container">
        <p className="credit-card__number">
          {number}
        </p>
        <p className="credit-card__description">
          {brandName.name}
        </p>
      </div>
      <button
        type="button"
        onClick={handleDeleteClick}
        className="btn-primary card-button"
      >
        Deletar
      </button>
    </div>
  );
};

export default CardContainer;
