import React from 'react';

import CreditcardForm from '../../components/credit-card/credit-card-form';

const AddCredits = ({ handleNextStep, handleBackStep }) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="register-continuation__title">Adicionar cartão de crédito</h1>
        </div>
      </div>
      <CreditcardForm
        handleSave={handleNextStep}
        handleBackStep={handleBackStep}
      />
    </>
  );
};

export default AddCredits;
