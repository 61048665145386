import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { navigate } from '@reach/router';

import DefaultButton from '../../../components/shared/DefaultButton';
import StorageService from '../../../app/services/storage';

import { ReactComponent as ImageFooter } from '../../../svg/tutorial/tutorial_footer_image.svg';

import '../TutorialStepOne/style.scss';

const TutorialStepOne = ({ currentStep }) => (
  <main>
    {currentStep === 3 && (
      <Fragment>
        <div className="row tutorial tutorial__responsive d-flex h-100">
          <div className="col-10 col-sm-10 col-md-10 col-xl-6 col-lg-6 offset-lg-1 offset-xl-1">
            <div className="tutorial__content-container">
              <h1 className="tutorial__title">Mensagens monetizadas</h1>
              <div className="tutorial__title__underline" />
              <div className="tutorial__description-container">
                <p className="tutorial__description">
                Atenda o seu cliente no chat em tempo real , converse e venda os seus produtos e serviços.
                Ah! Fique tranquilo!  Os planos são pré-pagos e você só é descontado por mensagens enviadas.
                Já o seu cliente recebe créditos em dinheiro por toda mensagens que você envia , sua empresa
                também pode conceder bônus de R$ 1,00 , R$ 2,00 e R$ 5,00  reais toda vez que o cliente compra
                o seu produto ou serviço.
                </p>
              </div>

              <DefaultButton
                name="AVANÇAR"
                onClick={() => {
                  StorageService.set('skipTutorial', true);
                  navigate(I18n.t('routes.dashboard.url'));
                }}
              />
            </div>
          </div>

          <div className="col-10 col-sm-10 col-md-10 col-xl-4 col-lg-4 pb-5">
            <div className="tutorial__body-image-container3">
              <img
                src="/assets/images/tutorial/image_tutorial_step3.png"
                alt="Homem monetizando o seu negócio"
                className="tutorial__body-image-3"
              />
            </div>
          </div>
          <ImageFooter className="tutorial__footer-image d-none d-lg-block" />
        </div>
      </Fragment>
    )}
  </main>
);

export default TutorialStepOne;
