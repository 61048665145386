import CallServer from '../../api/call';
import { addLoading, removeLoading } from './loading';

export const ACTION_SET_CALLS = 'SET_CALLS';

export const setCalls = list => ({
  type: ACTION_SET_CALLS,
  payload: list,
});

export const createCall = (customer) => async (dispatch, getState) => {
  try {
    const { user: { user: { companies } } } = getState();
    await CallServer.createCall(companies[0]._id, customer);
  } catch (err) {
    throw new Error(err.message);
  } finally {
  }
};
