import {
  addLoading,
  removeLoading,
} from './loading';
import AdminRequests from '../../api/admin';
import NumberHelper from '../../../helpers/number';

// eslint-disable-next-line
export const getAddressByCep = (zipcode) => async (dispatch) => {
  dispatch(addLoading());
  let address = null;
  try {
    address = await AdminRequests.getAddressByCep(NumberHelper.keepOnlyNumber(zipcode));
  } finally {
    dispatch(removeLoading());
  }
  return address;
};
