import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const CREATE_COMPANY = gql`
mutation CreateCompany(
    $name: String,
    $cnpj: String,
    $cep: String!,
    $street: String,
    $complement: String,
    $city: String,
    $state: String,
    $categories: [ID]!
    $subCategories: [ID]
    $logo: String
    $radius: Float
  ) {
    createCompany(
      data: {
        name: $name
        cnpj: $cnpj
        address:{
          cep: $cep
          street: $street,
          complement: $complement,
          city: $city,
          state: $state,
        }
        categories: $categories,
        subCategories: $subCategories,
        logo: $logo
        radius: $radius
      }
    ) {
      _id,
      name,
      fancyName
    }
  }
`;

const UPDATE_COMPANY = gql`
mutation UpdateCompany(
    $logo: String!
    $id: ID!
  ) {
    updateCompany(
      data: {
        logo: $logo,
        _id: $id,
      }
    ) {
      _id,
      name,
    }
  }
`;

async function registerCompany(data) {
  try {
    const { data: createCompany } = await graphqlApi().mutate({
      mutation: CREATE_COMPANY,
      variables: {
        name: data.name,
        cnpj: data.document,
        cep: data.cep,
        street: data.address,
        complement: data.complement,
        city: data.city,
        state: data.uf,
        categories: [data.selectedOccupation],
        subCategories: data.subOccupationAreas,
        logo: data.base64Logo,
        radius: parseFloat(data.radius),
      },
    });
    return createCompany;
  } catch (e) {
    const code = e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes',
      MISSING_TOKEN: 'Sessão Expirada, por favor faça login novamente.',
      ADDRESS_NOT_FOUND: 'O Endereço inserido não é válido, por favor tente novamente.',
    };

    throw new Error(erros[code]);
  }
}

async function updateCompanyData(data) {
  try {
    const { data: updateCompany } = await graphqlApi().mutate({
      mutation: UPDATE_COMPANY,
      variables: {
        logo: data.logo,
        id: data.id,
      },
    });
    return updateCompany;
  } catch (e) {
    const code = e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes',
    };

    throw new Error(erros[code]);
  }
}

export default {
  registerCompany,
  updateCompanyData,
};
