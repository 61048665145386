export default {
  http: {
    // localhost: 'http://localhost:3001/graphql',
    localhost: 'https://api.elopag.com.br/graphql',
    homolog: 'https://qa-api.elopag.com.br/graphql',
    production: 'https://api.elopag.com.br/graphql',
  },
  ws: {
    // localhost: 'ws://localhost:3001/graphql',
    localhost: 'wss://api.elopag.com.br/graphql',
    homolog: 'wss://qa-api.elopag.com.br/graphql',
    production: 'wss://api.elopag.com.br/graphql',
  }
};
