import React, { useState } from 'react';
import CustomerSearchModal from '../../components/modal/customer-search/CustomerSearchModal';
import ChatListItem from './ChatListItem';
import IconSearch from '../../svg/ic_search.svg';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingInfinityScroll from '../../components/loading-infinity-scroll/LoadingInfinityScroll';
import parseDateTime from '../../helpers/parse-date-time';
import InputMask from 'react-input-mask';
import './styles.scss';

const ConversationList = ({
  data,
  selectedChat,
  hasMore,
  onFetchMore,
  onSelectChat,
  onCreateChat,
  onStatus,
}) => {
  const [searchText, setSearchText] = useState('');
  const [cpf, setCpf] = useState('');
  const [showCustomerSearchModal, setShowCustomerSearchModal] = useState(false);

  const handleSearchTextChange = (text) => {
    setSearchText(text);
  };

  const handleClick = (chat) => {
    onSelectChat(chat);
    onStatus(false);
  };

  const handleSearch = (customer) => {
    onCreateChat(customer._id)
    setShowCustomerSearchModal(false)
    setSearchText('')
    setCpf('')
  }

  const handleClose = () => {
    setShowCustomerSearchModal(false)
    setSearchText('')
    setCpf('')
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setCpf(searchText)
    setShowCustomerSearchModal(true)
  }

  return (
    <>
      <div className="chat__search">
        <div className="chat__search__input-container">
          <form className="chat__search__input-container__form" onSubmit={handleSubmit}>
            <InputMask
              mask="999.999.999-99"
              className="chat__search__input-container__input"
              placeholder="Pesquise pelo CPF"
              required
              value={searchText}
              onChange={(e) => handleSearchTextChange(e.target.value)}
            />
            <button
              type="submit"
              className="chat__search__button"
              onClick={handleSubmit}
            >
              <img
                className="chat__search__input-container__icon"
                src={IconSearch}
                alt="search"
              />
            </button>
          </form>
        </div>
      </div>
      
      <div id="chat-list" className="chat__list">
        <InfiniteScroll
          scrollableTarget="chat-list"
          dataLength={data.length}
          next={onFetchMore}
          hasMore={hasMore}
          loader={<LoadingInfinityScroll />}
        >

          {data?.length > 0 && data?.map((item) => {
            return (
              <ChatListItem
                key={item?._id}
                name={item?.customer?.name}
                cpf={item?.customer?.cpf}
                lastMessage={item?.lastMessage}
                lastMessageTime={parseDateTime(item?.updatedAt)}
                onClick={() => handleClick(item)}
                isSelected={selectedChat?._id === item?._id}
                unreadMessages={item?.unreadMessages}
              />
            );
          })}
        </InfiniteScroll>
      </div>

      <CustomerSearchModal
        cpf={cpf}
        isOpen={showCustomerSearchModal}
        onChange={handleSearch}
        onClose={() => handleClose()}
      />
    </>
  );
};

export default ConversationList;
