import React from 'react';

import './style.scss';

const DefaultButton = ({ name, onClick, disabled = false }) => (
  <button
    type="button"
    onClick={onClick}
    className={`defaultButton ${disabled ? 'defaultButton--disabled' : ''}`}
    disabled={disabled}
  >
    {name}
  </button>
);

export default DefaultButton;
