import { ACTION_SET_CATEGORIES_LIST, ACTION_SET_SUBCATEGORIES_LIST } from '../actions/settings';

const initialState = {
  categories: [],
  subcategories: [],
};
export default (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case ACTION_SET_CATEGORIES_LIST:
      return {
        ...state,
        categories: action.payload,
      };
    case ACTION_SET_SUBCATEGORIES_LIST:
      return {
        ...state,
        subcategories: action.payload,
      };
    default:
      return state;
  }
};
