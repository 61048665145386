import React from 'react';
import './styles.scss';

const SubcategoryItem = ({
  name, isSelected, handleClick,
}) => (
  <button
    type="button"
    className="modal-subcategory__subcategory__button"
    onClick={() => handleClick()}
  >
    {isSelected ? (
      <img
        src="/assets/svg/checked.svg"
        alt="Checked"
        className="modal-subcategory__subcategory__check"
      />
    ) : (
      <div className="modal-subcategory__subcategory__uncheck" />
    )}

    <p className="modal-subcategory__subcategory__title">
      {name}
    </p>
  </button>
);

export default SubcategoryItem;
