import React from 'react';
import LoadingButton from '../../loading-button';
import './styles.scss';

export default function FormFooter({ loading = false, onFormSubmit, buttonTitle }) {
  return (
    <section className="form-wrapper__section form-wrapper__section__form-footer">
      <div className="container">
        <LoadingButton
          loading={loading}
          title={buttonTitle}
          onClick={onFormSubmit}
        />
      </div>
    </section>
  );
}
