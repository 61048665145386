import React from 'react';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import './styles.scss';
import useArticle from './useArticle'
import Header from '../../components/header/Header';
import { ArticleList } from './ArticleList';
import { ArticleSkeleton } from './ArticleSkeleton';
import Show from '../../components/show';

export default function Article() {
  const { articles, hasMore, fetchMore, deleteArticle } = useArticle();

  const handleClickAdd = () => {
    navigate(`${I18n.t('routes.article.url')}/new`);
  };

  const handleDelete = async (id) => {
    await deleteArticle(id)
  };

  return (
    <div className="page-container container-fluid">
      <Header
        title="Seus Artigos"
        subTitle="Exibindo seus artigos"
        onClickAdd={() => handleClickAdd()}
      />
      <Show if={articles && articles.length > 0}>
        <ArticleList
          data={articles}
          fetchMore={fetchMore}
          onHasMore={hasMore}
          onDelete={handleDelete}
        />
      </Show>
      <Show if={articles && articles.length <= 0}>
       <ArticleSkeleton />
      </Show>
    </div>
  );
}
