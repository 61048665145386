import React from 'react';
import ArticleListItem from './ArticleListItem';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingInfinityScroll from '../../components/loading-infinity-scroll/LoadingInfinityScroll';
import './styles.scss';

export const ArticleList = ({
  data = [],
  fetchMore,
  onHasMore,
  onDelete,
}) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMore}
      hasMore={onHasMore}
      loader={<LoadingInfinityScroll />}
    >
      <div className="table-border">
        <table key="tablecall" className="table table-striped">
          <thead key="thead" className="thead-dark">
            <tr>
              <th key="title">Titulo</th>
              <th key="subtitle">Sub Titulo</th>
              <th key="datime">Data/Hora</th>
              <th key="actions" width="120">Ações</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 && data.map((item, key) => {
              return (
                <ArticleListItem
                  key={key}
                  id={item._id}
                  slug={item.slug}
                  title={item.title}
                  subtitle={item.subtitle}
                  createdAt={item.createdAt}
                  onDelete={onDelete}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </InfiniteScroll>
  );
};
