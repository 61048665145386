import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const CUSTOMER = gql`
query adminCustomerByCpf($cpf: String) {
  adminCustomerByCpf(cpf: $cpf) {
    _id
    name
    cpf
  }
}
`;

async function getCustomerByCpf(cpf) {
  try {
    const data = await graphqlApi().query({
      query: CUSTOMER,
      variables: {
        cpf
      },
    });
    return data.data.adminCustomerByCpf;
  } catch (error) {
    /** */
  }
}

export default {
  getCustomerByCpf,
};
