/* eslint-disable no-console */
import './styles/scss/index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import moment from 'moment-timezone';
import jstz from 'jstimezonedetect';
import SecureLS from 'secure-ls';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';

import Config from './app/config/app.config';
import i18nDictionary from './i18n';
import * as AuthActions from './app/redux/actions/auth';
import initalizeRedux from './app/redux';
import Routes from './app/routes';
import AuthServices from './app/services/auth';
import * as UserActions from './app/redux/actions/user';

const redux = initalizeRedux();
const tz = jstz.determine();
const timezone = tz.name() || Config.timezone;

export const axiosInstance = axios.create();
export const ls = new SecureLS({ encodingType: 'aes' });

async function authenticated(store) {
  if (AuthServices.isAuthenticated()) {
    const auth = AuthServices.get();
    await store.dispatch(AuthActions.saveAuthentication(auth));
    await store.dispatch(UserActions.getUser());
    await store.dispatch(UserActions.getCreditCard());
    await store.dispatch(UserActions.getUnreadNotifications());
  }
}

const initialization = async () => {
  try {
    let { language } = Config;
    if (!i18nDictionary[language]) ({ language } = Config);

    syncTranslationWithStore(redux);
    redux.dispatch(loadTranslations(i18nDictionary));
    redux.dispatch(setLocale(language));

    moment.tz.setDefault(timezone);
    moment.locale(language);

    axiosInstance.interceptors.response.use(
      response => response,
      async (err) => {
        if (err.response.status === 401) {
          redux.dispatch(AuthActions.cleanAuth());
        }
        return Promise.reject(err);
      },
    );

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('./firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Registration successful, scope is:', registration.scope);
        })
        .catch((err) => {
          console.log('Service worker registration failed, error:', err);
        });
    }

    await authenticated(redux);
  } catch (err) {
    // Redirect to error page
  } finally {
    const launchLoader = document.getElementById('loading');
    launchLoader.classList.add('hide');
  }
};

const initialize = async () => {
  await initialization();

  ReactDOM.render(
    <Provider store={redux}>
      <Routes />
    </Provider>,
    document.getElementById('root'),
  );
};

initialize();
