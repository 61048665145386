import { Router } from '@reach/router';
import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import { ToastContainer } from 'react-toastify';

import Public from './Public';
import Panel from './Panel';
import Private from './Private';

import Login from '../../pages/login';
import Register from '../../pages/register';
import ForgotPassword from '../../pages/forgot-password';
import RedefinePassword from '../../pages/redefine-password';
import RegisterContinuation from '../../pages/register-continuation';
import EmailValidation from '../../pages/email-validation';
import Tutorial from '../../pages/tutorial';
import Dashboard from '../../pages/dashboard';
import Chat from '../../pages/chat';
import Call from '../../pages/call';
import Post from '../../pages/post';
import PostForm from '../../pages/post/PostForm';
import Article from '../../pages/article';
import ArticleForm from '../../pages/article/ArticleForm';
import BuyCredits from '../../pages/buy-credits';
import Settings from '../../pages/settings';
import Notifications from '../../pages/notifications';
import AddCreditCard from '../../pages/credit-card';

import messaging from '../config/firebase-messaging.config';
import StorageService from '../services/storage';

class Content extends React.PureComponent {
  componentDidMount() {
    if (messaging) {
      messaging.requestPermission().then(async () => {
        const token = await messaging.getToken();
        StorageService.reset('@elopag_business_token_fcm');
        StorageService.set('@elopag_business_token_fcm', token);
      });

      navigator.serviceWorker.addEventListener('message', (message) => {
        const { data } = message;
        const firebaseMsgData = data['firebase-messaging-msg-data'];
        const firebaseNotification = firebaseMsgData.notification;

        navigator.serviceWorker.ready.then((registration) => {
          if (window.location.pathname !== I18n.t('routes.chat.url')) {
            registration.showNotification(firebaseNotification.title, {
              body: firebaseNotification.body,
              icon: firebaseNotification.icon,
              vibrate: [200, 100, 200, 100, 200, 100, 200],
            });
          }
        });
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Router>
          <Public
            path="/"
            container={<Login />}
            title={`${I18n.t('routes.login.pageTitle')} - ${I18n.t('application.name')}`}
          />
          <Public
            path={I18n.t('routes.login.url')}
            container={<Login />}
            title={`${I18n.t('routes.login.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Public
            path={I18n.t('routes.forgotPassword.url')}
            container={<ForgotPassword />}
            title={`${I18n.t('routes.forgotPassword.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Public
            path={`${I18n.t('routes.redefinePassword.url')}/:token`}
            container={<RedefinePassword />}
            title={`${I18n.t('routes.redefinePassword.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Public
            path={I18n.t('routes.register.url')}
            container={<Register />}
            title={`${I18n.t('routes.register.pageTitle')} - ${I18n.t('application.name')}`}
          />
          <Public
            path={`${I18n.t('routes.register.url')}/:refCustomer`}
            container={<Register />}
            title={`${I18n.t('routes.register.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Private
            path={I18n.t('routes.registerContinuation.url')}
            container={<RegisterContinuation />}
            title={`${I18n.t('routes.registerContinuation.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Public
            path={`${I18n.t('routes.emailValidation.url')}/:token`}
            container={<EmailValidation />}
            title={`${I18n.t('routes.emailValidation.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Public
            path={I18n.t('routes.tutorial.url')}
            container={<Tutorial />}
            title={`${I18n.t('routes.tutorial.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={I18n.t('routes.dashboard.url')}
            container={<Dashboard />}
            title={`${I18n.t('routes.dashboard.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={I18n.t('routes.chat.url')}
            container={<Chat />}
            title={`${I18n.t('routes.chat.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={I18n.t('routes.call.url')}
            container={<Call />}
            title={`${I18n.t('routes.call.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={I18n.t('routes.post.url')}
            container={<Post />}
            title={`${I18n.t('routes.post.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={`${I18n.t('routes.post.url')}/:id`}
            container={<PostForm />}
            title={`${I18n.t('routes.post.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={I18n.t('routes.article.url')}
            container={<Article />}
            title={`${I18n.t('routes.article.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={`${I18n.t('routes.article.url')}/:id`}
            container={<ArticleForm />}
            title={`${I18n.t('routes.article.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={I18n.t('routes.credits.url')}
            container={<BuyCredits />}
            title={`${I18n.t('routes.credits.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={I18n.t('routes.settings.url')}
            container={<Settings />}
            title={`${I18n.t('routes.settings.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={I18n.t('routes.notifications.url')}
            container={<Notifications />}
            title={`${I18n.t('routes.notifications.pageTitle')} - ${I18n.t('application.name')}`}
          />

          <Panel
            path={I18n.t('routes.addCrediCard.url')}
            container={<AddCreditCard />}
            title={`${I18n.t('routes.settings.pageTitle')} - ${I18n.t('application.name')}`}
          />


        </Router>
        <ToastContainer
          position="bottom-center"
          autoClose={2500}
          hideProgressBar
        />
      </Fragment>
    );
  }
}

export default Content;
