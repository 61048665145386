import { SaleActions } from '../actions';

const initialState = {
  sales: [],
  selectedSale: {},
};
export default (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SaleActions.ACTION_GET_SALES:
      return {
        ...state,
        sales: action.sales,
      };
    case SaleActions.ACTION_GET_SALE:
      return {
        ...state,
        selectedSale: action.selectedSale,
      };
    default:
      return state;
  }
};

export function getSales(state) {
  return state.sale;
}

export function getSelectedSale(state) {
  return state.sale.selectedSale;
}
