import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { IoIosClose, IoIosSearch } from 'react-icons/io';
import WarningMessage from '../../../components/shared/WarningMessage';
import InputMask from 'react-input-mask';
import validator from '../../../helpers/form-validator';
import CustomerServer from '../../../app/api/customer';
import './styles.scss';
import Show from '../../show';

Modal.setAppElement('#root');

const CustomerSearchModal = ({
  cpf = '',
  isOpen,
  onChange,
  onClose,
}) => {
  const [customer, setCustomer] = useState();
  const [errorNotExist, setErrorNotExist] = useState(false);
  const [errorInvalid, setErrorInvalid] = useState(false);
  const [formData, setFormdata] = useState({
    cpf,
  });

  const onFormDataChange = (value, field) => {
    setFormdata({
      ...formData,
      [field]: value,
    });
  };

  const clean = () => {
    onFormDataChange('', 'cpf')
    setErrorInvalid(false);
    setErrorNotExist(false);
    setCustomer(null);
  }

  const handleClose = () => {
    clean();
    onClose();
  }

  const handleSearch = async () => {
    const isFormValid = validator.validateGetCustomerByCpfForm(formData);
    if (isFormValid.error) {
      setErrorInvalid(true);
      setCustomer(null);
    } else {
      try {
        const c = await CustomerServer.getCustomerByCpf(formData.cpf.replace(/[^0-9]/g, ''));
        setCustomer(c);
        setErrorInvalid(false);
        setErrorNotExist(false);
      } catch (err) {
        setErrorNotExist(true);
        setErrorInvalid(false);
        setCustomer(null);
      }
    }
  }

  const handleNext = () => {
    onChange(customer);
    clean();
  }

  useEffect(() => {
    if (cpf) {
      onFormDataChange(cpf, 'cpf')
    }
  }, [cpf])

  useEffect(() => {
    handleSearch()
  }, [formData])

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName="modal"
      className="customer-search-modal"
    >
      <div className="customer-search-modal__header">
        <div className="customer-search-modal__header__title">
          Buscar via CPF
        </div>
        <button
          type="button"
          onClick={handleClose}
          className="customer-search-modal__header__close"
        >
          <IoIosClose />
        </button>

      </div>
      <div className="customer-search-modal__container">
        <div className="customer-search-modal__container__search">
          <div className="customer-search-modal__container__search__desc">
            Para:
          </div>
          <InputMask
            mask="999.999.999-99"
            placeholder="Digite um CPF para buscar"
            className="customer-search-modal__container__search__input"
            required
            value={formData.cpf}
            onChange={e => onFormDataChange(e.target.value, 'cpf')}
          />
          <button
            type="button"
            onClick={() => handleSearch()}
            className="customer-search-modal__container__search__button"
          >
            <IoIosSearch />
          </button>
        </div>
        <div className="customer-search-modal__container__content">
          <Show if={customer}>
            <div className="customer-search-modal__container__content__customer">
              <div className="customer-search-modal__container__content__customer__avatar">
                <img src="./assets/images/avatar.png" />
              </div>
              <p className="customer-search-modal__container__content__customer__title">
                {customer && customer.name}
              </p>
              <p className="customer-search-modal__container__content__customer__description">
                <InputMask
                  mask="999.999.999-99"
                  placeholder="999.999.999-99"
                  className="customer-search-modal__container__content__customer__description"
                  disabled
                  value={customer && customer.cpf}
                />
              </p>
              <button
                type="button"
                onClick={handleNext}
                className="btn-primary"
              >
                {cpf ? 'Falar' :  'Próximo'}
              </button>
            </div>
          </Show>
          <Show if={errorNotExist}>
            <WarningMessage
              message="Ops! Cliente ainda não pertence a nossa base de dados."
              haveButton={false}
            />
          </Show>

          <Show if={errorInvalid}>
            <WarningMessage
              message="Ops! CPF inválido."
              haveButton={false}
            />
          </Show>
        </div>
      </div>
    </Modal>
  );
}

export default CustomerSearchModal;
