import { UserActions } from '../actions';

const initialState = {
  occupationArea: '',
  subOccupationAreas: [],
  user: {},
  creditCard: [],
  unreadNotifications: 0,
  notifications: [],
};
export default (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case UserActions.ACTION_SAVE_USER:
      return {
        ...state,
        user: action.user,
      };
    case UserActions.ACTION_SAVE_OCCUPATION_AREA:
      return {
        ...state,
        occupationArea: action.payload,
      };
    case UserActions.ACTION_SAVE_SUB_OCCUPATIONS:
      return {
        ...state,
        subOccupationAreas: action.payload,
      };
    case UserActions.ACTION_SAVE_CREDIT_CARD:
      return {
        ...state,
        creditCard: action.payload,
      };
    case UserActions.ACTION_SAVE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case UserActions.ACTION_SAVE_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unreadNotifications: action.payload,
      };
    default:
      return state;
  }
};

export function getUser(state) {
  return state.user;
}

export function getOcuppationArea(state) {
  return state.user.occupationArea;
}

export function getSubOcuppationArea(state) {
  return state.user.subOccupationAreas;
}

export function getCreditCard(state) {
  return state.user.creditCard;
}

export function getNotificationsSelector(state) {
  return state.user.notifications;
}
