import * as DashboardActions from '../actions/dashboard';

const initialState = {
  unreadMessages: 0,
  accountBalance: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DashboardActions.ACTION_SAVE_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: action.payload,
      };
    case DashboardActions.ACTION_SAVE_ACCOUNT_BALANCE:
      return {
        ...state,
        accountBalance: action.payload,
      };
    default:
      return state;
  }
};

export function getDashboard(state) {
  return state.dashboard;
}
