import React from 'react';

import FormWrapper from '../../components/shared/form/FormWrapper';
import ForgotPasswordForm from '../../components/forgot-password/ForgotPasswordForm';

export default function ForgotPassword() {
  return (
    <FormWrapper>
      <ForgotPasswordForm />
    </FormWrapper>
  );
}
