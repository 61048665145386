import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { IoIosMenu} from 'react-icons/io';
import { getUser } from '../../app/redux/reducers/user';
import toast from '../../helpers/toast';
import SideBar from './SideBar';
import './styles.scss';

const Painel = ({ container }) => {
  const { user, unreadNotifications } = useSelector(state => getUser(state));
  const [showSideBar, setShowSideBar] = useState(true);

  useEffect(() => {
    if (!user.companies || user.companies.length === 0) {
      toast.error('Finalize seu cadastro para continuar!');
      navigate(I18n.t('routes.registerContinuation.url'));
    }
  }, [user]);

  const toggleSidebar = () => {
    setShowSideBar(!showSideBar);
  }

  return (user.companies && user.companies.length > 0
    && (
      <Fragment>
        <div className={`painel__container ${!showSideBar && 'hide-sidebar'}`}>
          <SideBar
            companyName={user.name && user.name}
            branchesQuantity={user.companies && user.companies.length}
            companyLogo={user.companies && user.companies[0] && user.companies[0].logo.url}
          />
          <div className="painel__content">
            <div className="painel__content__top">
              <div className="painel__content__top__left">
                <button
                  className="painel__content__top__menu"
                  onClick={() => toggleSidebar()}
                >
                  <IoIosMenu />
                </button>
              </div>
              <div className="painel__content__top__right">
                <button
                  className="painel__content__top__notifications"
                  type="button"
                  onClick={() => navigate(I18n.t('routes.notifications.url'))}
                >
                  <img
                    src="/assets/svg/dashboard_bell.svg"
                    alt="Logo Dash"
                  />
                  {unreadNotifications > 0 && (
                    <div className="painel__content__top__count-notifications">
                      <p className="painel__content__top__count-notifications__text">
                        {unreadNotifications}
                      </p>
                    </div>
                  )}
                </button>
              </div>
            </div>
            {container}
          </div>
        </div>
      </Fragment>
    )
  );
};

export default Painel;
