import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const GET_ARTICLE = gql`
  query Article($id: ID) {
    article(id: $id) {
      _id
      createdAt
      title
      subtitle
      body
      slug
    }
  }
`;

const GET_ARTICLES = gql`
  query Articles(
    $sort: [Sort],
    $filter: [Filter],
    $take: Int,
    $skip: Int
  ) {
    articles(
      sort: $sort,
      filter: $filter,
      take: $take,
      skip: $skip
    ) {
        _id
        createdAt
        title
        subtitle
        slug
      }
    }
`;

const CREATE_ARTICLE = gql`
  mutation CreateArticle(
    $company: ID!,
    $title: String!,
    $subtitle: String!,
    $body: String!,
  ) {
    createArticle(
      data: {
        company: $company
        title: $title
        subtitle: $subtitle
        body: $body
      }
    ) {
      _id
      title
    }
  }
`;

const UPDATE_ARTICLE = gql`
  mutation UpdateArticle(
    $_id: ID!,
    $title: String!,
    $subtitle: String!,
    $body: String!,
  ) {
    updateArticle(
      data: {
        _id: $_id
        title: $title
        subtitle: $subtitle
        body: $body
      }
    ) {
      _id
    }
  }
`;

const DELETE_ARTICLE = gql`
  mutation DeleteArticle($_id: ID!) {
    deleteArticle(_id: $_id)
  }
`;

async function getArticle(articleId) {
  try {
    const { data: article } = await graphqlApi().query({
      query: GET_ARTICLE,
      variables: {
        id: articleId,
      },
    });
    return article;
  } catch (err) {
    throw new Error('Não encontramos esse artigo');
  }
}

async function getArticles(companyId, take, skip) {
  try {
    const filter = [{ field: 'company', value: companyId }];
    const sort = [{ field: 'createdAt', dir: 'desc' }];
    const { data: { articles } } = await graphqlApi().query({
      query: GET_ARTICLES,
      variables: {
        sort,
        filter,
        take,
        skip,
      },
    });
    return articles;
  } catch (e) {
    return e;
  }
}

async function createArticle(data, companyId) {
  try {
    const { data: { createArticle } } = await graphqlApi().mutate({
      mutation: CREATE_ARTICLE,
      variables: {
        company: companyId,
        title: data.title,
        subtitle: data.subtitle,
        body: data.body,
      },
    });
    return createArticle;
  } catch (e) {
    const code = e.graphQLErrors && e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      INSUFFICIENT_FUNDS: 'Você não tem fundos suficientes para fazer essa ação!. Minimo pra essa ação R$ 10,00',
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function updateArticle(data) {
  try {
    const { data: { updateArticle } } = await graphqlApi().mutate({
      mutation: UPDATE_ARTICLE,
      variables: data,
    });
    return updateArticle;
  } catch (err) {
    const code = err.graphQLErrors.length > 0 ? err.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function deleteArticle(articleId) {
  try {
    const { data: { deleteArticle } } = await graphqlApi().mutate({
      mutation: DELETE_ARTICLE,
      variables: {
        _id: articleId,
      },
    });
    return deleteArticle;
  } catch (err) {
    const code = err.graphQLErrors.length > 0 ? err.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

export default {
  getArticle,
  getArticles,
  createArticle,
  updateArticle,
  deleteArticle,
};
