import React from 'react';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import usePost from './usePost'
import { PostList } from './PostList';
import './styles.scss';
import Header from '../../components/header/Header';
import { PostSkeleton } from './PostSkeleton';


export default function Post() {
  const { posts, hasMore, fetchMore, deletePost } = usePost();

  const handleClickAdd = () => {
    navigate(`${I18n.t('routes.post.url')}/new`);
  };

  const handleEdit = (saleId) => {
    navigate(`${I18n.t('routes.post.url')}/${saleId}`);
  };

  const handleDelete = async (id) => {
    await deletePost(id)
  };

  return (
    <div className="page-container container-fluid">
      <Header
        title="Suas Publicações"
        subTitle="Exibindo suas publicaçoes"
        onClickAdd={() => handleClickAdd()}
      />
      {posts && posts.length > 0 ? (
        <PostList
          data={posts}
          fetchMore={fetchMore}
          onHasMore={hasMore}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      ) : (
        <PostSkeleton />
      )}
    </div>
  );
}
