import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { getUser } from '../../app/redux/reducers/user';
import SaleRequest from '../../app/api/sale';
import toast from '../../helpers/toast';
import Preferences from '../../data/preferences'

const usePost = () => {
  const [posts, setPosts] = useState([]);
  const [take] = useState(Preferences.PostTake);
  const [skip, setSkip] = useState(0);
  const [oldPostsLength, setOldPostsLength] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const { user: { companies } } = useSelector(state => getUser(state));

  const getPosts = async () => {
    return await SaleRequest.getSales(companies[0]._id, skip, take);
  }

  const fetchMore = async () => {
    setOldPostsLength(posts.length);
    const newList = await getPosts();
    setPosts([...posts, ...newList]);
    setSkip(skip + take);
  }

  useEffect(() => {
    async function init() {
      const list = await getPosts();
      setPosts(list);
      setSkip(take);
    }
    init()
  }, []);

  useEffect(() => {
    const diffPostsLength = posts.length - oldPostsLength;
    setHasMore((diffPostsLength) % 12 === 0 && diffPostsLength > 0)
  }, [posts]);

  const deletePost = async (postId) => {
    try {
      await SaleRequest.removeSale(postId);
      toast.success('Promoção foi deletada com sucesso');
      const list = await getPosts();
      setPosts(list);
    } catch (error) {
      toast.error(error.message);
    }
  }

  return { posts, hasMore, fetchMore, deletePost }
}

export default usePost
