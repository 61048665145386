import React from "react";
import ContentLoader from "react-content-loader";

const Skeleton = () => (
  <ContentLoader
    height={"350"}
    width={"100%"}
    backgroundColor="#ecebeb"
    foregroundColor="#E6E6E6"
  >
    <rect x="0" y="10" rx="3" ry="3" width="100%" height="255" />
    <rect x="0" y="280" rx="3" ry="3" width="100%" height="20" />
    <rect x="0" y="310" rx="3" ry="3" width="130" height="20" />
  </ContentLoader>
);

export const PostSkeleton = () => {
  return (
    <div className="row">
      <div className="col-xl-3 col-lg-4 col-md-4">
        <Skeleton />
      </div>
      <div className="col-xl-3 col-lg-4 col-md-4">
        <Skeleton />
      </div>
      <div className="col-xl-3 col-lg-4 col-md-4">
        <Skeleton />
      </div>
      <div className="col-xl-3 col-lg-4 col-md-4">
        <Skeleton />
      </div>
      <div className="col-xl-3 col-lg-4 col-md-4">
        <Skeleton />
      </div>
      <div className="col-xl-3 col-lg-4 col-md-4">
        <Skeleton />
      </div>
      <div className="col-xl-3 col-lg-4 col-md-4">
        <Skeleton />
      </div>
      <div className="col-xl-3 col-lg-4 col-md-4">
        <Skeleton />
      </div>
    </div>
  )
}
