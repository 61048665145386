import React from 'react';
import Number from '../../helpers/number';

const ChatItem = ({
  onClick,
  name,
  cpf,
  lastMessage,
  lastMessageTime,
  isSelected,
  unreadMessages,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick()
  }
  return (
    <button
      type="button"
      className={`chat__search__item chat__search__item
    ${isSelected ? 'chat__search__item chat__search__item--selected' : ''}`}
      onClick={handleClick}
    >
      <div
        style={{
          width: '100%',
          textAlign: 'start',
        }}
      >
        <p className={`chat__search__item__company-title`}>
          {name ? `${name}` : 'Usuário não existe'}
        </p>
        <span
          className={`chat__search__item__last-message ${unreadMessages > 0
            ? 'chat__search__item__last-message--unread' : ''}`}
        >
          {lastMessage}
        </span>
      </div>
      <div>
        <span className="chat__search__item__time">{lastMessageTime}</span>
        {unreadMessages > 0 && (
          <div className="chat__search__item__notifications">
            <p className="chat__search__item__notifications__text">
              {unreadMessages}
            </p>
          </div>
        )}
      </div>
    </button>
  )
};

export default ChatItem;
