import React, { Fragment, useState } from 'react';

import TutorialStepOne from './TutorialStepOne';
import TutorialStepTwo from './TutorialStepTwo';
import TutorialStepThree from './TutorialStepThree';
import InstitutionalHeader from '../../components/shared/institutional-header/Header';


const TutorialPage = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(3);
    }
  };

  return (
    <Fragment>
      <InstitutionalHeader />
      <section>
        <div className="container-fluid h-100">
          <TutorialStepOne
            currentStep={currentStep}
            nextStep={() => nextStep()}
          />
          <TutorialStepTwo
            currentStep={currentStep}
            nextStep={() => nextStep()}
          />
          <TutorialStepThree
            currentStep={currentStep}
            nextStep={() => nextStep()}
          />
        </div>
      </section>
    </Fragment>
  );
};

export default TutorialPage;
