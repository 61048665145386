import React from 'react';
import { toast } from 'react-toastify';
import { MdError } from 'react-icons/md';

const success = (message) => {
  toast.success(
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <img
        src="/assets/images/ic_success.svg"
        alt="Success"
        className="mx-2"
      />
      <div style={{
        fontFamily: 'Poppins',
      }}
      >
        {!message ? 'Sucesso' : message}
      </div>
    </div>,
  );
};
const error = (message) => {
  toast.error(
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <MdError style={{
        marginLeft: '15px',
        marginRight: '15px',
        fill: '#fff',
        fontSize: '30px',
      }}
      />
      <div style={{
        fontFamily: 'Poppins',
      }}
      >
        {!message ? 'Ocorreu um erro inesperado. Tente novamente.' : message}
      </div>
    </div>,
  );
};

const message = (title, message) => {
  toast(
    <div>
      <div style={{
        fontFamily: 'Poppins',
        fontWeight: 600,
      }}
      >
        {!title ? 'Nova mensagem' : title}
      </div>
      <div style={{
        fontFamily: 'Poppins',
      }}
      >
        {!message ? 'Nova mensagem.' : message}
      </div>
    </div>,
  );
};

export default { success, error, message };
