/* eslint-disable import/no-mutable-exports */
import firebase from 'firebase';
import FirebaseConfig from './firebase.config';

const initializeFirebase = firebase.initializeApp(FirebaseConfig);
let messaging = null;

if (firebase.messaging.isSupported()) {
  messaging = initializeFirebase.messaging();
  messaging
    .usePublicVapidKey('BD8WcEkrfV2HiJ_nFnNg_aoFsZfsAZjBdLvEpj6aPXB6Nfo2UHjGJrdq5rdL2mTV6UwCQJTaHxR4FN6B2DXfdDE');
}

export default messaging;
