import imageCompression from 'browser-image-compression';

export function handleImageCompress(image) {

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1024,
    useWebWorker: true
  }

  return imageCompression(image, options)
    .then(function (compressedFile) {
      return imageCompression.getDataUrlFromFile(compressedFile)
      .then(function(toBase64) {
        return toBase64;
      })
    })
    .catch(function (error) {
      console.log(error.message);
    });
}

export function convertToBase64(image) {
  return imageCompression.getDataUrlFromFile(image)
  .then(function(toBase64) {
    return toBase64;
  })
}
