import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { IoMdShare, IoIosTrash, IoIosCreate } from 'react-icons/io';
import ArticleDeleteModal from './ArticleDeleteModal';

const ArticleActions = ({
  id,
  slug,
  onDelete
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = () => {
    setIsOpen(false)
    onDelete(id)
  }

  return (
    <div className="article__actions">
      <button className="article__actions__button">
        <a
          href={`https://elopag.com.br/artigo/${slug}`}
          target="blank"
        >
          <IoMdShare />
        </a>
      </button>
      <button
        type="button"
        className="article__actions__button"
      >
        <a href={`${I18n.t('routes.article.url')}/${id}`}>
          <IoIosCreate />
        </a>
      </button>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className="article__actions__button"
      >
        <IoIosTrash />
      </button>

      <ArticleDeleteModal
        isOpen={isOpen}
        onOverlayClick={() => setIsOpen(false)}
        onConfirmClick={() => handleDelete()}
        slug={slug}
      />
    </div>
  );
}

export default ArticleActions;
