import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const CREATE_ADMIN = gql`
  mutation CreateAdmin(
    $name: String!,
    $email: String!,
    $password: String!,
    $phone: String!,
    $document: String!,
    $refCustomer: String
  ) {
    createAdmin(
      data: {
        name: $name
        email: $email
        password: $password
        phone: $phone
        document: $document
        refCustomer: $refCustomer
      }
    ) {
      _id,
      name,
      email,
      user{
        _id
      }
    }
  }
`;

const LOGIN_ADMIN = gql`
  mutation LoginAdmin(
    $email: String!,
    $password: String!,
  ){
    loginAdmin(
      data: {
        email: $email
        password: $password,
      }
    ){
      token
      user {
        _id
        name
        email
      }
    }
  }
`;

const EMAIL_VALIDATION = gql`
    mutation EmailConfirmation(
        $token: String!,
    ) {
        emailConfirmation(
            data: {
                token: $token
            }
        ){
          token,
          user {
            _id
            name
            email
            document
          }
        }
    }
`;

const FORGOT_PASSWORD = gql`
  mutation FogotPassword($email:String!){
    forgotPasswordAdmin(data: {
      email: $email,
    })
  }
`;

const VALIDATE_PASSWORD_TOKEN = gql`
  mutation ValidateToken($token:String!){
    validatePasswordTokenAdmin(data: {
      token:$token,
    }){
      token
      user{
        _id
        name
        email
      }
    }
  }
`;

const SAVE_FCM_TOKEN = gql`
  mutation SaveAdminFcmToken($type:String!,$token:String!){
    saveAdminFcmToken(data: {
      type: $type,
      token: $token,
    })
  }
`;

async function createUser(data) {
  try {
    const { data: { createAdmin } } = await graphqlApi().mutate({
      mutation: CREATE_ADMIN,
      variables: {
        name: data.name,
        email: data.email,
        password: data.password,
        phone: data.phone,
        document: data.document,
        refCustomer: data.refCustomer,
      },
    });

    return createAdmin;
  } catch (e) {
    const code = e.graphQLErrors.length > 0 ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      11000: 'Ops! já existe uma conta cadastrada com esse documento e/ou e-mail.',
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function loginAdmin(email, password) {
  try {
    const request = await graphqlApi().mutate({
      mutation: LOGIN_ADMIN,
      variables: {
        email,
        password,
      },
    });
    return request.data.loginAdmin;
  } catch (err) {
    const code = err.graphQLErrors.length > 0 ? err.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      WRONG_PASSWORD: 'Senha incorreta.',
      USER_NOT_FOUND: 'E-mail não cadastrado.',
      INACTIVE_ACCOUNT: `Ops, parece que sua conta ainda não foi ativada.
       Por favor verifique seu e-mail para continuar!`,
      INACTIVE_ACCOUNT_RESEND: `Ops, parece que sua conta ainda não foi ativada.
       Reenviamos um e-mail de validação!`,
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function validateEmail(token) {
  try {
    const { data: emailConfirmation } = await graphqlApi().mutate({
      mutation: EMAIL_VALIDATION,
      variables: {
        token,
      },
    });
    return emailConfirmation;
  } catch (err) {
    const { code } = err.graphQLErrors[0] || 'DEFAULT';
    const erros = {
      USER_NOT_FOUND: 'Usuário não encontrado!',
      TOKEN_IS_NOT_VALID: 'Token Inválido!',
      token_expired: `Parece que o link de ativação que você está usando expirou!
       Reenviamos um e-mail de validação!`,
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function forgotPassword(email) {
  try {
    await graphqlApi().mutate({
      mutation: FORGOT_PASSWORD,
      variables: {
        email,
      },
    });
  } catch (e) {
    const code = e.graphQLErrors && e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      USER_NOT_FOUND: 'Não encontramos nenhuma conta cadastrada com este email.',
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function validatePasswordToken(token) {
  try {
    const { data: { validatePasswordTokenAdmin } } = await graphqlApi().mutate({
      mutation: VALIDATE_PASSWORD_TOKEN,
      variables: {
        token,
      },
    });

    return validatePasswordTokenAdmin;
  } catch (e) {
    const code = e.graphQLErrors && e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      USER_NOT_FOUND: 'Não encontramos nenhuma conta cadastrada com este email.',
      INVALID_TOKEN: 'Ops! Este link não é valido.',
      EXPIRED_TOKEN: 'Ops! Este link expirou, enviamos outro email com um link novo.',
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function saveFcmToken(token) {
  try {
    await graphqlApi().mutate({
      mutation: SAVE_FCM_TOKEN,
      variables: {
        type: 'Web',
        token,
      },
    });
  } catch (e) {
    const code = e.graphQLErrors && e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

export default {
  createUser,
  loginAdmin,
  validateEmail,
  forgotPassword,
  validatePasswordToken,
  saveFcmToken,
};
