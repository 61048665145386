import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from 'antd';
import WarningMessage from '../../components/shared/WarningMessage';
import NotificationItem from './notificationItem';
import { getNotifications, readNotifications } from '../../app/redux/actions/user';
import { getNotificationsSelector } from '../../app/redux/reducers/user';
import Preferences from '../../data/preferences';
import Header from '../../components/header/Header';
import './styles.scss';

export default function Promotions() {
  const dispatch = useDispatch();
  const notifications = useSelector(state => getNotificationsSelector(state));

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getNotifications(0, Preferences.notificationsOffset));
    dispatch(readNotifications());
  }, []);

  const getMoreNotifications = (pageNumber, pageSize) => {
    const skip = pageNumber === 1 ? 0 : ((pageNumber * pageSize) - pageSize);
    dispatch(getNotifications(skip, pageSize));
    setPage(pageNumber);
  };

  const renderNotificationList = () => (
    <>
      {notifications.list.map(item => (
        <NotificationItem
          description={item.description}
          date={item.createdAt}
          key={item._id}
        />
      ))}

      <Pagination
        defaultCurrent={page}
        pageSize={Preferences.notificationsOffset}
        total={notifications.count}
        onChange={getMoreNotifications}
      />
    </>
  );

  return (
    <div className="page-container container-fluid">
      <Header
        title="Suas Notificações"
        subTitle="Exibindo suas notificações"
      />
      <div className="mt-3 w-100">
        {notifications && notifications.list && notifications.count > 0 && renderNotificationList()}
        {(!notifications || notifications.count === 0) && (
          <WarningMessage
            message="Você não possui notificações"
          />
        )}
      </div>
    </div>
  );
}
