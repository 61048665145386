import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import Preferences from '../../data/preferences'
import { getUser } from '../../app/redux/reducers/user';
import ArticleApi from '../../app/api/article';
import toast from '../../helpers/toast';

const useArticle = () => {
  const [article, setArticle] = useState();
  const [articles, setArticles] = useState([]);
  const [take] = useState(Preferences.ArticleTake);
  const [skip, setSkip] = useState(0);
  const [oldArticlesLength, setOldArticlesLength] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const { user: { companies } } = useSelector(state => getUser(state));

  const getArticle = async (id) => {
    const data = await ArticleApi.getArticle(id);
    setArticle(data?.article)
  }

  const getArticles = async () => {
    return await ArticleApi.getArticles(companies[0]._id, take, skip);
  }

  const saveArticle = async (data) => {
    try {
      return await ArticleApi.createArticle(data, companies[0]._id);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const updateArticle = async (data) => {
    try {
      await ArticleApi.updateArticle(data);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const deleteArticle = async (articleId) => {
    try {
      await ArticleApi.deleteArticle(articleId);
      toast.success('Artigo foi deletado com sucesso');
      const list = await getArticles();
      setArticles(list);
    } catch (error) {
      toast.error(error.message);
    }
  }

  const fetchMore = async () => {
    setOldArticlesLength(articles.length);
    const newList = await getArticles();
    setArticles([...articles, ...newList]);
    setSkip(skip + take);
  }

  useEffect(() => {
    async function init() {
      const list = await getArticles();
      setArticles(list);
      setSkip(take);
    }
    init()
  }, []);

  useEffect(() => {
    const diffArticlesLength = articles.length - oldArticlesLength;
    setHasMore((diffArticlesLength) % 10 === 0 && diffArticlesLength > 0)
  }, [articles]);

  return { article, articles, hasMore, fetchMore, getArticle, getArticles, saveArticle, updateArticle, deleteArticle,  }
}

export default useArticle
