import React from 'react';
import { Link } from '@reach/router';

import './styles.scss';

const InstitutionalHeader = () => (
  <nav className="navbar navbar-expand-md navbar-light header pl-3">
    <Link
      className="d-flex justify-content-start link-logo pl-4"
      to="/dashboard"
    >
      <img
        src="/assets/images/logo.svg"
        alt="Logo EloPag"
        width="68"
        height="68"
      />
    </Link>
    <button
      className="navbar-toggler custom-toggler mr-5"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <img
        src="/assets/images/header/ic_menu.svg"
        alt="Menu Icon"
        className="menu-toggle-icon"
      />
    </button>

    <div
      className="collapse navbar-collapse"
      id="navbarSupportedContent"
    >
      {/* <ul className="navbar-nav ml-auto mr-5 pl-2">
        <li className="nav-item">
          <Link
            className="nav-link"
            to="#!"
          >
            Quem somos
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to="#!"
          >
            Empresas
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to="#!"
          >
            Criar conta
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to="#!"
          >
            Contato
          </Link>
        </li>
      </ul> */}


    </div>
  </nav>
);

export default InstitutionalHeader;
