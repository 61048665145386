import React from 'react';
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';

import './styles.scss';

const customStyles = {
  overlay: {
    background: 'rgb(0,0,0,0.85)',
  },
};

Modal.setAppElement('#root');

const ArticleSuggestMonetizeModal = ({
  isOpen,
  onOverlayClick,
  onConfirmClick,
  slug,
}) => (
  <Modal
    isOpen={isOpen}
    style={customStyles}
    shouldCloseOnOverlayClick
    onRequestClose={onOverlayClick}
    overlayClassName="modal"
    className="modal-delete-promotion__container"
  >
    <div className="row w-100 ml-0">
      <div className="col-12">
        <button
          className="modal-delete-promotion__close"
          type="button"
          onClick={onOverlayClick}
        >
          <MdClose className="modal-delete-promotion__close__icon" />
        </button>
        <div className="modal-delete-promotion__text-container px-4 py-4">
          <p className="modal-delete-promotion__title">
            Você deseja monetizar o artigo?
          </p>
          <p className="modal-delete-promotion__description">
            {`Caso aceite você será redirecionado para criar uma nova publicação!`}
          </p>
          <div className="modal-delete-promotion__container-button">
            <button
              type="button"
              onClick={() => onOverlayClick()}
              className="modal-delete-promotion__container-button__button--disable"
            >
              <p className="modal-delete-promotion__container-button__button__text">
                CANCELAR
              </p>
            </button>
            <button
              type="button"
              onClick={() => onConfirmClick()}
              className="modal-delete-promotion__container-button__button"
            >
              <p className="modal-delete-promotion__container-button__button__text">
                CONFIRMAR
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

export default ArticleSuggestMonetizeModal;
