import AuthRequests from '../../api/auth';
import AuthService from '../../services/auth';
import StorageService from '../../services/storage';

import {
  addLoading,
  removeLoading,
} from './loading';
import * as UserActions from './user';

import { graphqlInit } from '../../services/graphql-api'

export const ACTION_AUTH_LOGIN = 'LOGIN';
export const ACTION_AUTH_LOGOUT = 'LOGOUT';

export const resetAuthentication = () => ({
  type: ACTION_AUTH_LOGOUT,
});

export const saveAuthentication = authData => ({
  type: ACTION_AUTH_LOGIN,
  auth: authData,
});

export const cleanAuth = () => async (dispatch) => {
  dispatch(resetAuthentication());
  dispatch(UserActions.cleanUser());
  AuthService.reset();
};

export const authenticate = (
  email,
  password,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const tokenFcm = await StorageService.get('@elopag_business_token_fcm');
    const { token, user } = await AuthRequests.loginAdmin(
      email,
      password,
    );
    AuthService.create({ token, id: user._id });
    graphqlInit();
    await dispatch(saveAuthentication(token));
    await dispatch(UserActions.getUser());
    await dispatch(UserActions.getCreditCard());
    if (tokenFcm) await AuthRequests.saveFcmToken(tokenFcm);
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const register = data => async (dispatch) => {
  dispatch(addLoading());
  try {
    await AuthRequests.createUser(data);
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const validateEmail = _token => async (dispatch) => {
  dispatch(addLoading());
  try {
    const { emailConfirmation } = await AuthRequests.validateEmail(_token);
    const { token, user } = emailConfirmation;
    await AuthService.create({ token, id: user._id });
    await dispatch(saveAuthentication(token));
    await dispatch(UserActions.saveUser(user));
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const forgotPassword = email => async (dispatch) => {
  try {
    dispatch(addLoading());
    await AuthRequests.forgotPassword(email);
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const validatePasswordToken = _token => async (dispatch) => {
  try {
    dispatch(addLoading());
    const { token, user } = await AuthRequests.validatePasswordToken(_token);
    AuthService.create({ token, id: user._id });
    await dispatch(saveAuthentication(token));
    await dispatch(UserActions.saveUser(user));
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const logout = () => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanAuth());
  dispatch(removeLoading());
};
