import moment from 'moment';
import {
  addLoading,
  removeLoading,
} from './loading';
import * as AuthActions from './auth';
import SaleRequest from '../../api/sale';
import AdminRequest from '../../api/admin';

export const ACTION_GET_SALES = 'GET_SALES';
export const ACTION_GET_SALE = 'GET_SALE';

export const saveSales = sales => ({
  type: ACTION_GET_SALES,
  sales,
});

export const saveSelectedSale = selectedSale => ({
  type: ACTION_GET_SALE,
  selectedSale,
});

export const getSale = saleId => async (dispatch) => {
  try {
    dispatch(addLoading());
    const selectedSale = await SaleRequest.getSale(saleId);
    dispatch(saveSelectedSale(selectedSale));
    dispatch(removeLoading());
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getSales = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const user = await AdminRequest.getAdmin();
    const sales = await SaleRequest.getSales(user.companies[0]._id);
    await dispatch(saveSales(sales));
  } catch (err) {
    dispatch(AuthActions.cleanAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const addSale = data => async (dispatch) => {
  dispatch(addLoading());
  try {
    const user = await AdminRequest.getAdmin();
    await SaleRequest.addSales(data, user.companies[0]._id);
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const updateSale = (data, saleId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const variables = {
      _id: saleId,
      description: data.description,
      article: data.article,
    };
    if (data.base64Logo) {
      variables.images = [data.base64Logo];
    }
    await SaleRequest.updateSales(variables);
  } catch (err) {
    throw new Error(err);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteSale = saleId => async (dispatch) => {
  dispatch(addLoading());
  try {
    await SaleRequest.removeSale(saleId);
    await dispatch(getSales());
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};
