/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import toast from '../../helpers/toast';
import validate from '../../helpers/form-validator';
import Header from '../../components/header/Header';
import useArticle from './useArticle'
import ArticleSuggestMonetizeModal from './ArticleSuggestMonetizeModal';
import './styles.scss';

export default function ArticleForm() {
  const { id } = useParams();
  const { article, getArticle, saveArticle, updateArticle } = useArticle();
  const [isEditing] = useState(id !== 'new');
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    body: '',
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [createdArticle, setCreatedArticle] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const onFormDataChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSave = async () => {
    try {
      const isFormValid = validate.validateArticle(formData);
      if (isFormValid.error) {
        toast.error(isFormValid.errorMessage);
      } else {
        const res = await saveArticle(formData)
        toast.success('Artigo foi criado com sucesso');
        setIsOpen(true)
        setCreatedArticle(res)
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUpdate = async () => {
    try {
      const isFormValid = validate.validateArticle(formData);
      if (isFormValid.error) {
        toast.error(isFormValid.errorMessage);
      } else {
        await updateArticle(formData)
        toast.success('Artigo foi alterado com sucesso');
        navigate(I18n.t('routes.article.url'));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEditorStateChange = (state) => {
    setEditorState(state)
    onFormDataChange(draftToHtml(convertToRaw(state.getCurrentContent())), 'body')
  }

  const handleOverlayClickModal = () => {
    setIsOpen(false)
    navigate(I18n.t('routes.article.url'));
  }

  const handleConfirmClickModal = () => {
    navigate(I18n.t('routes.postNew.url'), {
      state: {
        article: createdArticle
      }
    });
  }

  useEffect(() => {
    if (isEditing) {
      getArticle(id)
    }
  }, [id]);

  useEffect(() => {
    if (article) {
      setFormData({
        _id: article._id,
        title: article.title,
        subtitle: article.subtitle,
        body: article.body,
      })
      const contentBlock = htmlToDraft(article.body);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState)
    }
  }, [article]);

  return (
    <div className="page-container container article-form">
      <Header
        title={isEditing ? 'Editar Artigo' : 'Adicionar Artigo'}
        subTitle={isEditing ? 'Altere os dados abaixo' : 'Preencha os dados abaixo'}
      />

      <div className="article-form__container">
        <div className="article-form__container__form">
          <div className="article-form__container__form__field">
            <span className="article-form__container__form__field__label">Título</span>
            <input
              className="article-form__container__form__field__input"
              type="text"
              placeholder="Título"
              maxLength="150"
              required
              value={formData.title}
              onChange={e => onFormDataChange(e.target.value, 'title')}
            />
          </div>
          <div className="article-form__container__form__field">
            <span className="article-form__container__form__field__label">Sub Título</span>
            <input
              className="article-form__container__form__field__input"
              type="text"
              placeholder="Sub Título"
              maxLength="150"
              required
              value={formData.subtitle}
              onChange={e => onFormDataChange(e.target.value, 'subtitle')}
            />
          </div>
          <div className="article-form__container__form__field">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={handleEditorStateChange}
            />
          </div>
        </div>
      </div>

      <div className="article-form__container">
        <div className="article-form__container__form">
          <button
            type="button"
            className="btn-primary article-form__container__form__button"
            onClick={() => {
              if (isEditing) handleUpdate();
              else handleSave();
            }}
          >
            Publicar
          </button>
        </div>
      </div>

      <ArticleSuggestMonetizeModal
        isOpen={isOpen}
        onOverlayClick={handleOverlayClickModal}
        onConfirmClick={handleConfirmClickModal}
      />
    </div>
  );
}
