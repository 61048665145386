import React, { useState } from 'react';
import { Link, navigate, useParams } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import InputMask from 'react-input-mask';
import { FiEyeOff, FiEye } from 'react-icons/fi';

import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

import { register } from '../../app/redux/actions/auth';

import toast from '../../helpers/toast';
import validator from '../../helpers/form-validator';
import formatters from '../../helpers/formatters'
import { adjustPhone, maskBuilderPhone } from '../../helpers/inputMask';
import FormHeader from '../shared/form/FormHeader';
import FormFooter from '../shared/form/FormFooter';
import EmailValidationModal from './EmailValidationModal';
import { LoadingSelectors } from '../../app/redux/reducers';

const RegisterForm = () => {
  const { refCustomer } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(state => LoadingSelectors.getLoading(state) > 0);

  const [formData, setFormdata] = useState({
    name: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    document: '',
    phone: '',
    agree: false,
    refCustomer,
  });

  const [hiddenPassword, setHiddenPassword] = useState({
    eyePassword: true,
    eyeConfirmPassword: true,
  });

  const [showModal, setShowModal] = useState(false);

  const onFormDataChange = (value, field) => {
    setFormdata({
      ...formData,
      [field]: value,
    });
  };

  const onHiddenPasswordChange = (value, field) => {
    setHiddenPassword({
      ...hiddenPassword,
      [field]: value,
    });
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = validator.validateRegisterForm(formData);
    if (isFormValid.error) {
      toast.error(isFormValid.errorMessage);
    } else {
      try {
        await dispatch(register(formData));
        toast.success('Sucesso!');
        setShowModal(true);
        //   onSubmitSucess();
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  return (
    <section className="form-wrapper__section">
      <FormHeader />
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 col-lg-6 form-wrapper__container">
            <form
              onSubmit={e => onFormSubmit(e)}
            >
              <div className="form-wrapper__container__form justify-content-center">
                <span className="form-wrapper__container__form__legend">Nome do responsável</span>
                <input
                  type="text"
                  placeholder="Digite seu nome"
                  className="form-wrapper__container__form__input"
                  required
                  value={formData.name}
                  maxLength={40}
                  onChange={e => onFormDataChange(e.target.value, 'name')}
                />

                <span className="form-wrapper__container__form__legend">CPF do responsável</span>
                <InputMask
                  mask="999.999.999-99"
                  placeholder="Digite CPF"
                  className="form-wrapper__container__form__input"
                  required
                  value={formData.document}
                  onChange={e => onFormDataChange(e.target.value, 'document')}
                />

                <span className="form-wrapper__container__form__legend">E-mail profissional</span>
                <input
                  type="text"
                  placeholder="Digite seu e-mail"
                  className="form-wrapper__container__form__input"
                  required
                  value={formData.email}
                  maxLength={254}
                  onChange={e => onFormDataChange(formatters.email(e.target.value), 'email')}
                />

                <span className="form-wrapper__container__form__legend">Confirmação de e-mail</span>
                <input
                  type="text"
                  placeholder="Digite novamente seu e-mail"
                  className="form-wrapper__container__form__input"
                  required
                  value={formData.confirmEmail}
                  maxLength={254}
                  onChange={e => onFormDataChange(formatters.email(e.target.value), 'confirmEmail')}
                />

                <span className="form-wrapper__container__form__legend">Contato principal </span>
                <InputMask
                  mask={maskBuilderPhone(formData.phone)}
                  placeholder="Digite o seu número"
                  className="form-wrapper__container__form__input"
                  required
                  value={formData.phone}
                  onChange={e => onFormDataChange(adjustPhone(e.target.value), 'phone')}
                />

                <div className="form-wrapper__container__form__password">
                  <span className="form-wrapper__container__form__legend">Senha </span>
                  <input
                    placeholder="Digite sua senha"
                    type={hiddenPassword.eyePassword ? 'password' : 'text'}
                    className="register-form__font register-form__password-div__input"
                    required
                    value={formData.password}
                    maxLength={128}
                    onChange={e => onFormDataChange(e.target.value, 'password')}
                  />
                  {hiddenPassword.eyePassword ? (
                    <FiEye
                      className="form-wrapper__container__form__ic-visibility"
                      onClick={() => onHiddenPasswordChange(!hiddenPassword.eyePassword, 'eyePassword')}
                    />

                  )
                    : (
                      <FiEyeOff
                        className="form-wrapper__container__form__ic-visibility"
                        onClick={() => onHiddenPasswordChange(!hiddenPassword.eyePassword, 'eyePassword')}
                      />
                    )}
                </div>

                <div className="form-wrapper__container__form__password">
                  <span className="form-wrapper__container__form__legend">Confirmação da senha  </span>
                  <input
                    placeholder="Digite sua senha novamente"
                    type={hiddenPassword.eyeConfirmPassword ? 'password' : 'text'}
                    className="register-form__font register-form__password-div__input"
                    required
                    value={formData.confirmPassword}
                    maxLength={128}
                    onChange={e => onFormDataChange(e.target.value, 'confirmPassword')}
                  />
                  {hiddenPassword.eyeConfirmPassword ? (
                    <FiEye
                      className="form-wrapper__container__form__ic-visibility"
                      onClick={() => onHiddenPasswordChange(!hiddenPassword.eyeConfirmPassword, 'eyeConfirmPassword')}
                    />

                  )
                    : (
                      <FiEyeOff
                        className="form-wrapper__container__form__ic-visibility"
                        onClick={() => onHiddenPasswordChange(!hiddenPassword.eyeConfirmPassword,
                          'eyeConfirmPassword')}
                      />
                    )}
                </div>

                <div className="register-form__warning">
                  <input
                    type="checkbox"
                    value={formData.agree}
                    onChange={e => onFormDataChange(e.target.checked, 'agree')}
                    className="register-form__warning__input"
                  />
                  <p className="register-form_font register-form__warning__text">
                    Você declara que leu e concorda com os&nbsp;
                    <a
                      className="register-form__warning__text--underlined"
                      href="https://elopag.com.br/docs/termo-de-uso.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Termos de uso
                    </a> e&nbsp;
                    <a
                      className="register-form__warning__text--underlined"
                      href="https://elopag.com.br/docs/politica-privacidade.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Política de Privacidade
                    </a> da plataforma.
                  </p>
                </div>

                <Link
                  to={I18n.t('routes.login.url')}
                  className="register-form__font
                      register-form__login-link mt-0 mb-2"
                >
                  Já tenho uma conta
                </Link>
              </div>

              <FormFooter
                loading={loading}
                onFormSubmit={e => onFormSubmit(e)}
                buttonTitle="AVANÇAR"
              />

            </form>

          </div>
        </div>
      </div>
      <EmailValidationModal
        isOpen={showModal}
        onOverlayClick={() => {
          setShowModal(false);
          navigate(I18n.t('routes.login.url'));
        }}
      />
    </section>
  );
};

export default RegisterForm;
