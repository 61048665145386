import React, { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { navigate } from '@reach/router';

import { getCreditCard } from '../../app/redux/reducers/user';
import toast from '../../helpers/toast';

import OccupationArea from '../../components/register/OccupationArea';
import CompanyRegister from '../../components/register/CompanyRegister';
import AddCredits from '../../components/add-credits';
import AddCreditCard from './add-credit-card';
import CheckoutPage from '../../components/checkout';
import './styles.scss';

const RegisterContinuation = () => {
  const responseCreditCard = useSelector(state => getCreditCard(state));
  const [creditCards, setCreditCard] = useState([]);
  const [formStep, setFormStep] = useState(1);
  const [purchaseMoney, setPurchaseMoney] = useState(0.00);
  const [numberMessages, setNumberMessages] = useState('0');

  useEffect(() => {
    setCreditCard(responseCreditCard);
  }, [responseCreditCard]);

  const handleAddCreditsStep = (value, numberOfMessages) => {
    if (value >= 1.00) {
      setPurchaseMoney(value);
      setNumberMessages(numberOfMessages);
      if (creditCards && creditCards.length > 0) {
        setFormStep(formStep + 2);
      } else {
        setFormStep(formStep + 1);
      }
    } else {
      toast.error('O valor deve ser de no mínimo R$ 1,00');
    }
  };

  return (
    <>
      <section className="register-continuation">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <span className={`register-continuation__step-bar register-continuation__step-bar--${formStep}`} />
              <p className="register-continuation__building-register-text">Construindo seu cadastro com a EloPag
                <span className="register-continuation__steps-text">{formStep} de 5 etapas</span>
              </p>
            </div>
          </div>

          {formStep === 1 && (
            <OccupationArea handleNextStep={() => setFormStep(formStep + 1)} />
          )}
          {formStep === 2 && (
            <CompanyRegister
              handleNextStep={() => setFormStep(formStep + 1)}
              handleBackStep={() => setFormStep(formStep - 1)}
            />
          )}
          {formStep === 3 && (
            <Fragment>
              <div className="row">
                <div className="col-12">
                  <h1 className="register-continuation__title">Adicionar créditos</h1>
                </div>
              </div>
              <AddCredits
                creditCards={creditCards}
                handleNextStep={(money, numberOfMessage) => handleAddCreditsStep(money, numberOfMessage)}
                handleCancel={() => navigate(I18n.t('routes.tutorial.url'))}
              />
            </Fragment>
          )}
          {formStep === 4 && (
            <AddCreditCard
              handleBackStep={() => setFormStep(formStep - 1)}
              handleNextStep={() => setFormStep(formStep + 1)}
            />
          )}
          {formStep === 5 && (
            <Fragment>
              <div className="row">
                <div className="col-12">
                  <h1 className="register-continuation__title">Adicionar créditos</h1>
                </div>
              </div>
              <CheckoutPage
                creditsValue={purchaseMoney}
                creditCards={creditCards}
                messageNumber={numberMessages}
                onConfirmClick={() => navigate(I18n.t('routes.tutorial.url'))}
                onBackClick={() => setFormStep(formStep - 2)}
              />
              <input
                type="hidden"
                id="antifraudInput"
                value=""
              />
            </Fragment>
          )}
        </div>
      </section>
    </>
  );
};

export default RegisterContinuation;
