import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const GET_SALE = gql`
  query GetSale($id: ID) {
    sale(id: $id) {
      _id
      createdAt
      description
      images {
        url
      }
      article {
        _id
        title
      }
    }
  }
`;

const GET_SALES = gql`
  query Sales(
    $sort: [Sort],
    $filter: [Filter],
    $take: Int,
    $skip: Int
  ) {
    sales(
      sort: $sort,
      filter: $filter,
      take: $take,
      skip: $skip
    ) {
        _id
        createdAt
        description
        images {
          url
        }
        article {
          _id
          title
        }
      }
    }
`;

const ADD_SALES = gql`
mutation CreateSale(
  $company: ID!,
  $description: String!,
  $images: [String]!,
  $article: ID,
) {
  createSale(
    data: {
      company: $company
      description: $description,
      images: $images
      article: $article
    }
  ) {
    _id
  }
}
`;

const UPDATE_SALE = gql`
mutation UpdateSale(
  $_id: ID!,
  $description: String!,
  $images: [String],
  $article: ID,
) {
  updateSale(
    data: {
      _id: $_id
      description: $description,
      images: $images,
      article: $article
    }
  ) {
    _id
  }
}
`;

const DELETE_SALE = gql`
mutation DeleteSale($_id: ID!) {
  deleteSale(_id: $_id)
}
`;

async function getSales(companyId, skip, take) {
  try {
    const filter = [{ field: 'company', value: companyId }];
    const sort = [{ field: 'createdAt', dir: 'desc' }];
    const { data: { sales } } = await graphqlApi().query({
      query: GET_SALES,
      variables: {
        sort,
        filter,
        skip,
        take,
      },
    });
    return sales;
  } catch (e) {
    return e;
  }
}

async function getSale(saleId) {
  try {
    const { data: sale } = await graphqlApi().query({
      query: GET_SALE,
      variables: {
        id: saleId,
      },
    });
    return sale;
  } catch (err) {
    throw new Error('Não encontramos essa promoção');
  }
}

async function addSales(data, companyId) {
  try {
    const { data: { createSale } } = await graphqlApi().mutate({
      mutation: ADD_SALES,
      variables: {
        company: companyId,
        description: data.description,
        images: [data.base64Logo],
        article: data.article,
      },
    });
    return createSale;
  } catch (e) {
    const code = e.graphQLErrors && e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      INSUFFICIENT_FUNDS: 'Você não tem fundos suficientes para fazer essa ação!. Minimo pra essa ação R$ 10,00',
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function updateSales(data) {
  try {
    console.log(data)
    const { data: { updateSale } } = await graphqlApi().mutate({
      mutation: UPDATE_SALE,
      variables: data,
    });
    return updateSale;
  } catch (err) {
    const code = err.graphQLErrors.length > 0 ? err.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function removeSale(saleId) {
  try {
    const { data: { deleteSale } } = await graphqlApi().mutate({
      mutation: DELETE_SALE,
      variables: {
        _id: saleId,
      },
    });
    return deleteSale;
  } catch (err) {
    const code = err.graphQLErrors.length > 0 ? err.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

export default {
  getSale,
  getSales,
  addSales,
  updateSales,
  removeSale,
};
