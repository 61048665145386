import React from 'react';
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';

import './styles.scss';

const customStyles = {
  overlay: {
    background: 'rgb(0,0,0,0.85)',
  },
};

Modal.setAppElement('#root');

const ChatTipModal = ({
  isOpen,
  onOverlayClick,
  onConfirmClick,
  tipValue,
}) => (
  <Modal
    isOpen={isOpen}
    style={customStyles}
    shouldCloseOnOverlayClick
    onRequestClose={onOverlayClick}
    overlayClassName="modal"
    className="modal-delete-promotion__container"
  >
    <div className="row w-100 ml-0">
      <div className="col-12">
        <button
          className="modal-delete-promotion__close"
          type="button"
          onClick={onOverlayClick}
        >
          <MdClose className="modal-delete-promotion__close__icon" />
        </button>
        <div className="modal-delete-promotion__text-container px-4 py-4">
          <p className="modal-delete-promotion__title">
              Você tem certeza?
          </p>
          <p className="modal-delete-promotion__description">
            {`Você deseja fidelizar este cliente com R$${tipValue},00 de ${tipValue === 1 ? 'crédito' : 'créditos'}?`}
            <span className="modal-delete-promotion__description modal-delete-promotion__description--bold">
                Este valor será debitado dos seus créditos!
            </span>
          </p>
          <div className="modal-delete-promotion__container-button">
            <button
              type="button"
              onClick={onOverlayClick}
              className="modal-delete-promotion__container-button__button--disable"
            >
              <p className="modal-delete-promotion__container-button__button__text">
                  CANCELAR
              </p>
            </button>
            <button
              type="button"
              onClick={onConfirmClick}
              className="modal-delete-promotion__container-button__button"
            >
              <p className="modal-delete-promotion__container-button__button__text">
                  CONFIRMAR
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

export default ChatTipModal;
