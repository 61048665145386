import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Preferences from '../../data/preferences'
import { getUser } from '../../app/redux/reducers/user';
import CallServer from '../../app/api/call';
import preferences from '../../data/preferences';

const useCall = () => {
  const [call, setCall] = useState({});
  const [calls, setCalls] = useState([]);
  const [showCallModal, setShowCallModal] = useState(false);
  const [showCallAnsweredModal, setShowCallAnsweredModal] = useState(false);
  const [showCustomerSearchModal, setShowCustomerSearchModal] = useState(false);
  const [take] = useState(Preferences.callTake);
  const [skip, setSkip] = useState(0);
  const [oldCallsLength, setOldCallsLength] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const { user: { companies } } = useSelector(state => getUser(state));

  const getCalls = async () => {
    return await CallServer.getCalls(companies[0]._id, take, skip);
  }

  const fetchMoreCalls = async () => {
    setOldCallsLength(calls.length);
    const newList = await getCalls();
    setCalls([...calls, ...newList]);
    setSkip(skip + take);
  }

  useEffect(() => {
    async function init() {
      const list = await getCalls();
      setCalls(list);
    }

    init()
  }, []);

  useEffect(() => {
    const diffCallsLength = calls.length - oldCallsLength;
    setHasMore((diffCallsLength) % 10 === 0 && diffCallsLength > 0)
  }, [calls]);

  useEffect(() => {
    checkWaitingChat()
    checkOpenChat()
  }, [calls]);

  const checkWaitingChat = () => {
    const lastCall = calls && calls[0];
    if (lastCall && lastCall.status == "WAITING") {
      setCall(lastCall);
      setShowCallModal(true);
    }
  }

  const checkOpenChat = () => {
    const lastCall = calls && calls[0];
    if (lastCall && lastCall.status != "WAITING" && !lastCall.closedAt ) {
      setCall(lastCall);
      setShowCallAnsweredModal(true);
    }
  }

  const handleCustomerSearchModel = (value) => {
    setShowCustomerSearchModal(value)
  }

  const handleSetShowCallmodal = (value) => {
    setShowCallModal(value)
  }

  const handleSearch = (customer) => {
    setCall({ ...call, customer });
    setShowCustomerSearchModal(false)
    setShowCallModal(true);
  }

  const onCallAnswer = (newCall) => {
    setCall(newCall);
    setShowCallModal(false);
    setShowCallAnsweredModal(true);
  }

  const onCloseCallAnswered = (newCall) => {
    if ((calls.length % preferences.callTake) == 0) {
      calls.pop()
    }

    setCall(newCall);
    calls.unshift(newCall)

    setCalls(calls);
    setShowCallAnsweredModal(false);
  }

  const handleClickAdd = () => {
    setShowCustomerSearchModal(true)
  }

  return {
    calls,
    setCalls,
    call,
    setCall,
    hasMore,
    handleClickAdd,
    checkOpenChat,
    onCloseCallAnswered,
    checkWaitingChat,
    handleSearch,
    showCustomerSearchModal,
    handleCustomerSearchModel,
    handleSetShowCallmodal,
    showCallModal,
    showCallAnsweredModal,
    onCallAnswer,
    fetchMoreCalls
  }
}

export default useCall;
