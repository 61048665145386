/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';

import { getUser } from '../../app/redux/reducers/user';
import * as UserActions from '../../app/redux/actions/user';

import toast from '../../helpers/toast';
import Validator from '../../helpers/form-validator';
import { adjustPhone, maskBuilderPhone } from '../../helpers/inputMask';
import StringHelper from '../../helpers/number';

import ArrowRight from '../../svg/arrow_right.svg';

export default function MyPlan() {
  const dispatch = useDispatch();
  const { user } = useSelector(state => getUser(state));
  const [document, setDocument] = useState('');
  const [oldData, setOldData] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const onFormDataChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  useEffect(() => {
    if (user && user.companies[0]) {
      setFormData({
        name: user.companies[0].name,
        email: user.email,
        phone: user.phone,
      });
      setOldData({
        name: user.companies[0].name,
        email: user.email,
        phone: user.phone,
      });

      setDocument(StringHelper.keepOnlyNumber(user.companies[0].cnpj));
    }
  }, [user]);

  const updateCompany = async () => {
    try {
      const isFormValid = Validator.validateChangeProfileForm(formData, oldData);
      if (isFormValid.error) {
        toast.error(isFormValid.errorMessage);
      } else {
        await dispatch(UserActions.updateUser(formData));
        toast.success('O perfil foi atualizado com sucesso');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container mt-3">
      <div className="row px-0">
        <div className="col-10 px-0">
          <div className="edit-profile__input-container">
            <div className="edit-profile__input-container__container">
              <span className="edit-profile__input-container__input__legend">Nome da empresa</span>
              <input
                type="text"
                maxLength="45"
                required
                value={formData.name}
                className="edit-profile__input-container__input"
                onChange={e => onFormDataChange(e.target.value, 'name')}
              />
            </div>
            <div className="edit-profile__input-container__container">
              <span className="edit-profile__input-container__input__legend">E-mail profissional</span>
              <input
                type="text"
                maxLength="45"
                required
                value={formData.email}
                className="edit-profile__input-container__input"
                onChange={e => onFormDataChange(e.target.value, 'email')}
              />
            </div>
          </div>
        </div>
        <div className="col-10 px-0 mt-5">
          <div className="edit-profile__input-container">
            <div className="edit-profile__input-container__container">
              <span className="edit-profile__input-container__input__legend
                edit-profile__input-container__input__legend--disabled"
              >
                {document.length > 11 ? 'CNPJ' : 'CPF'}
              </span>
              {document.length <= 11 ? (
                <InputMask
                  mask="999.999.999-99"
                  className="edit-profile__input-container__input edit-profile__input-container__input--disabled"
                  required
                  disabled
                  value={user.companies[0].cnpj}
                  onChange={e => onFormDataChange(e.target.value, 'document')}
                />
              ) : (
                <InputMask
                  mask="99.999.999/9999-99"
                  className="edit-profile__input-container__input edit-profile__input-container__input--disabled"
                  required
                  disabled
                  value={user.companies[0].cnpj}
                  onChange={e => onFormDataChange(e.target.value, 'document')}
                />
              )}
            </div>
            <div className="edit-profile__input-container__container">
              <span className="edit-profile__input-container__input__legend">Contato principal</span>
              <InputMask
                mask={maskBuilderPhone(formData.phone)}
                className="edit-profile__input-container__input"
                required
                value={formData.phone}
                onChange={e => onFormDataChange(adjustPhone(e.target.value), 'phone')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row px-0 mt-5">
        <div className="col-11 d-flex justify-content-end mt-5 px-0 postion-relative">
          <button
            type="button"
            onClick={() => updateCompany()}
            className="btn-primary"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
}
