import React, { Fragment } from 'react';
import DefaultButton from '../../../components/shared/DefaultButton';
import StorageService from '../../../app/services/storage';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';

import { ReactComponent as ImageFooter } from '../../../svg/tutorial/tutorial_footer_image.svg';

import './style.scss';

const TutorialStepOne = ({ currentStep, nextStep }) => currentStep === 1 && (
  <Fragment>
    <div className="row tutorial tutorial__responsive d-flex h-100">
      <div className="col-10 col-sm-10 col-md-10 col-xl-6 col-lg-6 offset-lg-1 offset-xl-1">
        <div className="tutorial__content-container">
          <h1 className="tutorial__title"> Conexão simples e vantajosa </h1>
          <div className="tutorial__title__underline" />
          <div className="tutorial__description-container">
            <p className="tutorial__description">
            A EloPag funciona com praticamente qualquer orçamento. Defina um limite de orçamento
            e nunca o ultrapasse. Além disso, você pode pausar ou ajustar suas conexões, assim
            você só investe no cliente certo.
            </p>
          </div>
          <DefaultButton
            name="AVANÇAR"
            onClick={() => nextStep()}
          />
          <br />
          <button
            className="skip-tutorial-btn ml-4"
            type="button"
            onClick={() => {
              StorageService.set('skipTutorial', true);
              navigate(I18n.t('routes.dashboard.url'));
            }}
          >
            Pular tutorial &gt;&gt;
          </button>
        </div>
      </div>

      <div className="col-10 col-sm-10 col-md-10 col-xl-4 col-lg-4">
        <div className="tutorial__body-image-container">
          <img
            src="/assets/images/tutorial/image_tutorial_step1.png"
            alt="Homem monetizando o seu negócio"
            className="tutorial__body-image-1"
          />
        </div>
      </div>
      <ImageFooter className="tutorial__footer-image d-none d-lg-block" />
    </div>
  </Fragment>
);

export default TutorialStepOne;
