import React, { useState } from 'react';
import moment from 'moment';
import DeletePromotionModal from '../../components/modal/DeletePromotionModal';
import PostActions from './PostActions';
import { MdAttachMoney } from 'react-icons/md';
import Show from '../../components/elements/Show'

const PostCard = ({
  id,
  logo,
  description,
  createdAt,
  article,
  deletePost,
  handleEditClick,
}) => {
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);

  const handleDeletePromotion = () => {
      deletePost(id);
      setIsOpenModalDelete(false);
  };

  return (
    <>
      <DeletePromotionModal
        isOpen={isOpenModalDelete}
        onOverlayClick={() => setIsOpenModalDelete(false)}
        onConfirmClick={() => handleDeletePromotion()}
      />
      <div className="col-xl-3 col-lg-4 col-md-4">
        <div className="post__sale-card">
          <Show if={!!article}>
            <div className="post__sale-card__monetized-chip">
              <MdAttachMoney />
              Monetizado
            </div>
          </Show>
          <div className="post__sale-card__image">
            <img src={logo} />
          </div>
          <div className="post__sale-card__content">
            <PostActions
              handleEditClick={() => handleEditClick()}
              handleDeleteClick={() => setIsOpenModalDelete(true)}
            />
            <div className="post__sale-card__created-date">
              {`${moment(parseInt(createdAt, 10)).format('DD MMM YYYY').toLowerCase()}`}
            </div>
            <div className="post__sale-card__description">
              {description}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostCard;
