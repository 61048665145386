import React from 'react';
import moment from 'moment';

const PromotionPopOver = ({
  date,
  // check,
  description,
}) => (
  <div
    // className={`notifications__item__button ${!check ? 'notifications__item__button--enable' : ''}`}
    className="notifications__item__button"
  >
    <p className="notifications__item__description">
      {description}
    </p>
    <p className="notifications__item__date">
      {moment(parseInt(date, 10)).format('dddd[ - ]DD/MM/YYYY[ - ]HH:mm')}
    </p>
  </div>
);

export default PromotionPopOver;
