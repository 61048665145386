import ChatServer from '../../api/chat';
import WalletServer from '../../api/wallet';
import { addLoading, removeLoading } from './loading';
import preferences from '../../../data/preferences';
import toast from '../../../helpers/toast';

export const ACTION_SET_CHAT_LIST = 'SET_CHAT_LIST';
export const ACTION_SET_SELECTED_CHAT = 'SET_SELECTED_CHAT';
export const ACTION_SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES';

export const setChatList = list => ({
  type: ACTION_SET_CHAT_LIST,
  payload: list,
});

export const setSelectedChat = chat => ({
  type: ACTION_SET_SELECTED_CHAT,
  payload: chat,
});

export const setChatMessages = messages => ({
  type: ACTION_SET_CHAT_MESSAGES,
  payload: messages,
});

export const sendMessage = ({ text, image, document }) => async (dispatch, getState) => {
  try {
    const { selectedChat } = getState().chat;
    await ChatServer.createChatMessage({ chat: selectedChat._id, text, image, document });
  } catch (err) {
    toast.error(err.message);
  }
};

export const getChatMessages = (load = true, skip = 0, scroll = true) =>
  async (dispatch, getState) => {
    if (load) dispatch(addLoading());
    try {
      const chatId = getState().chat.selectedChat._id;
      if (!chatId) {
        return;
      }
      const newMessages = await ChatServer.getChatMessages(chatId, skip, preferences.chatMessageTake);
      if (skip === 0) {
        await dispatch(setChatMessages(newMessages.reverse()));
      } else {
        const messagesList = getState().chat.chatMessages;
        await dispatch(setChatMessages(newMessages.reverse().concat(messagesList)));
      }

      if (scroll) {
        const chat = document.getElementById("chat-messages");
        chat.scrollTop = skip === 0 ? chat.scrollHeight : ((chat.scrollHeight * 15) / 100);
      }
    } catch (err) {
      throw new Error(err.message);
    } finally {
      if (load) dispatch(removeLoading());
    }
  };

export const chatMessageUpdatedObservable = () => async (dispatch, getState) => {
  const chatMessageUpdatedObservable = await ChatServer.chatMessageUpdatedObservable();
  return chatMessageUpdatedObservable.subscribe({
    next: async ({ data }) => {
      if (data?.chatMessageUpdated) {
        // dispatch(updateChatlist());
        dispatch(getChatMessages(false, 0, true));
      }
    }
  });
};

export const sendChatTip = value => async (dispatch, getState) => {
  try {
    dispatch(addLoading());
    const chatID = getState().chat.selectedChat._id;
    const companyId = getState().user.user.companies[0]._id;
    await WalletServer.sendTip(value, chatID, companyId);
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const createChat = (customerId) => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const companyId = getState().user.user.companies[0]._id;
    const chat = await ChatServer.createChat(companyId, customerId);
    await dispatch(setSelectedChat(chat));
  } catch (err) {
    throw new Error(err.message)
  } finally {
    dispatch(removeLoading());
  }
}
