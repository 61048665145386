import api from '../app/config/api.config';

function getBaseUrl() {
  const { hostname } = window.location;
  const { http, ws } = api;
  

  const uri = {
    http: null,
    ws: null,
  }
  switch (hostname) {
    case 'localhost':
    case 'www.localhost':
      uri.http = http.localhost;
      uri.ws = ws.localhost;
      break;
    case 'qa-empresa.elopag.com.br':
      uri.http = http.homolog;
      uri.ws = ws.homolog;
      break;
    case 'empresa.elopag.com.br':
    case 'business.elopag.com.br':
      uri.http = http.production;
      uri.ws = ws.production;
      break;
    default:
      uri.http = http.homolog;
      uri.ws = ws.homolog;
  }

  return uri;
}

export default {
  http: getBaseUrl().http,
  ws: getBaseUrl().ws,
}
