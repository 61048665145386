import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import thunk from 'redux-thunk';

import authReducer from './reducers/auth';
import userReducer from './reducers/user';
import settingsReducer from './reducers/settings';
import loadingReducer from './reducers/loading';
import chatReducer from './reducers/chat';
import callReducer from './reducers/call';
import saleReducer from './reducers/sale';
import dashboardReducer from './reducers/dashboard';

export default () => {
  const store = createStore(
    combineReducers(
      {
        i18n: i18nReducer,
        auth: authReducer,
        loading: loadingReducer,
        user: userReducer,
        settings: settingsReducer,
        chat: chatReducer,
        call: callReducer,
        sale: saleReducer,
        dashboard: dashboardReducer,
      },
    ),
    compose(
      applyMiddleware(thunk),
    ),
  );

  return store;
};
