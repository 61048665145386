import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { useSelector, useDispatch } from 'react-redux';

import { getCreditCard } from '../../app/redux/reducers/user';
import * as UserActions from '../../app/redux/actions/user';

import toast from '../../helpers/toast';
import WarningMessage from '../../components/shared/WarningMessage';
import CreditCardContainer from './card-container';


const PaymentPage = () => {
  const dispatch = useDispatch();
  const responseCreditCard = useSelector(state => getCreditCard(state));
  const [creditCards, setCreditCard] = useState([]);

  useEffect(() => {
    setCreditCard(responseCreditCard);
  }, [responseCreditCard]);

  const deleteCreditCard = async (creditCardId) => {
    try {
      await dispatch(UserActions.deletecreditCard(creditCardId));
      toast.success('O cartão foi deletado com sucesso!');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container mt-5 position-relative">
      <div className="row px-0 w-100 position-relative">
        {creditCards && creditCards.length === 0 && (
          <div className={`payment__add-credit-card
            ${creditCards.length === 0 ? 'payment__add-credit-card--full-screen' : ''}`}
          >
            <button
              type="button"
              onClick={() => navigate(I18n.t('routes.addCrediCard.url'))}
              className={`payment__add-credit-card__button
                ${creditCards.length > 0 ? 'payment__add-credit-card__button--full-screen' : ''}`}
            >
              <p className="payment__add-credit-card__plus-icon">
                +
              </p>
              <p className="payment__add-credit-card__text">
                Adicionar cartão
              </p>
            </button>
          </div>
        )}
        {creditCards && creditCards.length > 0 ? (
          <div className="row px-4 mt-5 w-100 position-relative">
            {creditCards.map(item => (
              <CreditCardContainer
                number={item.number}
                brand={item.brand}
                key={item._id}
                handleDeleteClick={() => deleteCreditCard(item._id)}
              />
            ))}
          </div>
        ) : (
          <div className="row px-4 mt-3">
            <WarningMessage
              message="Ops! Parece que você ainda não adicionou nenhum cartão ainda"
              haveButton={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentPage;
