import gql from 'graphql-tag';
import axios from 'axios';
import graphqlApi from '../services/graphql-api';

const GET_ADMIN = gql`
  query GetAdmin {
    admin {
      _id
      name
      email
      document
      phone
      companies {
        _id
        name
        fancyName
        cnpj
        sales {
          _id
          description
          images {
            url
          }
        }
        logo{
          url
        }
      }
    }
  }
`;

const UPDATE_ADMIN = gql`
  mutation UpdateAdmin($data:UpdateAdminInput!){
    updateAdmin(data:$data){
      _id
      name
      email
      document
      phone
      companies {
        _id
        name
        fancyName
        cnpj
        sales {
          _id
          description
          images {
            url
          }
        }
        logo{
          url
        }
      }
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangeAdminPassword($data:ChangePasswordInput!){
    changeAdminPassword(data:$data){
      _id
    }
  }
`;

const GET_NOTIFICATIONS = gql`
  query GetNotifications($filter:[Filter],$sort:[Sort],$take: Int,$skip:Int){
    adminNotifications(filter:$filter,sort:$sort,take:$take,skip:$skip){
      notifications{
      _id
      type
      description
      viewedAt
      createdAt
      }
      count
    }
  }
`;
const READ_NOTIFICATIONS = gql`
  mutation UpdateNotifications{
    updateNotifications
  }
`;

async function getAdmin() {
  try {
    const { data: { admin } } = await graphqlApi().mutate({
      mutation: GET_ADMIN,
    });
    return admin;
  } catch (e) {
    return e;
  }
}

async function updateAdmin(customerData) {
  try {
    const { data: { updateAdmin: updatedAdmin } } = await graphqlApi().mutate({
      mutation: UPDATE_ADMIN,
      variables: {
        data: customerData,
      },
    });
    return updatedAdmin;
  } catch (e) {
    const code = e.graphQLErrors.length > 0 ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      11000: 'Ops! já existe uma conta cadastrada com esse e-mail.',
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function changePassword(customerData) {
  try {
    const { data: { changeAdminPassword } } = await graphqlApi().mutate({
      mutation: CHANGE_PASSWORD,
      variables: {
        data: customerData,
      },
    });
    return changeAdminPassword;
  } catch (e) {
    const code = e.graphQLErrors.length > 0 ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      INCORRECT_OLD_PASSWORD: 'A senha atual está incorreta.',
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}
async function getNotifications(filter, skip, take) {
  const sort = { field: 'createdAt', dir: 'desc' };
  try {
    const { data: { adminNotifications } } = await graphqlApi().query({
      query: GET_NOTIFICATIONS,
      variables: {
        filter,
        sort: [sort],
        take,
        skip,
      },
    });
    return adminNotifications;
  } catch (e) {
    const code = e.graphQLErrors.length > 0 ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}
async function readNotifications() {
  try {
    await graphqlApi().mutate({
      mutation: READ_NOTIFICATIONS,
    });
  } catch (e) {
    const code = e.graphQLErrors.length > 0 ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function getAddressByCep(cep) {
  const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  return data;
}

export default {
  getAdmin,
  updateAdmin,
  changePassword,
  getAddressByCep,
  getNotifications,
  readNotifications,
};
