import React from 'react';

import { IoIosTrash, IoIosCreate } from 'react-icons/io';

const postPopOver = ({
  handleEditClick,
  handleDeleteClick,
}) => (
  <div className="post__sale-card__actions">
    <button
      type="button"
      onClick={handleEditClick}
      className="post__sale-card__actions__button"
    >
      <IoIosCreate />
    </button>

    <button
      type="button"
      onClick={handleDeleteClick}
      className="post__sale-card__actions__button"
    >
      <IoIosTrash />
    </button>
  </div>
);

export default postPopOver;
