import gql from 'graphql-tag';
import graphqlApi from '../services/graphql-api';

const GET_ADMIN_BALANCE = gql`
  query AdminAccountTransactionsBalance($filter: [Filter]){
    adminAccountTransactionsBalance(filter: $filter)
  }
`;

const SEND_TIP = gql`
  mutation SendCustomerBonification($chat: ID!, $company: ID!, $value: Float!){
    sendCustomerBonification(
      data: {
        chat: $chat
        company: $company
        value: $value
      }
    )
  }
`;

const ADD_CREDIT_CARD = gql`
  mutation AddPaymentCard (
    $number: String!,
    $holder: String!,
    $cvv: String!,
    $brand: String!,
    $expirationDate: String!
    $document: String!,
    $street: String!,
    $addresNumber: String!,
    $complement: String!,
    $neighborhood: String!,
    $city: String!,
    $uf: String!,
    $zipcode: String!,
  ){
    createAdminPaymentCard (
      data: {
        number: $number
        holder: $holder
        cvv: $cvv
        brand: $brand
        expirationDate: $expirationDate
        billingAddress : {
          street: $street,
          number: $addresNumber,
          complement: $complement,
          neighborhood: $neighborhood,
          city: $city,
          state: $uf,
          zipcode: $zipcode
        }
        document: $document,
      }
    ) {
      _id
      number
      brand
      billingAddress{
        street
        number
        complement
        city
        zipcode
        state
        neighborhood
      }
    }
  }
`;

const UPDATE_BILLING_ADDRESS = gql`
  mutation UpdateAdminBillingAddressPaymentCard (
    $card: ID!
    $street: String!,
    $addresNumber: String!,
    $complement: String!,
    $neighborhood: String!,
    $city: String!,
    $uf: String!,
    $zipcode: String!,
  ){
    updateAdminBillingAddressPaymentCard (
      data: {
        card: $card
        billingAddress : {
          street: $street,
          number: $addresNumber,
          complement: $complement,
          neighborhood: $neighborhood,
          city: $city,
          state: $uf,
          zipcode: $zipcode
        }
      }
    ) {
      _id
      number
      brand
      billingAddress{
        street
        number
        complement
        city
        zipcode
        state
        neighborhood
      }
    }
  }
`;

const GET_CREDIT_CARD = gql`
  query{
    adminPaymentCards{
      _id
      number
      brand
      billingAddress{
        street
        number
        complement
        city
        zipcode
        state
        neighborhood
      }
    }
  }
`;

const DELETE_CREDIT_CARD = gql`
  mutation DeleteAdminPaymentCard ($card: ID!){
    deleteAdminPaymentCard (
      card: $card,
    )
  }
`;

const CHECKOUT_PAYMENT = gql`
mutation CreateAdminPayment ($totalAmount: Float!, $cardId: ID!, $antiFraud: String){
  createAdminPayment (
    data: {
      totalAmount: $totalAmount,
      cardId: $cardId,
      antiFraud: $antiFraud,
    }
  )
}
`;

async function getAdminBalance(companyId) {
  try {
    const filter = [{ field: 'company', value: companyId }];
    const { data: { adminAccountTransactionsBalance } } = await graphqlApi().query({
      query: GET_ADMIN_BALANCE,
      variables: {
        filter,
      },
    });
    return adminAccountTransactionsBalance;
  } catch (err) {
    return err;
  }
}

async function sendTip(value, chatID, companyId) {
  try {
    const { data: { sendCustomerBonification } } = await graphqlApi().mutate({
      mutation: SEND_TIP,
      variables: {
        chat: chatID,
        company: companyId,
        value,
      },
    });
    return sendCustomerBonification;
  } catch (e) {
    const code = e.graphQLErrors && e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      INSUFFICIENT_FUNDS: 'Você não tem fundos suficientes para fazer essa ação!.',
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function addCreditCard(data) {
  try {
    const { data: { createAdminPaymentCard } } = await graphqlApi().mutate({
      mutation: ADD_CREDIT_CARD,
      variables: {
        number: data.number,
        holder: data.name,
        cvv: data.CVV,
        brand: data.brand,
        expirationDate: data.expireDate,
        document: data.document,
        street: data.address,
        addresNumber: data.addressNumber,
        neighborhood: data.neighborhood,
        complement: data.complement,
        city: data.city,
        uf: data.uf,
        zipcode: data.cep,
      },
    });
    return createAdminPaymentCard;
  } catch (e) {
    const code = e.graphQLErrors && e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      'invalid_card.expirationDate': 'A data de expiração do cartão está invalida!',
      'invalid_card.number': 'Os dados do cartão estão incorretos!',
      'invalid_card.cvv': 'Os dados do cartão estão incorretos!',
      'invalid_card.holder': 'Os dados do cartão estão incorretos!',
      card_number_invalid: 'Os dados do cartão estão incorretos!',
      PAYMENT_CARD_ALREADY_REGISTERED: 'Você já possui um cartão de crédito resgistrado!',
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function updateBillingAddress(data) {
  try {
    const { data: { updateAdminBillingAddressPaymentCard } } = await graphqlApi().mutate({
      mutation: UPDATE_BILLING_ADDRESS,
      variables: {
        card: data.card,
        street: data.address,
        addresNumber: data.addressNumber,
        neighborhood: data.neighborhood,
        complement: data.complement,
        city: data.city,
        uf: data.uf,
        zipcode: data.cep,
      },
    });
    return updateAdminBillingAddressPaymentCard;
  } catch (e) {
    const code = e.graphQLErrors && e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      PAYMENT_CARD_NOT_FOUND: 'Não encontramos esse cartão de crédito no sistema. Verifique seus dados!',
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function getCreditCard() {
  try {
    const { data: { adminPaymentCards } } = await graphqlApi().query({
      query: GET_CREDIT_CARD,
    });
    return adminPaymentCards;
  } catch (e) {
    return e;
  }
}

async function deleteCreditCard(creditCardId) {
  try {
    const { data: { deletePaymentCard } } = await graphqlApi().mutate({
      mutation: DELETE_CREDIT_CARD,
      variables: {
        card: creditCardId,
      },
    });
    return deletePaymentCard;
  } catch (e) {
    const code = e.graphQLErrors && e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      PAYMENT_CARD_NOT_FOUND: 'Esse cartão de crédito não foi encontrado no sistema!',
      DEFAULT: 'Ocorreu um erro, tente novamente em instantes.',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}

async function finalizePayment(data) {
  try {
    const { data: { createAdminPayment } } = await graphqlApi().mutate({
      mutation: CHECKOUT_PAYMENT,
      variables: {
        totalAmount: data.money,
        cardId: data.creditCardId,
        antiFraud: data.antiFraud,
      },
    });
    return createAdminPayment;
  } catch (e) {
    const code = e.graphQLErrors && e.graphQLErrors.length ? e.graphQLErrors[0].code : 'DEFAULT';
    const erros = {
      PAYMENT_CARD_NOT_FOUND: 'Ocorreu um erro, verifique os dados do seu cartão!',
      MISSING_BALANCE: 'Não conseguimos processar seu cartão, por favor verifique seus dados e tente novamente.',
      GENERIC_ERROR: 'Não conseguimos finalizar sua compra, por favor verifique seus dados e tente novamente',
      DEFAULT: 'Não conseguimos finalizar sua compra, por favor verifique seus dados e tente novamente',
    };
    throw new Error(erros[code] ? erros[code] : erros.DEFAULT);
  }
}


export default {
  getAdminBalance,
  sendTip,
  addCreditCard,
  updateBillingAddress,
  getCreditCard,
  deleteCreditCard,
  finalizePayment,
};
