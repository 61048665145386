import React from 'react';
import PostCard from './PostCard';
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingInfinityScroll from '../../components/loading-infinity-scroll/LoadingInfinityScroll';
import './styles.scss';

export const PostList = ({
  data = [],
  fetchMore,
  onHasMore,
  onEdit,
  onDelete,
}) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMore}
      hasMore={onHasMore}
      loader={<LoadingInfinityScroll />}
    >
      <div className="row">
        {data.map(item => (
          <PostCard
            key={item._id}
            id={item._id}
            logo={item.images[0].url}
            description={item.description}
            createdAt={item.createdAt}
            article={item.article}
            deletePost={onDelete}
            handleEditClick={() => onEdit(item._id)}
          />
        ))}
      </div>
    </InfiniteScroll>
  );
};
