import React from 'react';
import ArticleActions from './ArticleActions'
import parseDateTime from '../../helpers/parse-date-time'

const ListItem = ({
  id,
  slug,
  title,
  subtitle,
  createdAt,
  onDelete,
}) => (
  <tr>
    <td>{title}</td>
    <td>{subtitle.substr(0, 50)}...</td>
    <td>{parseDateTime(createdAt)}</td>
    <td>
      <ArticleActions
        id={id}
        slug={slug}
        onDelete={onDelete}
      />
    </td>
  </tr>
);

export default ListItem;
