import * as ChatActions from '../actions/chat';

const initialState = {
  list: [],
  selectedChat: '',
  chatMessages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ChatActions.ACTION_SET_CHAT_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case ChatActions.ACTION_SET_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: action.payload,
      };
    case ChatActions.ACTION_SET_CHAT_MESSAGES:
      return {
        ...state,
        chatMessages: action.payload,
      };
    default:
      return state;
  }
};

export function getChatList(state) {
  return state.chat.list;
}

export function getSelectedChat(state) {
  return state.chat.selectedChat;
}

