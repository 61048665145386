import React from 'react';
import './styles.scss';
import '../../pages/register-continuation/styles.scss';

const OccupationAreaItem = ({
  name, image, isSelected, handleClick,
}) => (
  <button
    type="button"
    className={`register-continuation__category-item 
    ${isSelected ? 'register-continuation__category-item--selected' : ''}`}
    onClick={() => handleClick()}
  >
    {isSelected && (
    <img
      src="/assets/svg/checked.svg"
      alt="Drug"
      className="register-continuation__category-item__check-ic"
    />
    )}

    <div
      className="register-continuation__category-item__image"
    >
      <img
        src={image}
        alt={`Imagem ${name}`}
      />
    </div>
    <p className="register-continuation__category-item__text">
      {name}
    </p>
  </button>
);

export default OccupationAreaItem;
