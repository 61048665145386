/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { getUser } from '../../app/redux/reducers/user';
import { getSales } from '../../app/redux/reducers/sale';
import { getDashboard } from '../../app/redux/reducers/dashboard';
import * as SalesActions from '../../app/redux/actions/sale';
import * as DashboardActions from '../../app/redux/actions/dashboard';
import './styles.scss';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => getUser(state));
  const sales = useSelector(state => getSales(state));
  const dashboard = useSelector(state => getDashboard(state));
  const MSG_VALUE = 0.02;

  useEffect(() => {
    dispatch(SalesActions.getSales());
    if (user.companies[0]) {
      dispatch(DashboardActions.getUnreadMessages(user.companies[0]._id));
      dispatch(DashboardActions.getAccountBalance(user.companies[0]._id));
    }
  }, []);

  return (
    <div className="page-container container cards-container">
      <div className="dashboard__cards-container">
        <div
          className="dashboard__cards-container__card"
          style={{filter: `hue-rotate(${Math.floor(Math.random() * 30)}deg)`}}
        >
          <div className="dashboard__cards-container__total-number">
            {dashboard.unreadMessages}
          </div>
          <div className="dashboard__cards-container__title">
            MENSAGENS NÃO LIDAS
          </div>
          <button
            type="button"
            className="dashboard__cards-container__button"
            onClick={() => navigate(`${I18n.t('routes.chat.url')}`)}
          >
            VER MENSAGENS
          </button>
        </div>

        <div
          className="dashboard__cards-container__card"
          style={{filter: `hue-rotate(${Math.floor(Math.random() * 30)}deg)`}}
        >
          <div className="dashboard__cards-container__total-number">
            {sales.sales.length}
          </div>
          <div className="dashboard__cards-container__title">
            PUBLICAÇÕES ATIVAS
          </div>
          <button
            type="button"
            className="dashboard__cards-container__button"
            onClick={() => navigate(`${I18n.t('routes.post.url')}`)}
          >
            VER PUBLICAÇÕES
          </button>
        </div>

        <div
          className="dashboard__cards-container__card"
          style={{filter: `hue-rotate(${Math.floor(Math.random() * 30)}deg)`}}
        >
          <div className="dashboard__cards-container__total-currency">
            {dashboard.accountBalance > 0
              ? dashboard.accountBalance.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
              : 'R$ 0,00'
            }
          </div>
          <div className="dashboard__cards-container__title">
            CRÉDITOS DISPONÍVEIS
          </div>
          <button
            type="button"
            className="dashboard__cards-container__button"
            onClick={() => navigate(`${I18n.t('routes.credits.url')}`)}
          >
            COMPRAR AGORA
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
