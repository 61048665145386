import {
  addLoading,
  removeLoading,
} from './loading';
import ChatServer from '../../api/chat';
import WalletServer from '../../api/wallet';

export const ACTION_SAVE_UNREAD_MESSAGES = 'SAVE_UNREAD_MESSAGES';
export const ACTION_SAVE_ACCOUNT_BALANCE = 'SAVE_ACCOUNT_BALANCE';

export const saveUnreadMessages = unreadMessages => ({
  type: ACTION_SAVE_UNREAD_MESSAGES,
  payload: unreadMessages,
});

export const saveAccountBalance = accountBalance => ({
  type: ACTION_SAVE_ACCOUNT_BALANCE,
  payload: accountBalance,
});


export const getUnreadMessages = companyId => async (dispatch) => {
  dispatch(addLoading());
  try {
    const unreadMessages = await ChatServer.getUnreadMessages(companyId);
    await dispatch(saveUnreadMessages(unreadMessages));
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};

export const getAccountBalance = companyId => async (dispatch) => {
  dispatch(addLoading());
  try {
    const unreadMessages = await WalletServer.getAdminBalance(companyId);
    await dispatch(saveAccountBalance(unreadMessages));
  } catch (err) {
    throw new Error(err.message);
  } finally {
    dispatch(removeLoading());
  }
};
