import React, { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { useDispatch } from 'react-redux';
import { useParams, navigate } from '@reach/router';

import { validateEmail } from '../../app/redux/actions/auth';

import toast from '../../helpers/toast';

import './styles.scss';

import FormWrapper from '../../components/shared/form/FormWrapper';

export default function EmailValidation() {
  const { token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    async function verifyToken() {
      try {
        await dispatch(validateEmail(token));
        toast.success('Parabéns! Sua conta foi validada com sucesso!');
        toast.success('Você será redirecionado em 3 segundos...');
        setTimeout(() => {
          navigate(I18n.t('routes.registerContinuation.url'));
        }, 3000)
      } catch (err) {
        toast.error(err.message);
        toast.error('Você será redirecionado em 3 segundos...');
        setTimeout(() => {
          navigate(I18n.t('routes.login.url'));
        }, 3000)
      }
    }

    verifyToken();
  }, [token, dispatch]);

  return (
    <FormWrapper>
      <img
        src="/assets/images/logo.svg"
        alt="Logo"
        className="logo"
        style={{
          margin: 30
        }}
      />
    </FormWrapper>
  );
}
