import React from 'react';
import InputMask from 'react-input-mask';

const STATUSDATA = {
  "ACCEPTED": "Ligação aceita",
  "REFUSED": "Ligação recusada",
  "BUSY": "Ocupado",
  "WAITING": "Aguardando",
}

const ListItem = ({
  cpf,
  customerAvatar = "./assets/images/avatar.png",
  customerName,
  dateTime,
  status,
}) => (
  <tr>
    <td>
      <div
        className="table__avatar"
        style={{
          backgroundImage: `url(${customerAvatar})`,
          flex: 1,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    </td>
    <td>{customerName}</td>
    <td>{dateTime}</td>
    <td>{STATUSDATA[status]}</td>
  </tr>
);

export default ListItem;
