import React, { Fragment, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { connect } from 'react-redux';
import autosize from "autosize";
import ChatTipModal from '../../components/modal/ChatTip';
import { ImageChat } from './ImageChat'
import Show from '../../components/elements/Show'
import toast from '../../helpers/toast';
import { handleImageCompress } from '../../helpers/compressImage';
import { IoIosArrowBack, IoIosAdd, IoIosSend } from "react-icons/io";
import {
  sendMessage,
  getChatMessages,
  chatMessageUpdatedObservable,
  sendChatTip,
} from '../../app/redux/actions/chat';
import preferences from '../../data/preferences';
import Number from '../../helpers/number';

const Conversation = ({
  selectedChat,
  chatMessages,
  sendNewMessage,
  getMessages,
  chatMessageUpdatedObservable,
  sendTip,
  onStatus,
}) => {
  const [chatInput, setChatInput] = useState('');
  const [page, setPage] = useState(1);
  const [observable, setObservable] = useState(null);
  const [chatTip, setChatTip] = useState(0);
  const [isChatTipModalOpen, setIsChatTipModalOpen] = useState(false);
  const inputImage = useRef(null)
  const textarea = useRef(null)

  const loadMoreMessages = async () => {
    if (chatMessages.length < selectedChat.count) {
      getMessages(true, (page * preferences.chatMessageTake), true);
      await setPage(oldPage => oldPage + 1);
    }
  };

  const getInputClick = () => inputImage.current.click();

  useEffect(() => {
    textarea.current.focus();
    autosize(textarea.current);
  }, []);

  useEffect(() => {
    setPage(1);
    getMessages(true, 0, true);
  }, [selectedChat]);

  useEffect(() => {
    initObservable();
  }, []);

  useEffect(() => {
    return () => {
      if (observable) {
        observable.unsubscribe();
      }
    };
  }, [observable]);

  useEffect(() => {
    const chat = document.getElementById('chat-messages');
    chat.onscroll = debounce(() => {
      if (chat.scrollTop < 100) {
        loadMoreMessages();
      }
    }, 100);
  });

  const getFileAndConvert = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const compressedImage = await handleImageCompress(event.target.files[0]);
      sendNewMessage({ image: compressedImage });
    } else {
      toast.error('Esse tipo de arquivo não é suportado!');
    }
  }

  const checkDate = (lastMessage, message) => {
    const lastDay = parseInt(moment(lastMessage).format('DD'), 10);
    const day = parseInt(moment(message).format('DD'), 10);
    return !(lastDay === day);
  };

  const sendMoney = async (value) => {
    try {
      await sendTip(value);
      toast.success(`Você acabou de doar R$${value},00`);
      setChatTip(0);
      setIsChatTipModalOpen(false);
    } catch (err) {
      toast.error(err.message);
      setChatTip(0);
      setIsChatTipModalOpen(false);
    }
  };

  const onClickTip = (value) => {
    setIsChatTipModalOpen(true);
    setChatTip(value);
  };

  async function initObservable() {
    const chatObservable = await chatMessageUpdatedObservable();
    setObservable(chatObservable);
  }

  async function onSendMessage(event) {
    event.preventDefault();
    try {
      if (chatInput.trim() !== '') {
        sendNewMessage({ text: chatInput });
        setChatInput('');
        setPage(1);
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      onSendMessage(e)
    }
  }

  return (
    <Fragment>
      <ChatTipModal
        isOpen={isChatTipModalOpen}
        onOverlayClick={() => setIsChatTipModalOpen(null)}
        tipValue={chatTip}
        onConfirmClick={() => sendMoney(chatTip)}
      />
      <div className="chat__header">
        <div className="chat__header__left">
          <span>
            <IoIosArrowBack
              className="chat__header__left__icon"
              onClick={() => onStatus(true)}
              size={22}
            />
          </span>
          <span>{selectedChat?.customer?.name ? `${selectedChat?.customer?.name} - CPF: ${Number.cpfMask(selectedChat?.customer?.cpf)}` : 'Usuário não existe'}</span>
        </div>
      </div>

      <div id="chat-messages" className="chat__body">
        {chatMessages && chatMessages.length > 0 && chatMessages.map((item, index) => {
          const msgClass = item.customer ? 'incoming' : 'outgoing';
          const msgSystemClass = item.system ? 'system' : msgClass;
          const msgTime = moment(parseInt(item.createdAt, 10));
          const msgTimeFormatted = msgTime.format('HH:mm');

          let lastMsgTime = '';
          let showDayBreak;
          if (index === 0) showDayBreak = true;
          else if (index >= 1) {
            lastMsgTime = moment(parseInt(chatMessages[index - 1].createdAt, 10));
            showDayBreak = checkDate(lastMsgTime, msgTime);
          }

          return (
            <Fragment key={item._id}>

              {showDayBreak && (
                <div className="chat__body__message-row__day-line">
                  <div className="day">{msgTime.format('dddd[,] DD [de] MMMM')} </div>
                </div>
              )}

              <div className={`chat__body__message-row chat__body__message-row--${msgSystemClass}`}>
                <div
                  className={`chat__body__message-row${item.text ? '__content' : '__content-image'}
                    chat__body__message-row__content--${msgSystemClass}`}
                >
                  <span>
                    <Show if={item.text}>
                      {item.text}
                    </Show>

                    <Show if={item.image}>
                      <ImageChat url={item.image && item.image.url} />
                    </Show>
                    <span
                      className={`chat__body__message-row__content__time
                        chat__body__message-row__content__time--${msgSystemClass}`}
                    >
                      {msgTimeFormatted}
                    </span>
                  </span>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>

      <div className="chat__footer">
        <div className="chat__tip">
          <p className="chat__tip__text">
            Cashback
          </p>
          <div className="chat__tip__container-buttons">
            <button
              type="button"
              className="chat__tip__button"
              onClick={() => onClickTip(1)}
            >
              <p className="chat__tip__value">R$ 1,00</p>
            </button>
            <button
              type="button"
              className="chat__tip__button"
              onClick={() => onClickTip(3)}
            >
              <p className="chat__tip__value">R$ 3,00</p>
            </button>
            <button
              type="button"
              className="chat__tip__button"
              onClick={() => onClickTip(5)}
            >
              <p className="chat__tip__value">R$ 5,00</p>
            </button>
          </div>
        </div>
        <textarea
          value={chatInput}
          placeholder={selectedChat && !selectedChat.isActive
            ? 'Ops parece que o chat foi desativado pelo cliente. Que pena!' : 'Digite sua mensagem...'}
          className="chat__footer__textarea"
          ref={textarea}
          onChange={e => setChatInput(e.target.value)}
          disabled={selectedChat && !selectedChat.isActive}
          maxLength={250}
          rows={1}
          onKeyDown={handleKeyDown}
        />

        <input
          type='file'
          accept='image/*'
          ref={inputImage}
          onChange={getFileAndConvert}
          style={{ display: "none" }}
        />
        <button
          type="button"
          className="chat__footer__image-button"
          onClick={getInputClick}
          disabled={selectedChat && !selectedChat.isActive}
        >
          <IoIosAdd size={34} />
        </button>

        <button
          type="submit"
          className="chat__footer__send-button"
          onClick={e => onSendMessage(e)}
          disabled={selectedChat && !selectedChat.isActive}
        >
          <IoIosSend size={25} />
        </button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  chatMessages: state.chat && state.chat.chatMessages,
  selectedChat: state.chat && state.chat.selectedChat,
});

const mapDispatchToProps = dispatch => ({
  sendNewMessage: ({ text, image, document }) => dispatch(sendMessage({ text, image, document })),
  getMessages: (load, skip, scroll) => dispatch(getChatMessages(load, skip, scroll)),
  chatMessageUpdatedObservable: () => dispatch(chatMessageUpdatedObservable()),
  sendTip: value => dispatch(sendChatTip(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
