import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '../loading-button';
import ArrowRight from '../../svg/arrow_right.svg';
import toast from '../../helpers/toast';
import { finishRegister } from '../../app/redux/actions/user';
import { getAddressByCep } from '../../app/redux/actions/utils';
import validator from '../../helpers/form-validator';
import { LoadingSelectors } from '../../app/redux/reducers';
import '../../pages/register-continuation/styles.scss';

const CompanyRegister = ({
  handleBackStep,
  handleNextStep,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => LoadingSelectors.getLoading(state) > 0);
  const [documentType, setDocumentType] = useState('CNPJ')
  const [formData, setFormData] = useState({
    name: '',
    document: '',
    cep: '',
    address: '',
    city: '',
    uf: '',
    complement: '',
    radius: '',
    base64Logo: '',
  });

  const onFormDataChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const getFileAndConvert = async (file) => {
    if (file) {
      if (file.type.includes('image')) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          onFormDataChange(reader.result, 'base64Logo');
        };
        reader.onerror = (error) => {
          toast(error);
        };
      } else {
        toast.error('Esse tipo de arquivo não é suportado!');
      }
    }
  };

  const handleFormSubmit = async () => {
    try {
      const isFormValid = validator.validateCompanyForm(formData);
      if (isFormValid.error) {
        toast.error(isFormValid.errorMessage);
      } else {
        await dispatch(finishRegister(formData));
        handleNextStep();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleGetAddress = async () => {
    const {
      logradouro, complemento, localidade, uf,
    } = await dispatch(getAddressByCep(formData.cep));
    await setFormData({
      ...formData,
      address: logradouro,
      complement: complemento,
      city: localidade,
      uf,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="register-continuation__title">Continue seu cadastro</h1>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <div className="form-input">
            <span className="company-register__legend">Nome da Empresa</span>
            <input
              type="text"
              value={formData.name}
              className="company-register__input"
              onChange={e => onFormDataChange(e.target.value, 'name')}
            />
          </div>

        </div>
        <div className="col-2">
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <div className="company-register__warning">
              <input
                type="radio"
                value="CNPJ"
                defaultChecked
                name="documents"
                onChange={(e) => {
                  setDocumentType(e.target.value);
                  onFormDataChange('', 'document');
                }}
                className="company-register__warning__input"
              />
              <span className="company-register__warning__text">CNPJ </span>
            </div>
            <div className="company-register__warning">
              <input
                type="radio"
                value="CPF"
                name="documents"
                onChange={(e) => {
                  setDocumentType(e.target.value);
                  onFormDataChange('', 'document');
                }}
                className="company-register__warning__input"
              />
              <span className="company-register__warning__text">CPF </span>
            </div>
          </div>
        </div>
        <div className="col-4">
          <span className="company-register__legend">
            {documentType}
          </span>
          {documentType == 'CPF' ? (
            <InputMask
              mask="999.999.999-99"
              className="company-register__input"
              required
              value={formData.document}
              onChange={e => onFormDataChange(e.target.value, 'document')}
            />
          ) : (
            <InputMask
              mask="99.999.999/9999-99"
              className="company-register__input"
              required
              value={formData.document}
              onChange={e => onFormDataChange(e.target.value, 'document')}
            />
          )}
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-4">
          <div className="form-input">
            <span className="company-register__legend">CEP</span>
            <InputMask
              mask="99999-999"
              className="company-register__input"
              required
              value={formData.cep}
              onChange={e => onFormDataChange(e.target.value, 'cep')}
              onBlur={() => handleGetAddress()}
            />
          </div>
        </div>
        <div className="col-8">
          <div className="form-input">
            <span className="company-register__legend">Endereço</span>
            <input
              type="text"
              value={formData.address}
              className="company-register__input"
              onChange={e => onFormDataChange(e.target.value, 'address')}
            />
          </div>

        </div>
      </div>
      <div className="row mt-5">
        <div className="col-4">
          <div className="form-input">
            <span className="company-register__legend">Complemento</span>
            <input
              type="text"
              value={formData.complement}
              className="company-register__input"
              onChange={e => onFormDataChange(e.target.value, 'complement')}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="form-input">
            <span className="company-register__legend">Cidade</span>
            <input
              className="company-register__input"
              value={formData.city}
              onChange={e => onFormDataChange(e.target.value, 'city')}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="form-input">
            <span className="company-register__legend">Estado</span>
            <input
              className="company-register__input"
              value={formData.uf}
              onChange={e => onFormDataChange(e.target.value, 'uf')}
            />
          </div>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-4">
          <div className="form-input">
            <span className="company-register__legend">Raio de atuação (Em KM)</span>
            <input
              type="number"
              className="company-register__input"
              onChange={e => onFormDataChange(e.target.value, 'radius')}
            />
          </div>
        </div> */}
        <div className="col-8 align-items-center">
          <span className="company-register__file__title">Carregue o logo da sua empresa</span>
          <input
            type="file"
            accept=".jpeg, .tif, .jpg, .png"
            className="company-register__file__input"
            onInput={e => getFileAndConvert(e.target.files[0])}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6 d-flex justify-content-start mt-5">
          <button
            type="button"
            className="button"
            onClick={() => handleBackStep()}
          >
            Voltar
            <img
              className="button__arrow-ic__left"
              src={ArrowRight}
              alt="arrow"
            />
          </button>
        </div>
        <div className="col-6 d-flex justify-content-end mt-5">
          <LoadingButton
            loading={loading}
            title='Avançar'
            onClick={handleFormSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default CompanyRegister;
