import React from 'react';
import { Redirect, useLocation } from '@reach/router';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { Helmet } from 'react-helmet';

import * as AuthSelectors from '../redux/reducers/auth';
import PanelContainer from '../../components/painel/index';
import Loading from '../../components/shared/Loading';

const Panel = (props) => {
  const { pathname } = useLocation();
  const { title, container, isAuthenticated } = props;
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {!isAuthenticated ? (
        <Redirect
          noThrow
          from={pathname}
          to={I18n.t('routes.login.url')}
        />
      ) : <PanelContainer container={container} />}
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: AuthSelectors.isAuthenticated(state),
});

export default connect(
  mapStateToProps,
)(
  Panel,
);
