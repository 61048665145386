import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { navigate } from '@reach/router';

import './styles.scss';
import { I18n } from 'react-redux-i18n';

import FormHeader from '../shared/form/FormHeader';
import FormFooter from '../shared/form/FormFooter';

import validator from '../../helpers/form-validator';
import toast from '../../helpers/toast';

import { updateUser } from '../../app/redux/actions/user';

export default function RedefinePasswordForm() {
  const dispatch = useDispatch();
  const [formData, setFormdata] = useState({
    password: '',
    confirmPassword: '',
  });

  const [hiddenPassword, setHiddenPassword] = useState({
    eyePassword: true,
    eyeConfirmPassword: true,
  });

  const onFormDataChange = (value, field) => {
    setFormdata({
      ...formData,
      [field]: value,
    });
  };

  const onHiddenPasswordChange = (value, field) => {
    setHiddenPassword({
      ...hiddenPassword,
      [field]: value,
    });
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    const validate = validator.validateRedefinePasswordForm(formData);
    if (validate.error) {
      toast.error(validate.errorMessage);
      return;
    }

    try {
      await dispatch(updateUser({ password: formData.password }));
      toast.success('Senha alterada com sucesso.');
      navigate(I18n.t('routes.dashboard.url'));
    } catch (error) {
      toast.error(error.message);
      setTimeout(() => {
        navigate(I18n.t('routes.login.url'));
      }, 2000);
    }
  };

  return (
    <>
      <FormHeader />
      <section className="redefine-password-form">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 redefine-password-form__container">
              <form
                onSubmit={e => onFormSubmit(e)}
                type="submit"
              >
                <div className="redefine-password-form__container__content justify-content-center pt-4 pb-4">
                  <h1 className="redefine-password-form__font redefine-password-form__font__title">REDEFINIR SENHA</h1>

                  <div className="redefine-password-form__password-div">
                    {hiddenPassword.eyePassword ? (
                      <FiEye
                        className="redefine-password-form__password-div__icon"
                        onClick={() => onHiddenPasswordChange(!hiddenPassword.eyePassword, 'eyePassword')}
                      />

                    )
                      : (
                        <FiEyeOff
                          className="redefine-password-form__password-div__icon"
                          onClick={() => onHiddenPasswordChange(!hiddenPassword.eyePassword, 'eyePassword')}
                        />
                      )}

                    <span className="redefine-password-form__font redefine-password-form__font__label">Senha </span>
                    <input
                      placeholder="Digite sua senha"
                      type={hiddenPassword.eyePassword ? 'password' : 'text'}
                      className="redefine-password-form__font redefine-password-form__password-div__input"
                      required
                      value={formData.password}
                      maxLength={128}
                      onChange={e => onFormDataChange(e.target.value, 'password')}
                    />
                  </div>

                  <div className="redefine-password-form__password-div">
                    {hiddenPassword.eyeConfirmPassword ? (
                      <FiEye
                        className="redefine-password-form__password-div__icon"
                        onClick={() => onHiddenPasswordChange(!hiddenPassword.eyeConfirmPassword, 'eyeConfirmPassword')}
                      />

                    )
                      : (
                        <FiEyeOff
                          className="redefine-password-form__password-div__icon"
                          onClick={() => onHiddenPasswordChange(!hiddenPassword.eyeConfirmPassword,
                            'eyeConfirmPassword')}
                        />
                      )}

                    <span className="redefine-password-form__font redefine-password-form__font__label">
                      Confirmação da senha
                    </span>
                    <input
                      placeholder="Digite sua senha novamente"
                      type={hiddenPassword.eyeConfirmPassword ? 'password' : 'text'}
                      className="redefine-password-form__font redefine-password-form__password-div__input"
                      required
                      value={formData.confirmPassword}
                      maxLength={128}
                      onChange={e => onFormDataChange(e.target.value, 'confirmPassword')}
                    />
                  </div>

                </div>
                <FormFooter
                  onFormSubmit={e => onFormSubmit(e)}
                  buttonTitle="CONCLUIR"
                />
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
