import moment from 'moment';
import {
  validateEmail,
  validateDocument,
  validateBirthday,
  validatePhone,
  validadeCardExpirationData,
} from './validators';

const validateGetCustomerByCpfForm = (data) => {
  const { cpf } = data;

  if (!validateDocument('cpf', cpf)) {
    return {
      error: true,
      errorMessage: 'O CPF digitado não é válido',
    };
  }

  return {
    error: false,
    errorMessage: '',
  };
}

const validateRegisterForm = (data) => {
  const {
    name, email, confirmEmail, document, phone, password, confirmPassword, agree,
  } = data;

  if (!name || !email || !confirmEmail || !document || !phone || !password || !confirmPassword) {
    return {
      error: true,
      errorMessage: 'Por favor, preencha todos os campos!',
    };
  }

  if (email !== confirmEmail) {
    return {
      error: true,
      errorMessage: 'Os emails devem ser iguais',
    };
  }

  if (!validateEmail(email)) {
    return {
      error: true,
      errorMessage: 'O E-mail digitado não está em um formato válido',
    };
  }

  if (password !== confirmPassword) {
    return {
      error: true,
      errorMessage: 'As senhas devem ser iguais',
    };
  }

  if (password.length < 6) {
    return {
      error: true,
      errorMessage: 'A senha deve conter no mínimo 6 caracteres',
    };
  }

  const documentType = (document.length > 14) ? 'cnpj' : 'cpf';

  if (!validateDocument(documentType, document)) {
    return {
      error: true,
      errorMessage: 'O CNPJ/CPF digitado não é válido',
    };
  }

  if (!agree) {
    return {
      error: true,
      errorMessage: 'Você deve aceitar os termos de uso para continuar',
    };
  }

  return {
    error: false,
    errorMessage: '',
  };
};

const validateLoginForm = (data) => {
  const { email, password } = data;

  if (!validateEmail(email)) {
    return {
      error: true,
      errorMessage: 'O E-mail digitado não está em um formato válido',
    };
  }

  if (password.length < 6) {
    return {
      error: true,
      errorMessage: 'A senha deve conter no mínimo 6 caracteres',
    };
  }

  return {
    error: false,
    errorMessage: '',
  };
};

const validateCompanyForm = (data) => {
  const {
    address, city, uf, cep, document, base64Logo,
  } = data;

  if (!address || !city || !cep || !document || !base64Logo || !uf) {
    return {
      error: true,
      errorMessage: 'Por favor, preencha todos os campos!',
    };
  }

  const documentType = (document.length > 14) ? 'cnpj' : 'cpf';

  if (!validateDocument(documentType, document)) {
    return {
      error: true,
      errorMessage: 'O CNPJ/CPF digitado não é válido',
    };
  }

  return {
    error: false,
    errorMessage: '',
  };
};

const validateForgotPasswordForm = ({ email }) => {
  if (!email) {
    return {
      error: true,
      errorMessage: 'Preencha todos os campos!',
    };
  }

  if (!validateEmail(email)) {
    return {
      error: true,
      errorMessage: 'Insira um email válido',
    };
  }

  return {
    error: false,
    errorMessage: '',
  };
};

const validateRedefinePasswordForm = ({ password, confirmPassword }) => {
  if (confirmPassword !== password) {
    return {
      error: true,
      errorMessage: 'As senhas devem ser iguais!',
    };
  }

  if (password.length < 6) {
    return {
      error: true,
      errorMessage: 'A senha deve ter no mínimo 6 caracteres!',
    };
  }

  return {
    error: false,
    errorMessage: '',
  };
};

const validateArticle = ({ title, subtitle, body }) => {
  if (!title || !subtitle || !body) {
    return {
      error: true,
      errorMessage: 'Por favor, preencha todos os campos!',
    };
  }

  return {
    error: false,
    errorMessage: '',
  };
};

const validateAddSaleForm = (sale) => {
  const {
    description, base64Logo,
  } = sale;

  if (!description || !base64Logo) {
    return {
      error: true,
      errorMessage: 'Por favor, preencha todos os campos!',
    };
  }

  return {
    error: false,
    errorMessage: '',
  };
};

const validateUpdateSaleForm = (sale, oldSale) => {
  const {
    description, base64Logo, article,
  } = sale;

  if (!description) {
    return {
      error: true,
      errorMessage: 'Por favor, preencha todos os campos!',
    };
  }

  return {
    error: false,
    errorMessage: '',
  };
};

const validateChangeProfileForm = (newData, oldData) => {
  const oldName = oldData.name;
  const oldEmail = oldData.email;
  const oldPhone = oldData.phone;

  if (!newData.name || !newData.email || !newData.phone) {
    return {
      error: true,
      errorMessage: 'Por favor, preencha todos os campos!',
    };
  }

  if ((oldName === newData.name) && (oldEmail === newData.email) && (oldPhone === newData.phone)) {
    return {
      error: true,
      errorMessage: 'Modifique algum campo para alterar os dados!',
    };
  }

  if (!validateEmail(newData.email)) {
    return {
      error: true,
      errorMessage: 'O E-mail digitado não está em um formato válido',
    };
  }

  if (!validatePhone(newData.phone)) {
    return {
      error: true,
      errorMessage: 'O número de telefone é inválido',
    };
  }


  return {
    error: false,
    errorMessage: '',
  };
};

const validateChangePasswordForm = (data) => {
  const {
    oldPassword, newPassword, confirmNewPassword,
  } = data;

  if (!oldPassword || !newPassword || !confirmNewPassword) {
    return {
      error: true,
      errorMessage: 'Por favor, preencha todos os campos!',
    };
  }

  if (oldPassword.length < 6 || newPassword.length < 6 || confirmNewPassword.length < 6) {
    return {
      error: true,
      errorMessage: 'As senhas devem conter no mínimo 6 caracteres!',
    };
  }

  if (oldPassword === newPassword) {
    return {
      error: true,
      errorMessage: 'A senha atual e a nova senha não podem ser iguais!',
    };
  }

  if (newPassword !== confirmNewPassword) {
    return {
      error: true,
      errorMessage: 'A nova senha e sua confirmação devem ser iguais!',
    };
  }

  return {
    error: false,
    errorMessage: '',
  };
};

const validateAddcreditCardForm = (data) => {
  const {
    number, expireDate, CVV, name, brand, address, addressNumber, neighborhood, cep, city, uf, document,
  } = data;

  if (!number || !expireDate || !CVV || !name
      || !brand || !address || !addressNumber || !neighborhood || !cep || !city || !uf || !document) {
    return {
      error: true,
      errorMessage: 'Por favor, preencha todos os campos!',
    };
  }

  if (number.indexOf('_') > 0) {
    return {
      error: true,
      errorMessage: 'O número do cartão está incompleto',
    };
  }

  if (CVV.length > 4) {
    return {
      error: true,
      errorMessage: 'O código de segurança é inválido',
    };
  }

  if (expireDate.indexOf('_') > 0) {
    return {
      error: true,
      errorMessage: 'A validade do cartão está incompleta',
    };
  }

  if (!validadeCardExpirationData(expireDate)) {
    return {
      error: true,
      errorMessage: 'A validade do cartão está invalida',
    };
  }

  if (cep.indexOf('_') > 0) {
    return {
      error: true,
      errorMessage: 'O CEP está incompleto',
    };
  }

  if (document.indexOf('_') > 0) {
    return {
      error: true,
      errorMessage: 'O documento está incompleto',
    };
  }

  const documentType = (document.length > 14) ? 'cnpj' : 'cpf';

  if (!validateDocument(documentType, document)) {
    return {
      error: true,
      errorMessage: 'O CNPJ/CPF digitado não é válido',
    };
  }

  return {
    error: false,
    errorMessage: '',
  };
};

const validateChangeBillingAddress = (newData, oldData) => {
  if (!newData.cep || !newData.address || !newData.addressNumber || !newData.neighborhood
      || !newData.city || !newData.uf || !newData.complement) {
    return {
      error: true,
      errorMessage: 'Por favor, preencha todos os campos!',
    };
  }

  if (JSON.stringify(newData) === JSON.stringify(oldData)) {
    return {
      error: false,
      sucessMessage: 'Enable',
    };
  }

  if (newData.cep.indexOf('_') > 0) {
    return {
      error: true,
      errorMessage: 'O CEP está incompleto',
    };
  }


  return {
    error: false,
    errorMessage: '',
    sucessMessage: '',
  };
};

export default {
  validateGetCustomerByCpfForm,
  validateRegisterForm,
  validateLoginForm,
  validateCompanyForm,
  validateRedefinePasswordForm,
  validateForgotPasswordForm,
  validateArticle,
  validateAddSaleForm,
  validateUpdateSaleForm,
  validateChangePasswordForm,
  validateChangeProfileForm,
  validateAddcreditCardForm,
  validateChangeBillingAddress,
};
