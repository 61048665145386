import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { useDispatch, useSelector } from 'react-redux';

import OccupationAreaItem from './OccupationAreaItem';
import OcuppationModal from './OccupationModal/OccupationModal';

import toast from '../../helpers/toast';
import SettingActions from '../../app/redux/actions/settings';
import ArrowRight from '../../svg/arrow_right.svg';
import './styles.scss';

import { saveOccupationArea, saveSubOccupationArea } from '../../app/redux/actions/user';
import { getUser, getOcuppationArea, getSubOcuppationArea } from '../../app/redux/reducers/user';
import '../../pages/register-continuation/styles.scss';

const OccupationArea = ({ handleNextStep }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => getUser(state));
  const selectedCategory = useSelector(state => getOcuppationArea(state));
  const selectedSubcategory = useSelector(state => getSubOcuppationArea(state));
  const categories = useSelector(state => state.settings.categories);
  const subcategories = useSelector(state => state.settings.subcategories);

  const [selectedOccupation, setSelectedOccupation] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (user.companies && user.companies.length > 0) {
      toast.error('Você já finalizou o seu cadastro!');
      navigate(I18n.t('routes.dashboard.url'));
    }
  }, [user]);

  useEffect(() => {
    dispatch(SettingActions.getCategories());
    setSelectedOccupation({
      _id: selectedCategory,
    });
    setSelectedSubcategories(selectedSubcategory);
  }, [dispatch]);

  useEffect(() => {
    if (subcategories && subcategories.length > 0) {
      setIsModalOpen(true);
    }
  }, [subcategories]);

  const onClickOccupationArea = async (item) => {
    if (!selectedOccupation) {
      setSelectedOccupation(item);
      await dispatch(SettingActions.getSubCategories(item._id));
    } else if (selectedOccupation._id !== item._id) {
      setSelectedOccupation(item);
      setSelectedSubcategories([]);
      await dispatch(SettingActions.getSubCategories(item._id));
    } else if (selectedOccupation._id === item._id) {
      if (subcategories && subcategories.length > 0) setIsModalOpen(true);
      else {
        setIsModalOpen(false);
      }
    }
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="register-continuation__title">Qual sua área de atuação?</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-12 register-continuation__category-content">
          {categories && categories.length > 0 && categories.map((item) => {
            if (item.adminPicture) {
              return (
                <OccupationAreaItem
                  key={item._id}
                  name={item.name}
                  image={item.adminPicture && item.adminPicture.url}
                  isSelected={item._id === selectedOccupation._id}
                  handleClick={() => {
                    onClickOccupationArea(item);
                  }}
                />
              );
            }
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-end mt-5 pr-5">
          <button
            type="button"
            className={`button ${selectedOccupation._id === '' ? 'button--disabled' : ''}`}
            onClick={() => {
              dispatch(saveOccupationArea(selectedOccupation._id));
              dispatch(saveSubOccupationArea(selectedSubcategories));
              handleNextStep();
            }}
            disabled={selectedOccupation._id === ''}
          >
            Avançar
            <img
              className="button__arrow-ic"
              src={ArrowRight}
              alt="arrow"
            />
          </button>
        </div>
      </div>

      <OcuppationModal
        isOpen={isModalOpen}
        subcategories={subcategories}
        selectedSubcategories={selectedSubcategories}
        setSelectedSubcategories={setSelectedSubcategories}
        onOverlayClick={() => {
          onCloseModal(false);
        }}
      />
    </>
  );
};

export default OccupationArea;
