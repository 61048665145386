import React, { useState } from 'react';
import Modal from 'react-modal';
import Show from '../../components/show';
import { FiPhoneOff, FiPhoneOutgoing, FiPhoneMissed } from 'react-icons/fi';
import InputMask from 'react-input-mask';
import CallServer from '../../app/api/call';

import './styles.scss';

const customStyles = {
  overlay: {
    background: 'rgb(0,0,0,0.85)',
  },
};

Modal.setAppElement('#root');

const CallAnsweredModal = ({
  isOpen,
  onClose,
  call,
}) => {
  const [formData, setFormdata] = useState({
    feedback: 'POSITIVE',
    feedbackMsg: '',
  });
  
  const onSave = async() => {
    const { feedback, feedbackMsg } = formData;
    const { data: { updateCall }} = await CallServer.updateCall({
      _id: call._id,
      company: call.company._id,
      customer: call.customer._id,
      feedback,
      feedbackMsg 
    });
    onClose(updateCall);
  }
  
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      overlayClassName="modal"
      className="modal-call-answered__container"
    >
      <div className="row w-100 ml-0">
        <div className="col-12">
          <div className="modal-call-answered__text-container px-4 py-4">
            <div className="row">
              <div className="col-4">
                <div
                  className="modal-call-answered__avatar"
                  style={{
                    backgroundImage: `url(./assets/images/avatar.png)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                />
              </div>
              <div className="col-8">
                <p className="modal-call-answered__title">
                  {call && call.customer && call.customer.name}
                </p>
                <p className="modal-call-answered__subtitle">
                  <InputMask
                    mask="999.999.999-99"
                    placeholder="999.999.999-99"
                    className="modal-call-answered__subtitle --input"
                    disabled
                    value={call && call.customer && call.customer.cpf}
                  />
                </p>
              </div>
            </div>

            <Show if={call.status == "ACCEPTED"}>
              <p className="modal-call-answered__status --green">
                <FiPhoneOutgoing size={20} /> Chamada Aceita
              </p>
              <p className="modal-call-answered__description">
                Conseguiu falar com o cliente?
              </p>
              <div className="modal-call-answered__container-button">
                <button
                  type="button"
                  className={`modal-call-answered__container-button__button outline ${formData.feedback == 'POSITIVE' ? 'selected' : ''}`}
                  onClick={() => {setFormdata({...formData, feedback: "POSITIVE"})}}
                >
                  Sim
                </button>
                <button
                  type="button"
                  className={`modal-call-answered__container-button__button outline ${formData.feedback == 'NEGATIVE' ? 'selected' : ''}`}
                  onClick={() => {setFormdata({...formData, feedback: "NEGATIVE"})}}
                >
                  Não
                </button>
              </div>

              <div className="form-input-container">
                <div className="form-input">
                  <span className="add-promotions__input__legend">Descrição</span>
                  <textarea
                    type="text"
                    maxLength="300"
                    placeholder="Relatório de conversa."
                    required
                    value={formData.feedbackMsg}
                    className="add-promotions__text-area"
                    onChange={e => setFormdata({...formData, feedbackMsg: e.target.value})}
                  />
                </div>
              </div>

              <div className="modal-call-answered__container-button">
                <button
                  type="button"
                  onClick={onSave}
                  className="modal-call-answered__container-button__button"
                >
                  Enviar
                </button>
              </div>
            </Show>

            <Show if={call.status == "REFUSED"}>
              <p className="modal-call-answered__status --red">
                <FiPhoneOff size={20} /> Chamada Recusada
              </p>
              <p className="modal-call-answered__description">
                Tente novamente em alguns minutos, ou vá para o próximo.
              </p>
              <div className="modal-call-answered__container-button">
                <button
                  type="button"
                  onClick={onSave}
                  className="modal-call-answered__container-button__button"
                >
                  Fechar
                </button>
              </div>
            </Show>

            <Show if={call.status == "BUSY"}>
              <p className="modal-call-answered__status --orange">
                <FiPhoneMissed size={20} /> Chamada Ocupada
              </p>
              <p className="modal-call-answered__description">
                O cliente está em outra ligação, tente novamente em instantes.
              </p>
              <div className="modal-call-answered__container-button">
                <button
                  type="button"
                  onClick={onSave}
                  className="modal-call-answered__container-button__button"
                >
                  Fechar
                </button>
              </div>
            </Show>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CallAnsweredModal;
