import React from 'react';
import './styles.scss';

const WarningMessage = ({
  message,
}) => (
  <div className="container">
    <div className="row justify-content-center my-5">
      <div className="col-12 text-center mb-1">
        <img
          src="/assets/images/warningMessage/emotion.svg"
          alt="Emoticon chorando"
        />
      </div>
      <div className="col-8 text-center">
        <p className="unlogged unlogged__description">
          {message}
        </p>
      </div>
    </div>
  </div>
);

export default WarningMessage;
