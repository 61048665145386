import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';

import SubcategoryItem from './SubCategoryItem';
import SettingActions from '../../../app/redux/actions/settings';

import './styles.scss';

const customStyles = {
  overlay: {
    background: 'rgb(0,0,0,0.85)',
  },
};

Modal.setAppElement('#root');

const OccupationModal = ({
  subcategories, isOpen, onOverlayClick, selectedSubcategories, setSelectedSubcategories,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SettingActions.getCategories());
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      shouldCloseOnOverlayClick
      onRequestClose={onOverlayClick}
      overlayClassName="modal"
      className="modal-subcategory__container"
    >
      <div className="row w-100 ml-0">
        <div className="col-12 my-2 text-center">
          <button
            className="modal-subcategory__close"
            type="button"
            onClick={onOverlayClick}
          >
            <MdClose className="modal-subcategory__close__icon" />
          </button>
          <h1 className="modal-subcategory__title ml-4 mt-3">Escolha suas subcategorias</h1>
          <div className="modal-subcategory__scroll">
            <div className="modal-subcategory__subcategory__container px-4 py-4">
              {subcategories && subcategories.length > 0 && subcategories.map((item) => {
                const isSubSelected = selectedSubcategories
                && selectedSubcategories.length > 0 && selectedSubcategories.find(category => category === item._id);

                return (
                  <SubcategoryItem
                    name={item.name}
                    key={item._id}
                    isSelected={isSubSelected}
                    handleClick={() => {
                      if (selectedSubcategories.find(category => category === item._id)) {
                        const filteredArray = selectedSubcategories.filter(sub => sub !== item._id);
                        setSelectedSubcategories(filteredArray);
                      } else {
                        setSelectedSubcategories(oldArray => [...oldArray, item._id]);
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
          <button
            type="button"
            onClick={() => onOverlayClick()}
            className="modal-subcategory__save-button"
          >
            Salvar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OccupationModal;
