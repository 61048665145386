import React from 'react';
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';

import './styles.scss';

const customStyles = {
  overlay: {
    background: 'rgb(40, 51, 74, 0.5)',
  },
};

Modal.setAppElement('#root');

const EmailValidationModal = ({ isOpen, onOverlayClick }) => (
  <Modal
    isOpen={isOpen}
    style={customStyles}
    shouldCloseOnOverlayClick
    onRequestClose={onOverlayClick}
    overlayClassName="modal"
    className="modal__container"
  >
    <div className="row d-flex justify-content-center">
      <div className="col-12 my-2 px-5 text-align-center d-flex flex-column align-items-center">
        <h3 className="modal__title mt-4">Você está indo bem!</h3>
        <p className="modal__description">
            Enviamos um e-mail com as instruções para sua aprovação na EloPag,
             use as instruções enviadas para conseguir acessar a plataforma.
        </p>

        <button
          className="modal__button mt-3"
          type="button"
          onClick={onOverlayClick}
        >
          Fechar
        </button>

        <button
          className="modal__close"
          type="button"
          onClick={onOverlayClick}
        >
          <MdClose className="modal__close__icon" />
        </button>
      </div>
    </div>
  </Modal>
);

export default EmailValidationModal;
