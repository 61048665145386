import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { useDispatch } from 'react-redux';

import toast from '../../helpers/toast';
import Validator from '../../helpers/form-validator';
import * as UserActions from '../../app/redux/actions/user';
import { getAddressByCep } from '../../app/redux/actions/utils';

import optionsBrand from '../../data/credit-card-data';
import optionsUF from '../../data/uf';
import ArrowRight from '../../svg/arrow_right.svg';
import './styles.scss';

const CreditCardForm = ({
  handleBackStep,
  handleSave,
}) => {
  const dispatch = useDispatch();
  const [selectBrand, setSelectedBrand] = useState(null);
  const [document, setDocument] = useState('CNPJ');
  const [selectUF, setSelectedUF] = useState(null);
  const [formData, setFormData] = useState({
    number: '',
    expireDate: '',
    CVV: '',
    name: '',
    brand: '',
    document: '',
    cep: '',
    address: '',
    addressNumber: '',
    neighborhood: '',
    city: '',
    uf: '',
    complement: '',
  });

  const onFormDataChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleGetAddress = async () => {
    const {
      logradouro, complemento, bairro, localidade, uf,
    } = await dispatch(getAddressByCep(formData.cep));
    await setFormData({
      ...formData,
      address: logradouro,
      complement: complemento,
      neighborhood: bairro,
      city: localidade,
      uf,
    });
    setSelectedUF(optionsUF.find(item => item.value === uf));
  };

  const onSaveButtonClick = async () => {
    try {
      const isFormValid = Validator.validateAddcreditCardForm(formData);
      if (isFormValid.error) {
        toast.error(isFormValid.errorMessage);
      } else {
        await dispatch(UserActions.addCreditCard(formData));
        toast.success('O cartão foi adicionado com sucesso!');
        handleSave();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container px-0">
      <div className="row mt-5 mb-5 justify-content-center">
        <div className="col-5">
          <p className="credit-card-form__title">
            Dados do cartão
          </p>
          <div className="credit-card-form__form-input">
            <Select
              value={selectBrand}
              onChange={(item) => {
                setSelectedBrand(item);
                onFormDataChange(item.value, 'brand');
              }}
              placeholder="Escolha sua bandeira..."
              options={optionsBrand}
              className="credit-card-form__select"
              styles={{
                control: styles => ({
                  ...styles,
                  backgroundColor: 'transparent',
                  height: '100%',
                }),
              }}
            />
            <span className="credit-card-form__legend">Selecione a bandeira do cartão</span>
          </div>
          <div className="credit-card-form__form-input">
            <input
              placeholder="Digite o numero do cartão"
              className="credit-card-form__input"
              type="number"
              required
              value={formData.number}
              onChange={e => onFormDataChange(e.target.value, 'number')}
            />
            <span className="credit-card-form__legend">Número do cartão</span>
          </div>
          <div className="credit-card-form__form-input">
            <div className="credit-card-form__divided-container-input ">
              <InputMask
                mask="99/9999"
                placeholder="Digite a validade do cartão"
                className="credit-card-form__input"
                required
                value={formData.expireDate}
                onChange={e => onFormDataChange(e.target.value, 'expireDate')}
              />
              <span className="credit-card-form__legend">Validade</span>
            </div>
            <div className="credit-card-form__divided-container-input ">
              <input
                placeholder="Digite o CCV do cartão"
                className="credit-card-form__input"
                type="number"
                required
                value={formData.CVV}
                onChange={e => onFormDataChange(e.target.value, 'CVV')}
              />
              <span className="credit-card-form__legend">CCV</span>
            </div>
          </div>
          <div className="credit-card-form__form-input">
            <input
              placeholder="Digite o nome conforme está impresso em seu cartão"
              className="credit-card-form__input"
              required
              value={formData.name}
              onChange={e => onFormDataChange(e.target.value, 'name')}
            />
            <span className="credit-card-form__legend">Nome impresso no cartão</span>
          </div>
          <div className="credit-card-form__form-input mb-0">
            <div className="credit-card-form__checkbutton-label">
              <div className="company-register__warning">
                <input
                  type="radio"
                  value="CNPJ"
                  defaultChecked
                  name="documents"
                  onChange={(e) => {
                    setDocument(e.target.value);
                    onFormDataChange('', 'document');
                  }}
                  className="company-register__warning__input"
                />
                <span className="company-register__warning__text">CNPJ </span>
              </div>
              <div className="company-register__warning">
                <input
                  type="radio"
                  value="CPF"
                  name="documents"
                  onChange={(e) => {
                    setDocument(e.target.value);
                    onFormDataChange('', 'document');
                  }}
                  className="company-register__warning__input"
                />
                <span className="company-register__warning__text">CPF </span>
              </div>
            </div>
            {document === 'CPF' ? (
              <InputMask
                mask="999.999.999-99"
                className="credit-card-form__input"
                placeholder="Insira do CPF do titular do cartão"
                required
                value={formData.document}
                onChange={e => onFormDataChange(e.target.value, 'document')}
              />
            ) : (
              <InputMask
                mask="99.999.999/9999-99"
                className="credit-card-form__input"
                placeholder="Insira do CNPJ do titular do cartão"
                required
                value={formData.document}
                onChange={e => onFormDataChange(e.target.value, 'document')}
              />
            )}
          </div>
        </div>
        <div />
        <div className="credit-card-form__divisor" />
        <div className="col-5">
          <p className="credit-card-form__title">
            Dados do endereço de cobrança
          </p>
          <div className="credit-card-form__form-input">
            <span className="credit-card-form__legend">CEP</span>
            <InputMask
              mask="99999-999"
              className="credit-card-form__input"
              placeholder="Digite CEP do endereço de cobrança"
              required
              value={formData.cep}
              onChange={e => onFormDataChange(e.target.value, 'cep')}
              onBlur={() => handleGetAddress()}
            />
          </div>
          <div className="credit-card-form__form-input">
            <input
              placeholder="Digite o endereço de cobrança"
              className="credit-card-form__input"
              required
              value={formData.address}
              onChange={e => onFormDataChange(e.target.value, 'address')}
            />
            <span className="credit-card-form__legend">Endereço</span>
          </div>
          <div className="credit-card-form__form-input">
            <input
              placeholder="Digite o bairro do endereço de cobrança"
              className="credit-card-form__input"
              required
              value={formData.neighborhood}
              onChange={e => onFormDataChange(e.target.value, 'neighborhood')}
            />
            <span className="credit-card-form__legend">Bairro</span>
          </div>
          <div className="credit-card-form__form-input">
            <div className="credit-card-form__divided-container-input ">
              <input
                placeholder="Digite o número do endereço de cobrança"
                className="credit-card-form__input"
                required
                value={formData.addressNumber}
                onChange={e => onFormDataChange(e.target.value, 'addressNumber')}
              />
              <span className="credit-card-form__legend">Número</span>
            </div>
            <div className="credit-card-form__divided-container-input ">
              <input
                placeholder="Digite o complemento do endereço de cobrança"
                className="credit-card-form__input"
                required
                value={formData.complement}
                onChange={e => onFormDataChange(e.target.value, 'complement')}
              />
              <span className="credit-card-form__legend">Complemento</span>
            </div>
          </div>
          <div className="credit-card-form__form-input">
            <div className="credit-card-form__divided-container-input ">
              <input
                placeholder="Digite a cidade do endereço de cobrança"
                className="credit-card-form__input"
                required
                value={formData.city}
                onChange={e => onFormDataChange(e.target.value, 'city')}
              />
              <span className="credit-card-form__legend">Cidade</span>
            </div>
            <div className="credit-card-form__divided-container-input ">
              <Select
                value={selectUF}
                onChange={(item) => {
                  setSelectedUF(item);
                  onFormDataChange(item.value, 'uf');
                }}
                placeholder="Escolha o estado..."
                options={optionsUF}
                className="credit-card-form__select"
                styles={{
                  control: styles => ({
                    ...styles,
                    backgroundColor: 'transparent',
                    height: '100%',
                  }),
                  menuList: styles => ({
                    ...styles,
                    maxHeight: '120px',
                  }),
                }}
              />
              <span className="credit-card-form__legend">Estado</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 pb-5">
        <div className="col-6 d-flex justify-content-start mt-3">
          <button
            type="button"
            className="button"
            onClick={handleBackStep}
          >
            Voltar
            <img
              className="button__arrow-ic__left"
              src={ArrowRight}
              alt="arrow"
            />
          </button>
        </div>
        <div className="col-6 d-flex justify-content-end mt-3">
          <button
            type="button"
            className="button"
            onClick={onSaveButtonClick}
          >
            Salvar
            <img
              className="button__arrow-ic"
              src={ArrowRight}
              alt="arrow"
            />
          </button>
        </div>
      </div>

    </div>
  );
};

export default CreditCardForm;
