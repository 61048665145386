import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FiEyeOff, FiEye } from 'react-icons/fi';

import * as UserActions from '../../app/redux/actions/user';
import toast from '../../helpers/toast';
import Validator from '../../helpers/form-validator';

import ArrowRight from '../../svg/arrow_right.svg';

export default function MyPlan() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const onFormDataChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const [hiddenPassword, setHiddenPassword] = useState({
    oldPassword: true,
    newPassword: true,
    confirmNewPassword: true,
  });

  const onHiddenPasswordChange = (value, field) => {
    setHiddenPassword({
      ...hiddenPassword,
      [field]: value,
    });
  };

  const updatePassword = async () => {
    try {
      const isFormValid = Validator.validateChangePasswordForm(formData);
      if (isFormValid.error) {
        toast.error(isFormValid.errorMessage);
      } else {
        await dispatch(UserActions.changePassword({
          currentPassword: formData.oldPassword,
          newPassword: formData.newPassword,
        }));
        toast.success('A sua senha foi alterada com sucesso!');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row px-0">
        <div className="col-12 px-0">
          <p className="edit-password__title">
            Redefinir uma nova senha
          </p>
          <p className="edit-password__subtitle">
            Preencha os dados abaixo
          </p>
        </div>
      </div>
      <div className="row px-0">
        <div className="col-12 px-0">
          <div className="edit-password__container mt-5">
            <div className="edit-password__input-container">
              <span className="edit-profile__input-container__input__legend">Senha atual</span>
              <input
                maxLength={128}
                required
                value={formData.oldPassword}
                type={hiddenPassword.oldPassword ? 'password' : 'text'}
                className="edit-password__input"
                onChange={e => onFormDataChange(e.target.value, 'oldPassword')}
              />
              {hiddenPassword.oldPassword ? (
                <FiEye
                  className="edit-password__icon"
                  onClick={() => onHiddenPasswordChange(!hiddenPassword.oldPassword, 'oldPassword')}
                />

              ) : (
                <FiEyeOff
                  className="edit-password__icon"
                  onClick={() => onHiddenPasswordChange(!hiddenPassword.oldPassword, 'oldPassword')}
                />
              )}
            </div>
            <div className="edit-password__input-container mt-5">
              <span className="edit-profile__input-container__input__legend">Nova senha</span>
              <input
                maxLength={128}
                required
                value={formData.newPassword}
                type={hiddenPassword.newPassword ? 'password' : 'text'}
                className="edit-password__input"
                onChange={e => onFormDataChange(e.target.value, 'newPassword')}
              />
              {hiddenPassword.newPassword ? (
                <FiEye
                  className="edit-password__icon"
                  onClick={() => onHiddenPasswordChange(!hiddenPassword.newPassword, 'newPassword')}
                />

              ) : (
                <FiEyeOff
                  className="edit-password__icon"
                  onClick={() => onHiddenPasswordChange(!hiddenPassword.newPassword, 'newPassword')}
                />
              )}
            </div>
            <div className="edit-password__input-container mt-5">
              <span className="edit-profile__input-container__input__legend">Confirmar nova senha </span>
              <input
                maxLength={128}
                required
                value={formData.confirmNewPassword}
                type={hiddenPassword.confirmNewPassword ? 'password' : 'text'}
                className="edit-password__input"
                onChange={e => onFormDataChange(e.target.value, 'confirmNewPassword')}
              />
              {hiddenPassword.confirmNewPassword ? (
                <FiEye
                  className="edit-password__icon"
                  onClick={() => onHiddenPasswordChange(!hiddenPassword.confirmNewPassword, 'confirmNewPassword')}
                />

              ) : (
                <FiEyeOff
                  className="edit-password__icon"
                  onClick={() => onHiddenPasswordChange(!hiddenPassword.confirmNewPassword, 'confirmNewPassword')}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row px-0 mt-5">
        <div className="col-11 d-flex justify-content-end px-0 postion-relative">
          <button
            type="button"
            onClick={() => updatePassword()}
            className="btn-primary"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
}
