import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { useSelector } from 'react-redux';

import { getCreditCard } from '../../app/redux/reducers/user';
import toast from '../../helpers/toast';

import CreditCardForm from '../../components/credit-card/credit-card-form';

const AddCreditCard = () => {
  const responseCreditCard = useSelector(state => getCreditCard(state));

  useEffect(() => {
    if (responseCreditCard.length > 0) {
      toast.error('Percebemos que você já possui um cartão!');
      navigate(I18n.t('routes.settings.url'));
    }
  }, []);

  return (
    <div className="container ml-0">
      <div className="row px-4 mt-3">
        <p className="settings__page-title">
          Novo Cartão
        </p>
      </div>

      <CreditCardForm
        handleBackStep={() => navigate(I18n.t('routes.settings.url'))}
        handleSave={() => navigate(I18n.t('routes.settings.url'))}
      />
    </div>
  );
};

export default AddCreditCard;
