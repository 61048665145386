import { MdDashboard } from 'react-icons/md';
import { IoMdChatbubbles, IoIosCall, IoIosImages  } from 'react-icons/io';
import { RiPixelfedLine, RiArticleLine, RiMoneyDollarCircleFill } from 'react-icons/ri';
import { BsGearWideConnected } from 'react-icons/bs';

const SidebarLinks = [
  {
    name: 'Dashboard',
    icon: MdDashboard,
    url: '/dashboard',
  },
  {
    name: 'Pix Messenger',
    icon: RiPixelfedLine,
    url: '/pix-messenger',
  },
  {
    name: 'Ligações',
    icon: IoIosCall,
    url: '/ligacoes',
  },
  {
    name: 'Publicações',
    icon: IoIosImages,
    url: '/publicacoes',
  },
  {
    name: 'Artigos',
    icon: RiArticleLine,
    url: '/artigos',
  },
  {
    name: 'Créditos',
    icon: RiMoneyDollarCircleFill,
    url: '/creditos',
  },
  {
    name: 'Configurações',
    icon: BsGearWideConnected,
    url: '/configuracoes',
  },
];

export default SidebarLinks;
