import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiEyeOff, FiEye } from 'react-icons/fi';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import toast from '../../helpers/toast';
import validator from '../../helpers/form-validator';
import formatters from '../../helpers/formatters';
import FormHeader from '../../components/shared/form/FormHeader';
import FormFooter from '../../components/shared/form/FormFooter';
import { authenticate } from '../../app/redux/actions/auth';
import { LoadingSelectors } from '../../app/redux/reducers';
import { version } from '../../../package.json';

export default function LoginForm() {
  const dispatch = useDispatch();
  const loading = useSelector(state => LoadingSelectors.getLoading(state) > 0);
  const [eyePassword, setEyePassword] = useState(true);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const onFormDataChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const isFormValid = validator.validateLoginForm(formData);
    if (isFormValid.error) {
      toast.error(isFormValid.errorMessage);
    } else {
      try {
        await dispatch(authenticate(formData.email, formData.password));
        navigate(I18n.t('routes.dashboard.url'));
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  return (
    <section className="form-wrapper__section">
      <FormHeader />
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 col-lg-6 form-wrapper__container">
            <form
              onSubmit={e => onFormSubmit(e)}
            >
              <div className="form-wrapper__container__form justify-content-center">
                <span className="form-wrapper__container__form__legend">E-mail </span>
                <input
                  type="text"
                  placeholder="Digite seu e-mail"
                  className="form-wrapper__container__form__input"
                  required
                  value={formData.email}
                  maxLength={254}
                  onChange={e => onFormDataChange(formatters.email(e.target.value), 'email')}
                />

                <div className="form-wrapper__container__form__password">
                  <span className="user-form__container__form__legend">Senha </span>
                  <input
                    placeholder="Digite sua senha"
                    type={eyePassword ? 'password' : 'text'}
                    className="form-wrapper__container__form__password__input"
                    required
                    value={formData.password}
                    maxLength={128}
                    onChange={e => onFormDataChange(e.target.value, 'password')}
                  />

                  {eyePassword ? (
                    <FiEye
                      className="form-wrapper__container__form__ic-visibility"
                      onClick={() => setEyePassword(!eyePassword)}
                    />

                  )
                    : (
                      <FiEyeOff
                        className="form-wrapper__container__form__ic-visibility"
                        onClick={() => setEyePassword(!eyePassword)}
                      />
                    )}
                </div>

                <Link
                  to={I18n.t('routes.forgotPassword.url')}
                  className="form-wrapper__container__form__forgot-password"
                >
                  Esqueceu a senha?
                </Link>

                <Link
                  to={I18n.t('routes.register.url')}
                  className="form-wrapper__container__form__forgot-password"
                >
                  Ainda não tem uma conta?
                </Link>

              </div>

              <FormFooter
                loading={loading}
                onFormSubmit={e => onFormSubmit(e)}
                buttonTitle="ENTRAR"
              />
            </form>
            <div style={{ alignSelf: 'center', padding: '50px 10px 10px 10px' }}>
              Versão {version}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
